import { expitationTime } from "../../../../helpers/expirationTime";
import { getData } from "../../../../services";

export function getDaysLeftUntilTrialEnd(trialEndDateUTC) {
  // Convert the UTC date string to a Date object
  const trialEndDate = new Date(trialEndDateUTC);

  // Get the current date in local time
  const currentDate = new Date();

  // Calculate the time difference in milliseconds
  const timeDifference = trialEndDate.getTime() - currentDate.getTime();

  // Convert the time difference from milliseconds to days
  const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

  return daysLeft;
}

const checkWalkinAllowed = () => {};

export const commonLogicForLoginAndVerification = async (
  data,
  cookie,
  setCookie,
  skipHubspotValidation = false,
) => {
  setCookie("cid", data?.company_id, {
    path: "/",
    expires: expitationTime(),
  });
  setCookie("t", data?.token, {
    path: "/",
    expires: expitationTime(),
  });
  setCookie("token", data?.token, {
    path: "/",
    expires: expitationTime(),
    domain: process.env.REACT_APP_DOMAIN,
  });
  setCookie("username", data?.user_name, {
    path: "/",
    expires: expitationTime(),
    domain: process.env.REACT_APP_DOMAIN,
  });
  setCookie("un", data?.user_name, {
    path: "/",
    expires: expitationTime(),
    domain: process.env.REACT_APP_DOMAIN,
  });
  setCookie("ut", btoa(data?.user_type), {
    path: "/",
    expires: expitationTime(),
    domain: process.env.REACT_APP_DOMAIN,
  });

  // if (data?.subscription_enable || data?.subs_end_date) {

  const endDate = data?.trial_end || data?.subs_end_date;
  const walkInTrial = data?.walkin_trial ?? null;
  let allow_user_to_walkin = false;


  if (!endDate && walkInTrial === null && !skipHubspotValidation) {
    try {
      const res = await getData({
        endpoint: "Wrapper/getContactFromHubspotByEmail?",
        params: {
          email: localStorage.getItem("em"),
        },
      });

      if (res) {
        allow_user_to_walkin = res?.properties?.match_walkin_trial === "true";
      }
    } finally {
    }
  }

  localStorage.setItem(
    "trial_info",
    JSON.stringify({
      is_trial: data?.is_trial,
      subs_end_date: data?.subs_end_date,
      subscription_enable: data?.subscription_enable,
      days_left: getDaysLeftUntilTrialEnd(
        data?.subs_end_date || data?.trial_end_date
      ),
      is_last_subs_mode_trial: data?.is_last_subs_mode_trial,
      trial_end: data?.trial_end,
      walkin_trial: data?.walkin_trial,
      walkin_trial_end: data?.walkin_trial_end,
      walkin_trial_start: data?.walkin_trial_start,
      allow:  allow_user_to_walkin || skipHubspotValidation,
    })
  );

  localStorage.removeItem("em");

  // }
};



