import * as signalR from "@microsoft/signalr";
import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getData, postData } from "../../services"; // Your existing API service

export const NotificationContext = createContext();

export const useNotificationContext = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showRed, setShowRed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [unread, setUnread] = useState([]);
  const [unreadTypes, setUnreadTypes] = useState({});
  const [cookies] = useCookies(["t"]);

  const baseURL = process.env.REACT_APP_NOTIFICATION_HUB_URL;

  // Creationg Connection
  const setupSignalRConnection = () => {
    // Create a SignalR connection to your hub
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(baseURL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();
    connection.start().catch((error) => {
      console.error(error);
    });

    // a client method to handle received notifications
    connection.on("SendNotification", (notification) => {
      if (notification[0]?.user_id || notification?.user_id) {
        if (notification[0]?.user_id === cookies.cuid) {
          setShowRed(true);
          setNotificationData((prevData) => [notification[0], ...prevData]);
        } else if (notification?.user_id === cookies.cuid) {
          setShowRed(true);
          setNotificationData((prevData) => [notification, ...prevData]);
        }
      } else {
        if (notification[0]?.main_contact_person === cookies.cuid) {
          setShowRed(true);
          setNotificationData((prevData) => [notification[0], ...prevData]);
        }
      }
    });
  };

  useEffect(() => {
    // Fetch initial notifications when the context loads
    setupSignalRConnection();
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    const res = await getData({
      endpoint: "RequestAccess/getProductRequestNotification",
      params: {
        user_id: cookies.cuid, // Use user cookies if needed
      },
      token: cookies.t,
    });

    if (res) {
      setNotificationData(res);
      setLoading(false);
    }
  };

  // finding unread notifications
  useEffect(() => {
    const unreadNotifications = notificationData.filter(
      (notification) => notification.notification_flag === true
    );
    const unreadNotId = unreadNotifications?.map(
      (notification) => notification.id
    );

    const separatedIds = unreadNotifications.reduce(
      (acc, notification) => {
        if (notification.type === "Blog") {
          acc.blog_ids.push(notification.id);
        } else {
          acc.request_ids.push(notification.id);
        }
        return acc;
      },
      { blog_ids: [], request_ids: [] }
    );

    setUnreadTypes(separatedIds);

    // const uniqueUnread = [...new Set(unreadNotId)];
    setUnread(unreadNotId);
    const count = unreadNotId.length;
    if (count > 0) {
      setShowRed(true);
    }
    setNotificationCount(count);
  }, [notificationData]);

  const markAsRead = async () => {
    if (unread.length > 0) {
      const res = await postData({
        endpoint: "RequestAccess/updateNotificationFlag",
        params: {
          request_ids: unreadTypes.request_ids.length
            ? JSON.stringify(unreadTypes.request_ids)
            : null,
          blog_ids: unreadTypes.blog_ids.length
            ? JSON.stringify(unreadTypes.blog_ids)
            : null,
        },
        token: cookies.t,
      });

      if (res) {
        fetchNotifications(); // Refresh notifications after marking as read
      }
    }
  };

  return (
    <NotificationContext.Provider
      value={{
        notificationData,
        notificationCount,
        showRed,
        loading,
        setShowRed,
        setNotificationData,
        markAsRead, // Expose the markAsRead function
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
