import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import {
  getStrategyByMultipleTypeId,
  getStructureByMultipleCategoryId,
} from "../../../../../../../services/editHelper";
import FilterIcon from "../../../../../../Icons/FilterIcon";
import CustomButton from "../../../../../../atoms/CustomeButton";
import Error from "../../../../../../atoms/Error";
import CloseBtnIcom from "../../../../../../molecules/Modal/CloseBtnIcon";
import FundsFunction from "./FundsFunction";
import "./style.scss";

export const groupByLabel = (data) =>
  data.reduce((acc, curr) => {
    const existingGroup = acc.find((item) => item.label === curr.label);

    if (existingGroup) {
      // If the group already exists, push the new value and data
      existingGroup.value.push(curr.value);
      existingGroup.data.push({
        value: curr.value,
        label: curr.structure_name,
      });
    } else {
      // Check if there are other objects with the same label
      const duplicateItems = data.filter((item) => item.label === curr.label);

      if (duplicateItems.length > 1) {
        // If there are multiple items with the same label, create a group
        acc.push({
          label: curr.label,
          value: [curr.value],
          nested: true,
          category_name: curr.category_name,
          data: [{ value: curr.value, label: curr.structure_name }],
        });
      } else {
        // If no duplicate, just push the current item as is
        acc.push(curr);
      }
    }

    return acc;
  }, []);

const Funds = ({
  type,
  reset,
  id,
  className,
  headingMobile,
  dateRangeNames = ["Date range"],
  onFilterChange,
  filterData: propsFilterData,
  isDateRange = false,
  headingtext,
  handleAum,
  QuickSearch,
  setQuickSearchData,
  selectedFilters = {},
  selectedAum = {}
}) => {

  const { geography, sector, categoryData, productType, productStructure } =
    FundsFunction({
      type: "product",
    });

  useEffect(() => {
    if(selectedFilters){
      setSelectedOptionsMap(selectedFilters)
    }

    if(selectedAum){
      setAum(selectedAum)
    }

  },[selectedFilters, selectedAum])

  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [updatedFilterData, setUpdatedFilterData] = useState({});
  const [strategies, setStrategies] = useState([]);
  const [structureData, setStructureData] = useState([]);
  // const [productType, setProductType] = useState([]);
  const [aum, setAum] = useState({ min: null, max: null });
  const [errors, setErrors] = useState({ min: null, max: null });
  const [confirmAum, setConfirmAum] = useState({ min: "", max: "" });
  const [isConfirmedAum, setIsAumConfirm] = useState(false);
  const screenWidth = useScreenWidth();



  const updated = dateRangeNames?.reduce((acc, name, index) => {
    acc[name] = [`startDate${index}`, `endDate${index}`];
    return acc;
  }, {});

  useEffect(() => {
    // if (QuickSearch?.value) {
    //   handleReset();
    // } else if (reset) {
    handleReset();
    // }
  }, [reset]);

  useEffect(() => {
    // Group productType by label
    const groupedProductType = groupByLabel(productType);

    const groupedProductStrategy = groupByLabel(strategies);

    const filterDataFunds = {
      // "Product Category": categoryData || [],
      "Product Type": groupedProductType || [],
      "Product Strategy": groupedProductStrategy || [],
      "Product Structure": productStructure || [],
      Geography: geography || [],
      Sector: sector || [],
    };

    const filterDataField = isDateRange
      ? { ...filterDataFunds, ...updated }
      : filterDataFunds;

    setUpdatedFilterData(filterDataField);
  }, [productType, categoryData, strategies, geography, sector, structureData]);

  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedKey, setSelectedKey] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const keys = Object.keys(updatedFilterData);
    setSelectedKey(keys[0]);
  }, []);

  const toggleFilterBox = () => {
    const keys = Object.keys(updatedFilterData);
    setIsOpen(!isOpen);
    setSelectedKey(keys[0]);
    setSearchQuery("");
  };

  const handleKeyClick = (key) => {
    setSelectedKey(key);
    setSearchQuery("");
  };

  useEffect(() => {
    fetchStrategyByMultipleTypeId();
    // }
  }, []);

  const fetchStrategyByMultipleTypeId = async (ids) => {
    const res = await getStrategyByMultipleTypeId(ids);
    if (res) {
      setStrategies(res);
    }
  };

  const fetchStructureByMultipleCategoryId = async (ids) => {
    const res = await getStructureByMultipleCategoryId(ids);
    if (res) {
      setStructureData(res);
    }
  };

  // const fetchTypeByMultipleStructureId = async (ids) => {
  //   const res = await getTypeByMultipleStructureId(ids);
  //   if (res) {
  //     setProductType(res);
  //   }
  // };

  // Function to recursively delete dependent keys
  const deleteDependentKeys = (updatedOptionsMap, deletedKey) => {
    for (const key in disableConditions) {
      const condition = disableConditions[key];
      if (typeof condition === "function" && condition(updatedOptionsMap)) {
        if (updatedOptionsMap[key]) {
          delete updatedOptionsMap[key];
          deleteDependentKeys(updatedOptionsMap, key); // Recursively delete dependent keys
        }
      }
    }
  };

  const handleOptionChange = (selectedValue) => {
    setSelectedOptionsMap((prevOptionsMap) => {
      const updatedOptionsMap = { ...prevOptionsMap };

      // Ensure the key exists in the map
      if (!updatedOptionsMap[selectedKey]) {
        updatedOptionsMap[selectedKey] = [];
      }

      // Function to handle toggling of a single selected value
      const toggleOption = (value) => {
        const optionExists = updatedOptionsMap[selectedKey].some(
          (option) => option.value === value
        );

        if (optionExists) {
          updatedOptionsMap[selectedKey] = updatedOptionsMap[
            selectedKey
          ].filter((option) => option.value !== value);
        } else {
          // Find the selected option in updatedFilterData
          let selectedOption = updatedFilterData[selectedKey]?.find(
            (option) => option.value === value || option.value.includes(value)
          );

          if (selectedOption) {
            const dataToAdd = selectedOption.data || selectedOption;

            if (Array.isArray(dataToAdd)) {
              updatedOptionsMap[selectedKey] = [
                ...updatedOptionsMap[selectedKey],
                ...dataToAdd.filter((item) => item.value === value),
              ];
            } else {
              updatedOptionsMap[selectedKey] = [
                ...updatedOptionsMap[selectedKey],
                dataToAdd,
              ];
            }
          } else {
            console.warn(`No option found for value: ${value}`);
          }
        }
      };

      // If selectedValue is an array (e.g., "Select All" clicked)
      if (Array.isArray(selectedValue)) {
        const allSelected = selectedValue.every((value) =>
          updatedOptionsMap[selectedKey].some(
            (option) => option.value === value
          )
        );

        if (allSelected) {
          // Deselect all if all items are already selected
          updatedOptionsMap[selectedKey] = updatedOptionsMap[
            selectedKey
          ].filter((option) => !selectedValue.includes(option.value));
        } else {
          // Otherwise, add all items
          selectedValue.forEach((value) => {
            const selectedOption = updatedFilterData[selectedKey]?.find(
              (option) => option.value === value || option.value.includes(value)
            );

            if (selectedOption) {
              const dataToAdd = selectedOption.data || selectedOption;
              if (Array.isArray(dataToAdd)) {
                updatedOptionsMap[selectedKey] = [
                  ...updatedOptionsMap[selectedKey],
                  ...dataToAdd.filter((item) => item.value === value),
                ];
              } else {
                updatedOptionsMap[selectedKey] = [
                  ...updatedOptionsMap[selectedKey],
                  dataToAdd,
                ];
              }
            }
          });
        }
      } else {
        // Proceed with single value toggle logic
        toggleOption(selectedValue);
      }

      // Remove the key if its array is empty
      if (updatedOptionsMap[selectedKey].length === 0) {
        delete updatedOptionsMap[selectedKey];
        deleteDependentKeys(updatedOptionsMap, selectedKey);
      }

      return updatedOptionsMap;
    });
  };

  useEffect(() => {
    if (QuickSearch?.value) {
      setSelectedOptionsMap((prev) => ({
        ...prev,
        "Product Type": Array.isArray(QuickSearch?.value) ? QuickSearch?.data : [QuickSearch],
      }));
    }
  }, [QuickSearch]);

  useEffect(() => {
    if (
      selectedKey === "Product Type" &&
      selectedOptionsMap?.["Product Type"]?.length > 0
    ) {
      const typeValues =
        selectedOptionsMap?.["Product Type"]?.map((type) => type.value) || [];
      fetchStrategyByMultipleTypeId(typeValues);
    }
  }, [selectedOptionsMap?.["Product Type"]]);

  const handleApply = () => {
    const filterValues = {
      ...selectedOptionsMap,
      ...dateRange,
    };

    onFilterChange(filterValues);
    setIsOpen(false);
    if (QuickSearch?.value) {
      setQuickSearchData && setQuickSearchData(null);
    }
  };

  const handleReset = () => {
    setSelectedOptionsMap({});
    setDateRange({ startDate: null, endDate: null });
    setIsOpen(false);
    setAum(() => ({
      min: null,
      max: null,
    }));
    onFilterChange({});
    if (QuickSearch?.value) {
      setQuickSearchData && setQuickSearchData(null);
    }
  };

  const disableConditions = {
    "Product Type": [], // Define disabling conditions for Product Type
    Geography: [], // Define disabling conditions for Geography
    Sector: [], // Define disabling conditions for Sector
    "Product Strategy": (selectedOptionsMap) =>
      !selectedOptionsMap["Product Type"],
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const excludedClassesRegex = /^Mui.*/; // Regular expression to match classes starting with "Mui"

      const isExcluded = Array.from(event.target.classList).some((className) =>
        excludedClassesRegex.test(className)
      );

      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !isExcluded &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, isOpen]);

  const handleChecked = (value) => {
    const selectedOptions = selectedOptionsMap[selectedKey] || [];

    if (Array.isArray(value)) {
      // If value is an array, check if all items in the array are found in the selected options
      const allItemsFound = value.every((val) =>
        selectedOptions.some((selectedOption) => selectedOption.value === val)
      );
      return allItemsFound;
    } else {
      // If value is not an array, just check if it exists in the selected options
      const isFound = selectedOptions.some(
        (selectedOption) => selectedOption.value === value
      );
      return isFound;
    }
  };

  const handleConfirm = (type) => {
    if (aum) {
      // if (!aum.min) {
      //   setErrors((prev) => ({ ...prev, min: "Min is Required" }));
      //   return;
      // } else if (!aum.max) {
      //   setErrors((prev) => ({ ...prev, max: "Max is Required" }));
      //   return;
      // } else {
      //   // If both min and max are present, clear any previous errors
      //   setErrors({});
      // }

      // If all validations pass, proceed with confirming AUM
      setConfirmAum({ ...aum });
      setIsAumConfirm(true);

      handleAum({ ...aum });
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    setAum((prevAum) => ({ ...prevAum, [name]: value }));
    // validate(name, value);
  };

  useEffect(() => {
    if (
      containerRef &&
      containerRef?.current &&
      dropdownRef &&
      dropdownRef.current &&
      screenWidth < 900
    ) {
      dropdownRef.current.style.visibility = "hidden";
      // console.log(dropdownRef?.current?.style.visibility="hidden");
      const positions = containerRef?.current?.getBoundingClientRect();
      const dropPosition = dropdownRef?.current?.getBoundingClientRect();
      const totalWidth = (dropPosition.width * 100) / 80;
      const width_20_percent = (totalWidth * 20) / 100;
      dropdownRef.current.style.left = `-${
        positions.x + (screenWidth < 500 && width_20_percent)
      }px`;
      dropdownRef.current.style.right = `auto`;
      dropdownRef.current.style.visibility = "visible";
    }
  }, [isOpen]);

  return (
    <>
      <div
        className={`${className} ${
          headingMobile
            ? "filter-container filter-mobile-container"
            : "filter-container"
        }`}
        ref={containerRef}
        id={id}
      >
        <CustomButton
          handleClick={toggleFilterBox}
          icon={<FilterIcon />}
          text={
            headingMobile ? "" : type === "product" ? headingtext : headingtext
          }
          classes={`${
            headingMobile
              ? Object.keys(selectedOptionsMap)?.length > 0 ||
                (isDateRange && (aum.min || aum.max))
                ? "multi-dot-indicator filter-mobile-container-btn"
                : "filter-mobile-container-btn"
              : Object.keys(selectedOptionsMap)?.length > 0 ||
                (isDateRange && (aum.min || aum.max))
              ? "multi-dot-indicator filterbtn"
              : "filterbtn"
          } ${
            (Array.isArray(selectedOptionsMap) &&
              selectedOptionsMap?.length > 0) ||
            (selectedOptionsMap &&
              Object.keys(selectedOptionsMap)?.length > 0) ||
            (isDateRange &&
              Object.values(dateRange).some((value) => value !== null)) ||
            isOpen
              ? "active-filter"
              : "test"
          }`}
          style={{ height: "40px" }}
        />
        {isOpen && (
          <div className={`filter-box`} ref={dropdownRef}>
            <div className="filter-header">
              <p className="header">Filters</p>

              <span
                className="header"
                style={{ cursor: "pointer" }}
                onClick={toggleFilterBox}
              >
                <i className="d-flex">
                  <CloseBtnIcom />
                </i>
              </span>
            </div>
            <div className="filter-data-box">
              <div className="filter-key-box">
                {Object.keys(updatedFilterData).map((key) => (
                  <div
                    key={key}
                    onClick={() => {
                      // Check if the key should be disabled based on the disableConditions
                      if (
                        disableConditions[key] &&
                        typeof disableConditions[key] === "function"
                      ) {
                        if (disableConditions[key](selectedOptionsMap)) {
                          // If the condition is met, return early without changing the selected key
                          return;
                        }
                      }
                      // If the key is not disabled, handle the click event as usual
                      handleKeyClick(key);
                    }}
                    className={`filter-key selected ${
                      selectedKey === key ? "active-key" : ""
                    } ${
                      // Apply disabled class if the key is disabled
                      disableConditions[key] &&
                      typeof disableConditions[key] === "function" &&
                      disableConditions[key](selectedOptionsMap) &&
                      "disabled-key"
                    }`}
                  >
                    {key}

                    {selectedOptionsMap[key] ||
                    (isDateRange &&
                      (dateRange[`startDate${dateRangeNames.indexOf(key)}`] ||
                        dateRange[`endDate${dateRangeNames.indexOf(key)}`] ||
                        (key === "AUM($mm)" && (aum.min || aum.max)))) ? (
                      <div className="dot-blue"></div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>

              <div className="filters-column">
                {dateRangeNames?.includes(selectedKey) ? (
                  <div>
                    <form className="formConatiner">
                      <div className="formInput">
                        <label htmlFor="startDate">Min Aum</label>
                        <input
                          type="number"
                          placeholder="Enter Min Aum"
                          name="min"
                          onChange={(e) =>
                            setAum({ ...aum, min: e.target.value })
                          }
                          onBlur={handleBlur}
                          value={aum.min}
                        />
                        {errors.min && <Error error={errors.min} />}
                      </div>
                      <div className="formInput">
                        <label htmlFor="endDate">Max Aum</label>
                        <input
                          type="number"
                          placeholder="Enter Max Aum"
                          name="max"
                          onChange={(e) =>
                            setAum({ ...aum, max: e.target.value })
                          }
                          onBlur={handleBlur}
                          value={aum.max}
                        />
                        {errors.max && <Error error={errors.max} />}
                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    <input
                      key={selectedKey}
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      style={{
                        height: "35px",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    <div className="filters-options">
                      {(() => {
                        if (selectedKey === "Product Type") {
                          // Group options by category_name
                          const groupedOptions = updatedFilterData[
                            selectedKey
                          ].reduce((acc, option) => {
                            const categoryName =
                              option.category_name || "Uncategorized";
                            if (!acc[categoryName]) {
                              acc[categoryName] = [];
                            }
                            acc[categoryName].push(option);
                            return acc;
                          }, {});

                          // Filter and organize options within each category
                          const filteredGroupedOptions = Object.entries(
                            groupedOptions
                          ).reduce((acc, [categoryName, options]) => {
                            const selectedOptions = options.filter(
                              (option) =>
                                selectedOptionsMap[selectedKey]?.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                ) &&
                                option.label
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                            );

                            const unselectedOptions = options.filter(
                              (option) =>
                                !selectedOptionsMap[selectedKey]?.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                ) &&
                                option.label
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                            );

                            const filteredOptions = [
                              ...selectedOptions,
                              ...unselectedOptions,
                            ];

                            if (filteredOptions.length > 0) {
                              acc[categoryName] = filteredOptions;
                            }

                            return acc;
                          }, {});

                          // Check if no options are available
                          if (
                            Object.keys(filteredGroupedOptions).length === 0
                          ) {
                            return (
                              <div className="no-data-found">
                                {searchQuery
                                  ? ""
                                  : selectedKey === "Product Strategy"
                                  ? "No Data Found for the Selected Product Type"
                                  : ""}
                              </div>
                            );
                          }

                          // Render grouped options
                          return Object.entries(filteredGroupedOptions).map(
                            ([categoryName, options]) => (
                              <div
                                key={categoryName}
                                className="category-group"
                              >
                                <h6 className="filter-group-header">
                                  {categoryName}
                                </h6>
                                {options.map((option) => (
                                  <div key={option.value} className="mb-1">
                                    <label
                                      className="check-container"
                                      htmlFor={option.value}
                                    >
                                      {option.label}
                                      <input
                                        type="checkbox"
                                        id={option.value}
                                        checked={handleChecked(option.value)}
                                        onChange={() =>
                                          handleOptionChange(option.value)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )
                          );
                        } else {
                          // Original UI for other selected keys
                          const selectedOptions = updatedFilterData[selectedKey]
                            .filter((option) =>
                              selectedOptionsMap[selectedKey]?.some(
                                (selectedOption) =>
                                  selectedOption.value === option.value
                              )
                            )
                            .filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            );

                          const unselectedOptions = updatedFilterData[
                            selectedKey
                          ]
                            .filter(
                              (option) =>
                                !selectedOptionsMap[selectedKey]?.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                )
                            )
                            .filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            );

                          const filteredOptions = [
                            ...selectedOptions,
                            ...unselectedOptions,
                          ];

                          if (filteredOptions.length === 0) {
                            return (
                              <div className="no-data-found">
                                {searchQuery ? "" : "No Data Found"}
                              </div>
                            );
                          }

                          return filteredOptions.map((option) => (
                            <div key={option.value} className="mb-1">
                              <label
                                className="check-container"
                                htmlFor={option.value}
                              >
                                {option.label}
                                <input
                                  type="checkbox"
                                  id={option.value}
                                  checked={handleChecked(option.value)}
                                  onChange={() =>
                                    handleOptionChange(option.value)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          ));
                        }
                      })()}
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr className="my-0" />
            <div className="d-flex justify-content-end gap-3 p-3">
              <button
                className="btn btn-outline-dark mt-0"
                onClick={() => {
                  handleAum({});
                  setAum(() => ({
                    min: null,
                    max: null,
                  }));
                  handleReset();
                }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary mt-0"
                disabled={!errors}
                onClick={() => {
                  handleConfirm("aum");
                  handleApply();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Funds;
