import React from "react";

const Comment = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.768 15.7699H6.504C5.96 15.7699 5.492 15.5739 5.1 15.1819C4.708 14.7899 4.512 14.3139 4.512 13.7539V7.48994C4.512 6.92994 4.708 6.45794 5.1 6.07394C5.492 5.68994 5.96 5.49794 6.504 5.49794H16.512C17.072 5.49794 17.544 5.68994 17.928 6.07394C18.312 6.45794 18.504 6.92994 18.504 7.48994V13.7539C18.504 14.3139 18.308 14.7899 17.916 15.1819C17.524 15.5739 17.056 15.7699 16.512 15.7699H12.696C12.088 15.7699 11.544 15.9699 11.064 16.3699L8.28 18.7459V17.2579C8.28 16.8419 8.132 16.4899 7.836 16.2019C7.54 15.9139 7.184 15.7699 6.768 15.7699ZM6.504 17.2579H6.768V19.8259C6.768 20.0979 6.86 20.3299 7.044 20.5219C7.228 20.7139 7.452 20.8179 7.716 20.8339C7.98 20.8499 8.216 20.7699 8.424 20.5939L12.048 17.4979C12.256 17.3379 12.472 17.2579 12.696 17.2579H16.512C17.136 17.2579 17.716 17.1019 18.252 16.7899C18.788 16.4779 19.212 16.0539 19.524 15.5179C19.836 14.9819 19.992 14.3939 19.992 13.7539V7.48994C19.992 6.86594 19.836 6.28594 19.524 5.74994C19.212 5.21394 18.788 4.78994 18.252 4.47794C17.716 4.16594 17.136 4.00994 16.512 4.00994H6.504C5.864 4.00994 5.276 4.16594 4.74 4.47794C4.204 4.78994 3.78 5.21394 3.468 5.74994C3.156 6.28594 3 6.86594 3 7.48994V13.7539C3 14.3939 3.156 14.9819 3.468 15.5179C3.78 16.0539 4.204 16.4779 4.74 16.7899C5.276 17.1019 5.864 17.2579 6.504 17.2579Z"
        fill="#5E5E5E"
      />
    </svg>
  );
};

export default Comment;
