import PropTypes from "prop-types";
import NotFoundImage from "../../../assets/images/NoCompany.png";
import Company from "../../../assets/images/NoContent.png";
import "./image_viewer.scss"; // Import the CSS file

const CommunityImageViewer = ({
  imageUrl,
  altText = "Image",
  type,
  viewType = "normal",
  borderType = 'normal'
}) => {
  const defaultImageUrl = NotFoundImage;

  const handleError = (e) => {
    e.target.src = defaultImageUrl;
  };

  return (
    <div
      className={
        viewType === "normal" ? "image-container" : "community-image-container"
      }
    >
      {imageUrl ? (
        <>
          <img
            src={imageUrl}
            alt={altText}
            className="community-image"
            onError={handleError}
            style={{ borderRadius : borderType === 'normal' ? ''  : '6px', border : viewType ===  'community'?  '1px solid #efefef' : '' }}
          />

          {/* {type === "JoinRequest" ? (
            <img
              className="icon-img"
              src={JoinRequest}
              alt="Community Join Request"
            />
          ) : type === "Text" ? (
            <Article classname="icon-img" />
          ) : type === "Event" ? (
            <img className="icon-img" src={Eventimage} alt="Event" />
          ) : type === "Location" ? (
            <img className="icon-img" src={RoadshowImage} alt="Event" />
          ) : type === "Article" ? (
            <img className="icon-img" src={ArticleImage} alt="Event" />
          ) : (
            <></>
          )} */}
        </>
      ) : (
        <img
          src={type === "Company" ? Company : NotFoundImage}
          alt={altText}
          className={`image no-image ${
            type === "Company" ? "" : "other-image"
          }`}
          onError={handleError}
        />
      )}
    </div>
  );
};

CommunityImageViewer.propTypes = {
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  type: PropTypes.string,
  viewType: PropTypes.oneOf(["normal", "community"]),
};

export default CommunityImageViewer;
