import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useNotificationContext } from "../../../../contexts/NotificationContext";
import { timeAgo } from "../../../../helpers/timeAgo";
import DocumentIcon from "../../../Icons/Document";
import NotificationIcon from "../../../Icons/notification";
import IconCircle from "../../../atoms/IconCircle";
import style from "./styles.module.scss";

const Notification = () => {
  const navigate = useNavigate();
  console.log("useNotificationContext()", useNotificationContext());

  const [cookies] = useCookies(["t", "cuid"]);
  const {
    notificationData,
    notificationCount,
    showRed,
    loading,
    setShowRed,
    setNotificationData,
    markAsRead,
  } = useNotificationContext();

  const [notifications, setNotifications] = useState([]);

  const [showDropdown, setShowDropdown] = useState(false);

  // Initially Creating a connection and fetching previous notifications

  const handleBellClick = () => {
    setShowDropdown((prev) => !prev);
    if (!showDropdown) {
      setShowRed(false); // Mark as viewed
    }
  };

  const handleDropdownItemClick = (notification) => {
    if (notification?.type) {
      navigate(`/dashboard/requests/${notification?.type}`);
    } else {
      navigate(`/dashboard/requests`);
    }
    setShowDropdown(false);
    setShowRed(false);
  };

  // useEffect(() => {
  //   console.log("notification-dta", notificationData);
  // }, [notificationData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const notificationIcon = document.getElementById("notificationIcon");
      const dropdown = document.getElementById("notificationDropdown");

      if (
        !notificationIcon.contains(event.target) &&
        dropdown &&
        !dropdown.contains(event.target)
      ) {
        setShowDropdown(false);
        markAsRead();
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className={style.notification}>
      <i
        className="cursor-pointer grey-i"
        id="notificationIcon"
        onClick={handleBellClick}
      >
        <NotificationIcon />
      </i>

      {showRed && <div className={style.notificationCount}></div>}

      {showDropdown && (
        <div className={style.dropdown} id="notificationDropdown">
          {/* Add your amazing dropdown content here */}
          {loading ? (
            // Shimmer effect while loading
            <div className={style.shimmerContainer}>
              <div className={style.shimmer}></div>
              <div className={style.shimmer}></div>
              <div className={style.shimmer}></div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between p-3 align-items-center py-1">
                <div className="h3-i semi-light">
                  {notificationCount > 0 && (
                    <>
                      {notificationCount}{" "}
                      {notificationCount > 1 ? "Requests" : "Request"}
                    </>
                  )}
                </div>

                {notificationData.length > 0 && (
                  <div
                    className="view-more cursor-pointer"
                    onClick={() => {
                      navigate("/dashboard/requests");
                      setShowDropdown(false);
                      markAsRead();
                    }}
                  >
                    View All
                  </div>
                )}
              </div>
              {notificationData.length > 0 ? (
                notificationData
                  .filter((notification, index, array) => {
                    // Use indexOf to check if the current notification's id is the first occurrence in the array
                    return (
                      array.indexOf(
                        array.find((item) => item.id === notification.id)
                      ) === index
                    );
                  })
                  ?.map((notification, index) => (
                    <>
                      <div
                        className={`${style.dropdownItem} ${
                          notification?.notification_flag
                            ? style.unreadNotification
                            : ""
                        }`}
                        onClick={() => handleDropdownItemClick(notification)}
                      >
                        <div className="d-flex gap-3">
                          <IconCircle
                            icon={
                              <i
                                className="d-flex"
                                style={{ color: "#1A1D1F" }}
                              >
                                <DocumentIcon />
                              </i>
                            }
                            size={50}
                          ></IconCircle>
                          <div className="d-flex flex-column">
                            {notification?.type === "Received" ? (
                              <>
                                <p className={style.notification_p}>
                                  <span className="semi-light">
                                    {notification?.user_name}
                                  </span>{" "}
                                  requested access for{" "}
                                  <span className="semi-light">
                                    {notification?.name}
                                  </span>
                                </p>
                              </>
                            ) : notification?.type === "Blog" ? (
                              <>
                                <p className={style.notification_p}>
                                  <span className="semi-light">PrimeAlpha</span>{" "}
                                  {notification?.status} request for{" "}
                                  <span className="semi-light">
                                    {notification?.name ||
                                      notification?.blog_name}
                                  </span>
                                </p>
                              </>
                            ) : (
                              <>
                                <p className={style.notification_p}>
                                  <span className="semi-light">
                                    {notification?.user_name}
                                  </span>{" "}
                                  {notification?.status} request for{" "}
                                  <span className="semi-light">
                                    {notification?.name}
                                  </span>
                                </p>
                              </>
                            )}

                            <p className="p mb-xs">
                              {timeAgo(notification?.date)}
                            </p>
                            {/* <p>{notification?.notification_flag}</p> */}
                          </div>
                        </div>
                      </div>
                    </>
                  ))
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="h3-i semi-light mb-1">
                    No New Notifications
                  </div>
                </div>
              )}
            </>
          )}
          {/* Add more items as needed */}
        </div>
      )}
      {/* )} */}
    </div>
  );
};

export default Notification;
