import { Field, Formik } from "formik";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import * as yup from "yup";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { replaceSpacesInImageUrl } from "../../../../helpers/replaceSpaceInImageUrl";
import {
  communityStatusData,
  communityType,
  RoleType,
} from "../../../../helpers/staticData";
import { patchFormData, postFormData } from "../../../../services";
import {
  getCityByName,
  getCountries,
  getGeography,
  getSector,
  getStatesByName,
  getType,
} from "../../../../services/editHelper";
import SelectDropdown from "../../../atoms/Dropdown";
import InputLayout from "../../../atoms/InputLayout";
import ImageSelector from "../../../molecules/ImageSelector/ImageSelector";

const CommunityForm = ({ onClose, id, initialData, type, refetch }) => {
  const [cookies] = useCookies(["cid", "t", "cuid"]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [countryData, setCountryData] = useState();
  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  const [geography, setGeography] = useState();
  const [sector, setSector] = useState();
  const [types, setTypes] = useState();

  const ImageSelectorStyle = {
    borderRadius: "10px",
    border: "1px solid #EFEFEF",
    // padding: "10px",
  };

  const fetchData = async () => {
    const res = await getCountries();
    const updatedCData = res.map((item) => ({
      ...item,
      value: `${item.label}`,
    }));

    setCountryData(updatedCData);

    const gData = await getGeography();
    const updatedGData = gData.map((item) => ({
      ...item,
      value: `${item.label}`,
    }));
    setGeography(updatedGData);

    const sdata = await getSector();
    const updatedSData = sdata.map((item) => ({
      ...item,
      value: `${item.label}`,
    }));
    setSector(updatedSData);

    const tdata = await getType();
    const seen = new Set();
    const updateTData = tdata
      .filter((item) => {
        const duplicate = seen.has(item.label);
        seen.add(item.label);
        return !duplicate; // Keep items that are not duplicates
      })
      .map((item) => ({
        ...item,
        value: `${item.label}`,
      }));
    setTypes(updateTData);
  };

  const fetchRequiredData = async () => {
    const sdata = await getStatesByName(initialData?.country);
    setStates(sdata);

    const cdata = await getCityByName(initialData?.state);
    setCities(cdata);
  };

  useEffect(() => {
    fetchData();
    if (type === "edit") {
      fetchRequiredData();
    }
  }, []);
  const initialValues = {
    logo: initialData?.logo || null,
    cover_photo: initialData?.cover_photo || null,
    name: initialData?.name || "",
    is_private: initialData?.is_private || false,
    description: initialData?.description || null,
    community_policy: initialData?.community_policy || "",
    country: initialData?.country || "",
    state: initialData?.state || "",
    city: initialData?.city || "",
    status: initialData?.status || "",
    is_searchable_on_pa: initialData?.is_searchable_on_pa || false,
    is_searchable_on_google: initialData?.is_searchable_on_google || false,
    need_post_approval: initialData?.need_post_approval || false,
    member_preference: initialData?.member_preference || null,
    allow_member_to_post: initialData?.allow_member_to_post || false,
    geography: initialData?.geography || null,
    sector: initialData?.sector || null,
    product_type: initialData?.product_type || null,
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),

    country: yup.string().required("Country is required").nullable(),

    state: yup.string().required("State is required").nullable(),

    city: yup.string().required("City is required").nullable(),

    member_preference: yup
      .array()
      .min(1, "At least one Member Preference is required")
      .required("Member Preference is required"),

    geography: yup
      .array()
      .min(1, "At least one Geography is required")
      .required("Geography is required"),

    sector: yup
      .array()
      .min(1, "At least one Sector is required")
      .required("Sector is required"),
  });

  const handleSubmit = async (values) => {
    setIsSubmitting(true);
    try {
      const res = await postFormData({
        endpoint: "Community/addCommunity",
        type: "community",
        data: { ...values, user_id: cookies.cuid },
        params : {user_role : 'Customer'},
        token : cookies.t
      });
      if (res) {
        toast.success("Community Added Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        onClose();
        refetch();
      }
    } catch {
      toast.error("An Error Occured", { style: errorStyles, duration: 1000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdate = async (values) => {
    setIsSubmitting(true);
    try {
      const res = await patchFormData({
        endpoint: "Community/updateCommunity",
        type: "community",
        data: { ...values, user_id: cookies.cuid },
        params: {
          id: id,
        },
        token : cookies.t
      });
      if (res) {
        toast.success("Community Details Updated Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        onClose();
        refetch();
      }
    } catch {
      toast.error("An Error Occured", { style: errorStyles, duration: 1000 });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Formik
      key={id}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
      validateOnBlur
      enableReinitialize
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        setFieldValue,
        touched,
        errors,
        setFieldError,
        values,
        handleBlur,
        handleChange,
      }) => (
        <>
          <div className="p-4 py-3">
            <div className="row">
              <InputLayout>
                <label htmlFor="Logo">Logo</label>
                <ImageSelector
                  style={ImageSelectorStyle}
                  type="Company"
                  handleFile={(file) => {
                    setFieldValue("logo", file);
                  }}
                  onRemove={() => setFieldValue("remove_profile_photo", true)}
                  aspect={1}
                  widthLimit={400}
                  heightLimit={400}
                  selectedImage={replaceSpacesInImageUrl(initialData?.logo)}
                  description="Logo image should be 400X400px and size is 1MB"
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="Cover Photo">Cover Photo</label>
                <ImageSelector
                  style={{
                    ...ImageSelectorStyle,
                    width: "10rem",
                    objectFit: "contain",
                  }}
                  type="Company"
                  handleFile={(file) => {
                    setFieldValue("cover_photo", file);
                  }}
                  aspect={2}
                  onRemove={() => setFieldValue("remove_cover_photo", true)}
                  widthLimit={1400}
                  heightLimit={400}
                  selectedImage={replaceSpacesInImageUrl(
                    initialData?.cover_photo
                  )}
                  description="Logo image should be 400X400px and size is 1MB"
                />
              </InputLayout>
            </div>

            <div className="row mt-3">
              <InputLayout>
                <label htmlFor="" className="required-field">
                  Name
                </label>
                <Field
                  type="text"
                  name="name"
                  placeholder="Name"
                  className={`${
                    touched.name && errors.name ? "error-input" : ""
                  }`}
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="" className="required-field">
                  Community Type
                </label>
                <SelectDropdown
                  placeholder="Select Community Type"
                  data={communityType}
                  onChange={(option) => setFieldValue("is_private", option)}
                  selectedValue={initialValues?.is_private}
                />
                <div className="p mt-1 mb-0">
                  **Everyone on PrimeAlpha will be able to see a Public
                  Community
                </div>
              </InputLayout>
            </div>

            <div className="row mt-3">
              <InputLayout>
                <label htmlFor="country" className="required-field">
                  Country
                </label>
                <SelectDropdown
                  onChange={async (option) => {
                    setFieldValue("country", option?.label);
                    const data = await getStatesByName(option?.label);
                    setFieldValue("state", null);
                    setFieldValue("city", null);
                    setStates(data);
                    setCities(null);
                  }}
                  format="all"
                  onClear={() => {
                    setFieldValue("country", null);
                    setFieldValue("state", null);
                    setFieldValue("city", null);
                  }}
                  placeholder="Select Country"
                  data={countryData}
                  selectedValue={initialValues?.country}
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="state_region" className="required-field">
                  State/Region
                </label>
                <SelectDropdown
                  onChange={async (option) => {
                    setFieldValue("state", option?.label);
                    const data = await getCityByName(option?.label);
                    setFieldValue("city", null);
                    setCities(data); // Corrected this line
                  }}
                  onClear={() => {
                    setFieldValue("state", null);
                    setFieldValue("city", null);
                  }}
                  placeholder="Select State"
                  format="all"
                  data={states}
                  selectedValue={initialValues?.state}
                />
              </InputLayout>
            </div>

            <div className="row mt-3">
              <InputLayout>
                <label htmlFor="city" className="required-field">
                  City
                </label>
                <SelectDropdown
                  onChange={(option) => {
                    setFieldValue("city", option?.label);
                  }}
                  format="all"
                  onClear={() => {
                    setFieldValue("city", null);
                  }}
                  placeholder="Select City"
                  data={cities}
                  // selectedValue={initialData?.city_id}
                  selectedValue={initialValues?.city}
                />
              </InputLayout>

              <InputLayout>
                <label htmlFor="" className="required-field">
                  Status
                </label>
                <SelectDropdown
                  placeholder="Status"
                  data={communityStatusData}
                  onChange={(option) => setFieldValue("status", option)}
                  selectedValue={initialValues?.status}
                />
              </InputLayout>
            </div>

            <div className="row mt-3">
              <InputLayout>
                <label htmlFor="member_preference" className="required-field">
                  Member Preference
                </label>
                <SelectDropdown
                  placeholder="Select Member Preference"
                  data={RoleType}
                  onChange={(option) =>
                    setFieldValue("member_preference", option)
                  }
                  selectedValue={initialValues?.member_preference}
                  multiSelect
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="Geography" className="required-field">
                  Geography
                </label>
                <SelectDropdown
                  placeholder="Select Geography"
                  data={geography}
                  onChange={(option) => setFieldValue("geography", option)}
                  multiSelect
                  selectedValue={initialValues?.geography}
                />
              </InputLayout>
            </div>

            <div className="row mt-3">
              <InputLayout>
                <label htmlFor="Sector" className="required-field">
                  Sector
                </label>
                <SelectDropdown
                  placeholder="Select Sector"
                  data={sector}
                  onChange={(option) => setFieldValue("sector", option)}
                  multiSelect
                  selectedValue={initialValues?.sector}
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="type">Type</label>
                <SelectDropdown
                  placeholder="Select Type"
                  data={types}
                  onChange={(option) => setFieldValue("product_type", option)}
                  multiSelect
                  selectedValue={initialValues?.product_type}
                />
              </InputLayout>
            </div>

            <div className="row mt-3">
              <InputLayout>
                <label htmlFor="">Description</label>
                <Field
                  type="text"
                  as="textarea"
                  name="description"
                  placeholder="Enter Description"
                  className={`${
                    touched.description && errors.description
                      ? "error-input"
                      : ""
                  }`}
                />
              </InputLayout>
              <InputLayout>
                <label htmlFor="">Community Policy</label>
                <Field
                  type="text"
                  as="textarea"
                  name="community_policy"
                  placeholder="Enter Community Policy"
                  className={`${
                    touched.community_policy && errors.community_policy ? "error-input" : ""
                  }`}
                />
              </InputLayout>
            </div>

            <div className="row mt-3">
              <div className="col-md">
                <div className="add-page-switch">
                  <label htmlFor="" className="mb-0">
                    Searchable on PrimeAlpha
                  </label>
                  <div className="form-switch">
                    {values.is_searchable_on_pa ? "Yes" : "No"}
                    <input
                      className="form-check-input ms-3"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      name="showOnCompany"
                      checked={values.is_searchable_on_pa}
                      value={values.is_searchable_on_pa}
                      onChange={(e) => {
                        values.is_searchable_on_pa =
                          !values.is_searchable_on_pa;
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md">
                <div className="add-page-switch">
                  <label htmlFor="" className="mb-0">
                    Searchable on Google
                  </label>
                  <div className="form-switch">
                    {values.is_searchable_on_google ? "Yes" : "No"}
                    <input
                      className="form-check-input ms-3"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      name="showOnCompany"
                      checked={values.is_searchable_on_google}
                      value={values.is_searchable_on_google}
                      onChange={(e) => {
                        setFieldValue(
                          "is_searchable_on_google",
                          !values.is_searchable_on_google
                        );
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-md">
                <div className="add-page-switch">
                  <label htmlFor="" className="mb-0">
                    Allow Members to Post
                  </label>
                  <div className="form-switch">
                    {values.allow_member_to_post ? "Yes" : "No"}
                    <input
                      className="form-check-input ms-3"
                      type="checkbox"
                      role="switch"
                      id="flexSwitchCheckDefault"
                      name="showOnCompany"
                      checked={values.allow_member_to_post}
                      value={values.allow_member_to_post}
                      onChange={() => {
                        setFieldValue(
                          "allow_member_to_post",
                          !values.allow_member_to_post
                        );
                      }}
                      onBlur={handleBlur}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md">
                {values.is_private && values?.allow_member_to_post && (
                  <div className="add-page-switch">
                    <label htmlFor="" className="mb-0">
                      Require Approval Before Posting
                    </label>
                    <div className="form-switch">
                      {values.need_post_approval ? "Yes" : "No"}
                      <input
                        className="form-check-input ms-3"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        name="showOnCompany"
                        checked={values.need_post_approval}
                        value={values.need_post_approval}
                        onChange={() => {
                          setFieldValue(
                            "need_post_approval",
                            !values.need_post_approval
                          );
                        }}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div
            className="d-flex justify-content-end px-4 py-3"
            style={{ borderTop: "1px solid #efefef" }}
          >
            {type === "add" ? (
              <button
                className="btn btn-primary w-20"
                disabled={!isValid || !dirty || isSubmitting}
                onClick={() => handleSubmit(values)}
              >
                {" "}
                Create
                {isSubmitting && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            ) : (
              <button
                className="btn btn-primary w-20"
                disabled={!isValid || isSubmitting}
                onClick={() => handleUpdate(values)}
              >
                {" "}
                Update
                {isSubmitting && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

export default CommunityForm;
