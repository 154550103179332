const ShimmerItem = () => {
  return (
    <div className="post-view common post-shimmer mb-3" id="PostView">
      <div className="shimmer-wrapper">
        <div className="shimmer-navbar">
          <div className="shimmer-avatar" />
          <div className="w-100">
            <div className="shimmer-name" />
            <div className="shimmer-date" />
          </div>
        </div>
        <div className="shimmer-title" />
        <div className="shimmer-image" />

        <div className="shimmer-content" />
        <div className="shimmer-content" />
      </div>
    </div>
  );
};

const Shimmer = () => {
  return (
    <div>
      <ShimmerItem />
      <ShimmerItem />
      <ShimmerItem />
    </div>
  );
};

export default Shimmer;
