import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/toast";
import { formatToDateTime } from "../../../../../helpers/formatDateTime";
import { deleteData } from "../../../../../services";
import CommunityImageViewer from "../../../../atoms/CommunityImageViewer";
import Ellipseicon from "../../../../Icons/Ellipseicon";
import NoContentCard from "../../../../molecules/NoContentCard";
import Pagination from "../../../../molecules/Pagination";
import Shimmer from "../../Community/Shimmer";
import "./styles.scss";

const Reporteddetails = ({ activeTab, isLoading, data, itemsPerPage ,getAllPostReports, currentPage, onPageChange, onItemsPerPageChange}) => {
  const [cookies] = useCookies(['t'])
  const totalItems = data?.total_record; // Calculate the number of items in the object

  const handleViewPost = (slug) => {
window.open(`${process.env.REACT_APP_COMMUNITY_URL}community/posts/${slug}`,'_blank')
  }


  const handleDeletePost = async (item) => {
  try {
    const res = await deleteData({
      endpoint : 'Post/deletePost',
      params : {id : item},
      token : cookies.t,
      type : 'community'
    })

    if(res) {
      toast.success('Post Deleted Successfully',{duration : 1000, style :  confimationStyles})
      getAllPostReports()
    }
  } catch (err) {

  }
 }
  return (
    <div className="container-list">
      {isLoading ? (
        <>
          <Shimmer />
          <Shimmer />
          <Shimmer />
          <Shimmer />
          <Shimmer />
          <Shimmer />
        </>
      ) : totalItems > 0 ? (
        data?.data?.map((item, index) => (
          <div
            key={index}
            className={`singlecard-container ${
              activeTab === "Reported" && index === 0 ? "active-tab" : ""
            }`}
          >
            <div className="left-content" style={{alignItems: 'start'}}>
              <CommunityImageViewer
                type="Company"
                imageUrl={item.profile_photo}
                viewType="community"
              />
              <div className="member-details">
                <div>
                  <span className="member-name">{item.user_name}</span>
                </div>
                <div className="member-community-details">
                  <span className="community-detail">{item.post_name}</span>
                  <Ellipseicon />{" "}
                  <span className="community-detail">
                    {item.community_name}
                  </span>
                </div>
                {item?.remark && (
                  <div className="community-detail mt-3">{item?.remark}</div>
                )}
                <span className="post-date">
                  {" "}
                  {formatToDateTime(item.created_date)}
                </span>{" "}
              </div>
            </div>
            <div className="action-btn-container me-3">
                  <button
                    className={`action-btn-primary`}
                    onClick={() => handleViewPost(item?.slug)}
                    // disabled={loading}
                    style={{minWidth: '100px'}}
                  >
                    View
                    {/* {loading && activeIndex === index ? "Accepting..." : "Accept"} */}
                  </button>
                  <button
                    className={`action-btn-secondary`}
                    onClick={() => handleDeletePost(item?.post_id)}
                    // disabled={loading}
                    style={{minWidth: '120px'}}
                  >
                    Delete Post
                    {/* {loading && activeIndex === index ? "Declining..." : "Decline"} */}
                  </button>
                </div>
          </div>
        ))
      ) : (
        <NoContentCard title="No Data Found" />
      )}

      {totalItems > itemsPerPage && (
        <div className="mt-3">
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onItemsPerPageChange={onItemsPerPageChange}
          />
        </div>
      )}
    </div>
  );
};

export default Reporteddetails;
