export const createLinkText = (text) => {
  // Regular expression to detect URLs
  const urlPattern = /(https?:\/\/[^\s]+)/g;

  // Split the text by URLs and map over it
  return text.split(urlPattern).map((part, index) => {
    // If it's a URL, return a clickable link
    if (part.match(urlPattern)) {
      return (
        <a key={index} href={part} target="_blank" rel="noopener noreferrer">
          {part}
        </a>
      );
    }
    // Otherwise, return the plain text part
    return part;
  });
};
