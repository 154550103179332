import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSubscription } from '../../SubscriptionContext';
import { setPeopleData } from '../../store/slices/peopleSlice';

const LogoutHelper = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setTop } = useSubscription();
    const [, , removeCookies] = useCookies([
      "t",
      "un",
      "email",
      "cuid",
      "username",
      "token",
      "ut",
      "cid",
      "role",
    ]);
    const cookieNames = [
      "t",
      "un",
      "email",
      "cuid",
      "cid",
      "role",
      "username",
      "token",
      "ut",
    ];
  
    const cookiesNameDomain = ["token", "username" , "ut" , "un" , "cuid"];
  
    const handleLogout = () => {

        debugger;
      cookieNames.forEach((cookieName) => {
        removeCookies(cookieName, {
          path: "/",
        });
      });
      cookiesNameDomain.forEach((cookieName) => {
        removeCookies(cookieName, {
          path: "/",
          domain: process.env.REACT_APP_DOMAIN,
        });
      });
  
      localStorage.removeItem("emailrequest");
      localStorage.removeItem("trial_info");
  
      setTop(0);
  
      dispatch(setPeopleData({}));
  
      navigate("/", { replace: true });
    };

    useEffect(() => {
        const handleMessage = (event) => {
            console.log('event',event)
            // Verify the origin of the message
            // if (event.origin !== process.env.REACT_APP_COMMUNITY_URL) return;


            if (event.data.action === 'logout') {
                handleLogout();
            }
        };

        window.addEventListener('message', handleMessage);
        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return null; // No UI needed
};

export default LogoutHelper;
