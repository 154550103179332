import moment from "moment";
import { useEffect, useState } from "react";
import Calender from "../../../../Icons/Calender";
import "./styles.scss";

const EventPreview = ({ image, info, type = "preview", data }) => {
  const [imageSrc, setImageSrc] = useState("");
  const [mediaType, setMediaType] = useState("");

  useEffect(() => {
    if (image) {
      const objectUrl = URL.createObjectURL(image);
      setImageSrc(objectUrl);

      // Determine if the blob is an image or a video
      const fetchBlobType = async () => {
        try {
          const response = await fetch(objectUrl);
          const blob = await response.blob();
          const mimeType = blob.type;

          if (mimeType.startsWith("image/")) {
            setMediaType("image");
          } else if (mimeType.startsWith("video/")) {
            setMediaType("video");
          }
        } catch (error) {
          console.error("Error fetching blob type:", error);
        }
      };

      fetchBlobType();

      return () => URL.revokeObjectURL(objectUrl);
    }
  }, [image]);

  return (
    <>
      {type === "preview" ? (
        <div className="event-preview">
          {imageSrc && (
            <div>
              {mediaType === "video" ? (
                <video src={imageSrc} className="event-poster" />
              ) : (
                <img
                  src={imageSrc}
                  className="event-poster"
                  alt="Event-Preview"
                />
              )}
            </div>
          )}
          <div>
            <div className="event-time">
              {moment(data?.start_date).format("MMM D, YYYY")},{" "}
              {moment(data?.start_time, "HH:mm:ss").format("h:mm A")} -{" "}
              {moment(data?.end_date).format("MMM D, YYYY")},{" "}
              {moment(data?.end_time, "HH:mm:ss").format("h:mm A")} (
              {data?.timezone?.value})
            </div>
            <div className="h4-j">{data.event_name}</div>
            <label className="mb-0 mt-1" style={{ fontWeight: 400 }}>
              {JSON.parse(data?.location)?.address}
            </label>
          </div>
        </div>
      ) : (
        <div id="EventPreview">
               {info?.image && (
            <>
              {/(.mp4|.webm|.ogg|.mov)$/i.test(info.image) ? (
                <video src={info.image} controls className="post-video" />
              ) : (
                <img src={info.image} alt="Post media" className="preview-img" />
              )}
            </>
          )}
          <div className="content">
            <div className="event-details">
              <div className="d-flex align-items-start gap-3">
                <div className="date-preview">
                  <div className="date">
                    {moment(info?.start_date).format("DD")}
                  </div>
                  <div className="month">
                    {moment(info?.start_date).format("MMM")}
                  </div>
                </div>
                <div className="information">
                  <h1 className="h5-i">{info?.event_name}</h1>
                  <p className="p mb-0">
                    {moment(info?.start_date).format("MMM D, YYYY")},{" "}
                    {moment(info?.start_time, "HH:mm:ss").format("h:mm A")} -{" "}
                    {moment(info?.end_date).format("MMM D, YYYY")},{" "}
                    {moment(info?.end_time, "HH:mm:ss").format("h:mm A")} (
                    {JSON.parse(info?.timezone)?.value}),{" "}
                    {JSON.parse(info?.location)?.name},
                    {JSON.parse(info?.location)?.address}
                  </p>
                  <a
                    href={`https://www.google.com/maps?q=${
                      JSON.parse(info?.location)?.address
                    }&ll=${info?.lat},${info?.lng}&z=15`}
                    target="_blank"
                    style={{ fontSize: "14px" }}
                    className="text-decoration-underline"
                  >
                    View in map
                  </a>
                </div>
              </div>

              <div className="calender-icon">
                <Calender />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EventPreview;
