import moment from "moment";
import { useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/toast";
import { createLinkText } from "../../../../../helpers/createLinkText";
import { deleteData } from "../../../../../services";
import MapWithPin from "../../../../atoms/Community/MapWithPin";
import CustomMenu from "../../../../atoms/CustomMenu";
import PeopleProfileCard from "../../../../atoms/PeopleProfileCard";
import LikeComponent from "../../../../atoms/postComponents/LikeComponent";
import ShareComponent from "../../../../atoms/postComponents/LikeComponent/ShareComponent";
import Comment from "../../../../Icons/Comment";
import Like from "../../../../Icons/Like";
import Menu from "../../../../Icons/Menu";
import LinkPreview from "../../../../molecules/LinkPreview";
import EventPreview from "../EventPreview";
import CommentSection from "./CommentsSection";
import ReportFunctionality from "./ReportFunctionality";
import "./styles.scss";

const PostView = ({
  profile,
  name,
  data,
  peopleDetails,
  role,
  is_delete_allowed = true,
  fetchUpdated,
  is_share_allowed = false,
  onConfirm
}) => {
  const [cookies] = useCookies(["t", "cuid"]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedrow, setSelectedRow] = useState(null);
  const [showComments, setShowComments] = useState(false); // state for showing/hiding comments
  const [openReport, setOpenReport] = useState(false);
  // state to manage new comment input

  // Initialize the likes state
  const [likes, setLikes] = useState(data?.likesCount || 0);
  const [isLiked, setIsLiked] = useState(data?.likedByUser || false);
  const [commentsCount, setCommentsCount] = useState(data?.commentsCount || 0);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleCommentClick = () => {
    setShowComments(!showComments); // toggle comment section visibility
  };

  // Handle Comment Increment/Decrement
  const handleCommentIncrement = () => {
    setCommentsCount((prev) => prev + 1);
  };

  const handleCommentDecrement = () => {
    setCommentsCount((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const handleDeletePost = async (id) => {
    const res = await deleteData({
      endpoint: "Post/deletePost",
      params: { id: id },
      type: "community",
    });

    if (res?.data?.status) {
      toast.success("Post Deleted Successfully", {
        style: confimationStyles,
        duration: 1000,
      });
      fetchUpdated();
    }
  };

  const handleLikeIncrement = () => {
    if (!isLiked) {
      setLikes(likes + 1);
      setIsLiked(true);
    }
  };

  const handleLikeDecrement = () => {
    if (isLiked) {
      setLikes(likes - 1);
      setIsLiked(false);
    }
  };

  const menu = [
    {
      id: "report",
      label: "Report Spam",
      onClick: () => {
        setOpenReport(true);
        setAnchorEl(null);
      },
    },
  ];

  if (
    role &&
    is_delete_allowed &&
    (role !== "Member" || data?.creator?.id === cookies.cuid)
  ) {
    menu.push({
      id: "delete",
      label: <span style={{ color: "#BE0F00" }}>Delete Post</span>,
      onClick: () => handleDeletePost(data?.id),
    });
  }

  return (
    <div className="post-view common" id="PostView">
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex gap-2 align-items-center">
          <PeopleProfileCard
            profile={profile}
            name={name}
            size={48}
            borderRadius="50%"
            fontSize="14px"
            color="#ffffff"
          />
          <div>
            <div className="h5-i">{name}</div>
            {/* <div className="p">{timeAgo(data?.created_date)}</div> */}
            <div className="p">
              {moment(data?.created_date).format("MMM D, YYYY hh:mm a")}
            </div>
          </div>
        </div>

        <div>
          {cookies.cuid && (
            <span
              type="button"
              onClick={(event) => handleStatusMenu(event, data)}
            >
              <Menu />
            </span>
          )}

          <CustomMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            items={menu}
          />
        </div>
      </div>

      <div className="data mt-3">
        {data?.text && (data?.image || data?.type === "Article") && (
          <span style={{ whiteSpace: "pre-wrap" }}>
            {createLinkText(data.text)}
          </span>
        )}

        {data?.type === "Post" && data?.image && (
          <>
            {/(.mp4|.webm|.ogg|.mov)$/i.test(data.image) ? (
              <video src={data.image} controls className="post-video" />
            ) : (
              <img src={data.image} alt="Post media" className="post-image" />
            )}
          </>
        )}
      </div>

      {data?.type === "Post" && !data.image && (
        <div className="text-view common">{createLinkText(data.text)}</div>
      )}

      {data?.type === "Article" && data?.link && (
        <div>
          <LinkPreview url={data?.link} />
        </div>
      )}

      {data?.type === "Event" && <EventPreview type="post" info={data} />}

      {data?.type === "Location" && (
        <div style={{ marginTop: "10px" }}>
          <MapWithPin
            location={{ lat: data?.lat, lng: data?.lng }}
            type="view"
          />
          <div className="location-info">
            <h1 className="h5-i">{JSON.parse(data?.location)?.name}</h1>
            <p className="p mb-0">{JSON.parse(data?.location)?.address}</p>
            <a
              href={`https://www.google.com/maps?q=${
                JSON.parse(data?.location)?.name
              }&ll=${data?.lat},${data?.lng}&z=15`}
              target="_blank"
              style={{ fontSize: "14px" }}
              className="text-decoration-underline"
            >
              View in map
            </a>
          </div>
        </div>
      )}

      <div className="likes-comments">
        <div className="likes p mb-0">
          {likes > 0 ? (
            <div className="d-flex align-items-center gap-1">
              <Like /> {likes}
            </div>
          ) : (
            ""
          )}
        </div>
        <div
          className="comments p mb-0 cursor-pointer"
          onClick={() => setShowComments((prev) => !prev)}
        >
          {commentsCount > 0
            ? `${commentsCount} ${commentsCount === 1 ? "Comment" : "Comments"}`
            : null}
        </div>
      </div>
      <div className="social-footer">
        <LikeComponent
          post_id={data?.id}
          is_liked={isLiked}
          incrementLike={handleLikeIncrement}
          decrementLike={handleLikeDecrement}
        />

        <div
          id="post_comment"
          className="center-div gap-2 cursor-pointer"
          onClick={handleCommentClick}
        >
          <Comment />
          Comment
        </div>

        {is_share_allowed && <ShareComponent post_id={data?.slug} />}
      </div>

      {/* Comment Section */}
      {showComments && (
        <CommentSection
          // comments={comments}
          profile={profile}
          name={name}
          post_id={data.id}
          isOpen={showComments}
          peopleDetails={peopleDetails}
          incrementComment={handleCommentIncrement}
          decrementComment={handleCommentDecrement}
        />
      )}

      {openReport && selectedrow && (
        <ReportFunctionality
          type="post"
          openReport={openReport}
          id={selectedrow?.id}
          onClose={() => setOpenReport(false)}
          setOpenReport={setOpenReport}
          onConfirm={onConfirm}
        />
      )}
    </div>
  );
};

export default PostView;
