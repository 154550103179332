import { Form, Formik } from "formik";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import useRecaptcha from "../../../../hooks/useRecaptcha";

import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { expitationTime } from "../../../../helpers/expirationTime";
import { getData, postData } from "../../../../services";
import Error from "../../../atoms/Error";
import InputField from "../../../atoms/InputField";
import ReCaptchaTerms from "../../../atoms/RecaptchaTerms";

const WalkinForm = () => {
  const [pageError, setPageError] = useState(null);
  const [cookie, setCookie] = useCookies(["t"]);
  const navigate = useNavigate();

  //recaptcha handling
  const [refreshRecaptcha, setRefreshRecaptcha] = useState(false);
  const recaptchaToken = useRecaptcha("ACCESSTRIAL", refreshRecaptcha);

  const initialValues = {
    email: "",
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const recaptchaResponse = await getData({
      endpoint: "Wrapper/verifyRecaptcha",
      params: { token: recaptchaToken, expected_action: "ACCESSTRIAL" },
    });

    if (recaptchaResponse?.riskScore > 0.5) {
      try {
        const lowercaseEmail = values.email.toLowerCase();
        const updatedValues = { ...values, email: lowercaseEmail };
        const { data: resultData, isLoading } = await postData({
          endpoint: "/CustomerAuth/walkin/login",
          data: {},
          params: updatedValues,
          // token: 'your_token',
        });

        if (resultData.status) {
          toast.success(resultData.message, {
            duration: 1000,
            style: confimationStyles,
          });
          setCookie("cuid", resultData.data.user_id, {
            path: "/",
            expires: expitationTime(),
            domain: process.env.REACT_APP_DOMAIN,
          });
          navigate("/auth/verify", {
            state: { skipHubspotValidation: true },
          });
        } else {
          toast.error(resultData.message, {
            duration: 1000,
            style: errorStyles,
          });
          // setPageError('This email is not registered with us. Please enter your registered email. If your email has changed, please email us at help@primealpha.com.')
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Recaptcha Not Verified", {
        duration: 1000,
        style: errorStyles,
      });

      // If recaptcha is not verified, refresh the token
      setRefreshRecaptcha((prev) => !prev); // Toggle to regenerate token
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-group">
            <InputField
              label="Email"
              type="text"
              id="email"
              name="email"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter Email Address"
              autoComplete="off"
              className={`${
                ((touched.email || isSubmitting) && errors.email) || pageError
                  ? "error-input"
                  : ""
              }`}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\s/g, "");
              }}
            />
            {touched.email && errors.email && <Error error={errors.email} />}
            {pageError && values.email && !errors.email && (
              <Error error={pageError} />
            )}
          </div>

          <button
            className="btn btn-primary w-100 mt-4"
            disabled={isSubmitting}
            type="submit"
          >
            Send OTP
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <button
            className="btn btn-outline-dark w-100 mt-4"
            type="button"
            onClick={() => navigate("/auth/login")}
          >
            Continue with Password & OTP
          </button>

          <ReCaptchaTerms classes="mt-3 mb-3" />
        </Form>
      )}
    </Formik>
  );
};

export default WalkinForm;
