import { Box } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { confimationStyles } from "../../../../assets/styles/toast";
import { getData, postData } from "../../../../services";
import ContentContainer from "../../../atoms/ContentContainer";
import CustomButton from "../../../atoms/CustomeButton";
import Heading from "../../../atoms/Heading";
import Search from "../../../atoms/Search";
import Modal from "../../../molecules/Modal/Normal";
import TabStrip from "../../../molecules/TabStrip";
import CommunityForm from "./CommunityForm";

import {
  getGeography,
  getSector,
  getType,
} from "../../../../services/editHelper";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import CommunityTab from "./CommunityTab";
import "./styles.scss";

const Community = () => {
  const [cookies] = useCookies(["t", "cuid"]);
  const [filters, setFilters] = useState({});
  const [myCommunityData, setMyCommunityData] = useState(null);
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchValue, setSearchvalue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("All Communities");
  const [modalState, setModalState] = useState({
    id: null,
    isOpen: false,
  });
  const [filterData, setFilterData] = useState({
    Geography: [],
    Sector: [],
    Product: [],
  });

  const fetchInitialData = async () => {
    try {
      const [gData, sData, tData] = await Promise.all([
        getGeography(),
        getSector(),
        getType(),
      ]);

      const updatedGData = gData?.map((item) => ({
        ...item,
        value: `${item.label}`,
      }));
      const updatedSData = sData?.map((item) => ({
        ...item,
        value: `${item.label}`,
      }));
      const updatedTData = tData?.map((item) => ({
        ...item,
        value: `${item.label}`,
      }));

      // Create unique arrays using Set
      const uniqueGeography = [
        ...new Set(updatedGData?.map((item) => item.value)),
      ];
      const uniqueSector = [
        ...new Set(updatedSData?.map((item) => item.value)),
      ];
      const uniqueProduct = [
        ...new Set(updatedTData?.map((item) => item.value)),
      ];

      // Set data into the filterData object
      setFilterData((prevFilterData) => ({
        ...prevFilterData,
        Geography: uniqueGeography,
        Sector: uniqueSector,
        Product: uniqueProduct,
      }));
    } catch (error) {
      console.error("Failed to fetch data:", error);
    }
  };

  // Fetch data on initial render
  useEffect(() => {
    fetchInitialData();
  }, []);

  const onTabChange = (event, value) => {
    setActiveTab(value);
    setCurrentPage(1)
  };

  const _getComponent = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  // Get user info from the Redux store
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  const getMyCommunity = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getData({
        endpoint: "Community/getMyCommunity",
        type: "community",
        token : cookies.t,
        params: {
          user_id: cookies.cuid,
          search_query: searchValue,
          page_limit: itemsPerPage,
          page: currentPage,
          geography:
            filters?.Geography?.length > 0
              ? JSON.stringify(filters?.Geography)
              : null,
          sector:
            filters?.Sector?.length > 0
              ? JSON.stringify(filters?.Sector)
              : null,
          product_type:
            filters?.Product?.length > 0
              ? JSON.stringify(filters?.Product)
              : null,
        },
      });
      setMyCommunityData(res);
    } catch (error) {
      console.error("Failed to fetch community data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [cookies.cuid, currentPage, itemsPerPage, searchValue, filters]);

  // Fetch community data with memoized useCallback to prevent unnecessary function re-creations
  const getCommunityData = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getData({
        endpoint: "Community/getAllCommunity",
        type: "community",
        params: {
          user_id: cookies.cuid,
          search_query: searchValue,
          page_limit: itemsPerPage,
          page: currentPage,
          geography:
            filters?.Geography?.length > 0
              ? JSON.stringify(filters?.Geography)
              : null,
          sector:
            filters?.Sector?.length > 0
              ? JSON.stringify(filters?.Sector)
              : null,
          product_type:
            filters?.Product?.length > 0
              ? JSON.stringify(filters?.Product)
              : null,
        },
        token : cookies.t,
      });
      setData(res);
    } catch (error) {
      console.error("Failed to fetch community data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [cookies.cuid, currentPage, itemsPerPage, searchValue, filters]);

  // UseEffect to trigger fetching data on dependencies change
  useEffect(() => {
    getMyCommunity();
    getCommunityData();
  }, [getCommunityData]);

  // Handler for search functionality
  const handleSearch = useCallback((value) => {
    setSearchvalue(value);
    setCurrentPage(1); // Reset to first page after search
  }, []);

  // Memoize total items for better performance
  const totalItems = useMemo(() => data?.total_record ?? 0, [data]);

  // Handle page change
  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setItemsPerPage(perPage);
    setCurrentPage(1); // Reset to first page after items per page change
  }, []);

  const handleEditModal = async (id) => {
    const communityData = await getData({
      type: "community",
      endpoint: "Community/getCommunityDetails",
      params: {
        id: id,
      },
      token: cookies.t
    });

    setModalState((prev) => ({
      ...prev,
      id: id,
      data: communityData,
      isOpen: true,
    }));
  };

  const handleJoinCommunity = async ({ community_id , is_private}) => {
    try {
      const res = await postData({
        endpoint: "Community/joinCommunity",
        params: { user_id: cookies.cuid, community_id },
        type: "community",
        token: cookies.t
      });

      if (res?.data?.status) {
        toast.success(is_private ? 'Community Joining Request Sent Successfully': "Community Joined Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        getCommunityData();
        if(!is_private){
          getMyCommunity();
        }
      }
    } catch {}
  };

  let dataArray = [
    {
      id: 0,
      label: "All Communities",
      view: (
        <CommunityTab
          isLoading={isLoading}
          data={data}
          handleEditModal={handleEditModal}
          handleJoinCommunity={handleJoinCommunity}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
          refetch={getCommunityData}
          refetchOther={getMyCommunity}
        />
      ),
    },
    {
      id: 1,
      label: "My Communities",
      view: (
        <CommunityTab
          isLoading={isLoading}
          data={myCommunityData}
          handleEditModal={handleEditModal}
          handleJoinCommunity={handleJoinCommunity}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          onItemsPerPageChange={handleItemsPerPageChange}
          refetch={getMyCommunity}
          refetchOther={getCommunityData}
        />
      ),
    },
  ];

  return (
    <ContentContainer id="Community-Container">
      <Heading title="Community" type="main">
        <div className="d-flex gap-2">
          {userInfo?.community_creation && (
            <CustomButton
              text="Create Community"
              type="btn-primary"
              handleClick={() =>
                setModalState({
                  isOpen: true,
                  id: uuidv4(),
                })
              }
            />
          )}
        </div>
      </Heading>

      <TabStrip
        activeTab={activeTab}
        onTabChange={onTabChange}
        data={dataArray}
        styleType="children"
      >
        <div className="d-flex gap-2">
          <Search
            variant="search_lg"
            placeholder="Search by Name & Type"
            style={{ width: "350px" }}
            onSearchEmit={handleSearch}
          />
          <FilterComponent
            filterData={filterData}
            onFilterChange={(data) => {
              setFilters(data);
            }}
            type="right"
          />
        </div>
      </TabStrip>

      <div className="main-box-company">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ py: "1rem" }}>{_getComponent(activeTab)}</Box>
        </Box>
      </div>

      {/* Create Community Modal */}
      {modalState.isOpen && (
        <Modal
          title={modalState?.data ? "Edit Community" : "Create Community"}
          isActive={modalState.isOpen}
          onClose={() => setModalState((prev) => ({ ...prev, isOpen: false }))}
          width="1000px"
        >
          <CommunityForm
            id={modalState.id}
            onClose={() =>
              setModalState((prev) => ({ ...prev, isOpen: false }))
            }
            type={modalState?.data ? "edit" : "add"}
            initialData={modalState?.data}
            refetch={getMyCommunity}
          />
        </Modal>
      )}
    </ContentContainer>
  );
};

export default Community;
