import { useSubscription } from ".";
import "../components/molecules/Modal/LogoutModal/logout-modal.scss";

import { useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { confimationStyles } from "../assets/styles/toast";
import CloseButton from "../components/atoms/CloseButton";
import { useCreditCount } from "../contexts/CreditCountContext";
import { USERTYPE } from "../helpers/constantData";
import { postData } from "../services";
import { fetchUserData } from "../store/slices/peopleSlice";

const SubscriptionBanner = () => {
  const [cookies] = useCookies(["cuid"]);
  const upgrade_link = process.env.REACT_APP_PAYMENT_LINK;
  const [activatingTrial, setActivatingTrial] = useState(false);
  const { getCreditInfo } = useCreditCount();
  const decodedString = cookies?.ut ? atob(cookies?.ut) : null;
  const dispatch = useDispatch();

  const staticData = {
    subscription_enable: true,
    is_trial: true,
    subs_days: 7,
    subs_start_date: new Date(),
    subs_end_date: new Date(new Date().setDate(new Date().getDate() + 5)),
    investor_database: true,
    resource: true,
  };

  const {
    isSubscriptionExpired,
    isShow,
    setIsShow,
    setIsTrialActivationModalVisible,
    setTrialData,
  } = useSubscription();

  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  // if (!isSubscriptionExpired) return null;

  const trial_data_string = localStorage.getItem("trial_info");
  let trial_data;

  if (trial_data_string !== null) {
    // Only parse if the string is not null
    trial_data = JSON.parse(trial_data_string);
  }

  const endDate =
    userInfo?.subs_end_date ||
    userInfo?.trial_end ||
    trial_data?.subs_end_date ||
    trial_data?.trial_end;

  const localMode =
    userInfo?.is_last_subs_mode_trial || trial_data?.is_last_subs_mode_trial;

  const walkInTrial =
    userInfo?.walkin_trial ?? trial_data?.walkin_trial ?? null;

  const handleTrialActivation = async () => {
    try {
      setActivatingTrial(true);

      const res = await postData({
        endpoint: "Trial/enableTrial",
        params: { people_id: cookies.cuid },
      });

      setTrialData(res?.data?.data);
      getCreditInfo();
      dispatch(fetchUserData(cookies.cuid));
    } catch (err) {
    } finally {
      setActivatingTrial(false);
      setIsTrialActivationModalVisible(true);
      setIsShow(false);
    }

    toast.success("Your free trial has been activated successfully!", {
      style: confimationStyles,
      duration: 1000,
    });
  };

  return (
    <div
      className={`modal ${isSubscriptionExpired && isShow ? "active" : ""}`}
      // style={{ pointerEvents: "bounding-box" }}
    >
      <div className="modal-content">
        {!endDate && (
          // <button
          //   className="cursor-pointer"
          //   onClick={() => setIsShow(false)}
          //   aria-label="Close"
          //   style={{ position: "absolute", top: "10px", right: "10px" }}
          // >
          //   <CloseBtnIcom/>
          // </button>

          <div
            style={{
              position: "absolute",
              top: "15px",
              right: "15px",
              zIndex: 10,
            }}
            className="cursor-pointer"
          >
            <CloseButton
              onClose={(e) => {
                e.stopPropagation();
                setIsShow(false);
              }}
            />
          </div>
        )}

        {decodedString === USERTYPE ? (
          <div className="modal-body py-4">
            <div className="d-flex align-items-center justify-content-center flex-column gap-2">
              {/* <CircleAvatar
              name={userInfo?.name}
              size="90px"
              fontSize="25px"
              color="#F4F4F4"
              borderRadius="12px"
              iconColor="#3D9BE9"
              border="1px solid #efefef"
            /> */}
              <p
                className="modal-heading mb-2 mt-3"
                style={{ fontSize: "22px" }}
              >
                {/* Your {trial_data?.is_trial ? "Free Trial" : "Subscription"} has
              expired. */}
                Unlock Your Complete Profile
              </p>
            </div>

            {/* )} */}
            <p
              className={`modal-description mt-2 mb-4`}
              style={{ textAlign: "center" }}
            >
         
                <>
                  Complete your profile to unlock full access to our premium database and marketing tools. Contact PrimeAlpha today!
                  <br />{" "}
                </>
            
            </p>
            <div className="d-flex w-100 mt-3">
              {!endDate && walkInTrial === null && trial_data?.allow ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={() => handleTrialActivation()}
                  style={{ flex: "1 0 45%" }}
                >
                  Activate Free Account
                  {activatingTrial && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-dark"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              ) : (
                <button
                  className="btn btn-outline-dark"
                  onClick={() => setIsShow(false)}
                  style={{ flex: "1 0 45%" }}
                >
                  Cancel
                </button>
              )}

              <button
                className="btn btn-primary mt-0"
                onClick={() =>
                  window.open(
                    "mailto:info@primeAlpha.com?subject=Complete Profile Activation",
                    "_blank"
                  )
                }
                style={{ flex: "1 0 45%", marginLeft: "10px" }}
              >
                Contact Now
              </button>
            </div>
          </div>
        ) : (
          <div className="modal-body py-4">
            <div className="d-flex align-items-center justify-content-center flex-column gap-2">
              {/* <CircleAvatar
              name={userInfo?.name}
              size="90px"
              fontSize="25px"
              color="#F4F4F4"
              borderRadius="12px"
              iconColor="#3D9BE9"
              border="1px solid #efefef"
            /> */}
              <p
                className="modal-heading mb-2 mt-3"
                style={{ fontSize: "22px" }}
              >
                {/* Your {trial_data?.is_trial ? "Free Trial" : "Subscription"} has
              expired. */}

                {!endDate
                  ? "Activate Your Subscription"
                  : localMode
                  ? "Upgrade Your Subscription"
                  : "Renew Your Subscription"}
              </p>
            </div>

            {/* )} */}
            <p
              className={`modal-description ${localMode ? "mt-2" : "mt-2"}`}
              style={{ textAlign: "center" }}
            >
              {localMode ? (
                `Thank you for exploring PrimeAlpha. To continue accessing our investor database and powerful marketing tools, upgrade now and unlock the full potential of our platform. Leverage our features to supercharge your investor engagement.
`
              ) : (
                <>
                  Complete your profile to unlock full access to our premium
                  database and marketing tools. Contact PrimeAlpha today!
                  <br />{" "}
                  <p>
                    <br />
                    Don’t miss out - activate your account today!
                  </p>
                </>
              )}
            </p>
            <div className="d-flex w-100 mt-3">
              {!endDate && walkInTrial === null && trial_data?.allow ? (
                <button
                  className="btn btn-outline-dark"
                  onClick={() => handleTrialActivation()}
                  style={{ flex: "1 0 45%" }}
                >
                  Activate Free Account
                  {activatingTrial && (
                    <span
                      className="spinner-border spinner-border-sm ms-2 text-dark"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              ) : (
                <button
                  className="btn btn-outline-dark"
                  onClick={() => setIsShow(false)}
                  style={{ flex: "1 0 45%" }}
                >
                  Cancel
                </button>
              )}

              <button
                className="btn btn-primary mt-0"
                onClick={() => window.open(upgrade_link, "_blank")}
                style={{ flex: "1 0 45%", marginLeft: "10px" }}
              >
                {!endDate
                  ? "Subscribe Now"
                  : localMode
                  ? "Upgrade Now"
                  : "Renew Now"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubscriptionBanner;
