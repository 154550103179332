import { Outlet } from "react-router-dom";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import "./styles.scss";
const CommunityRequestLayout = () => {
  return (
    <>
      <Navbar searchRequired={false} sideBarRequired={false} />
      <div id="Community-Requests-Layout">
        <Outlet />
      </div>

      <div className="footer-container">
        <Footer />
      </div>
    </>
  );
};

export default CommunityRequestLayout;
