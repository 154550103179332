const Report = ({ classes, styles }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 26 26"
      width="18"
      height="18"
      className={classes}
      style={styles}
    >
      <path
        d="m23.34,9.48l-3.5-6c-.893-1.53-2.547-2.48-4.319-2.48h-7.072c-1.771,0-3.426.95-4.319,2.48L.631,9.48c-.907,1.554-.907,3.485,0,5.039l3.5,6c.893,1.53,2.547,2.48,4.319,2.48h7.072c1.771,0,3.426-.95,4.319-2.48l3.5-6c.907-1.554.907-3.485,0-5.039Zm-1.728,4.031l-3.5,6c-.536.918-1.528,1.488-2.591,1.488h-7.072c-1.063,0-2.056-.57-2.591-1.488l-3.5-6c-.544-.933-.544-2.091,0-3.023l3.5-6c.536-.918,1.528-1.488,2.591-1.488h7.072c1.063,0,2.056.57,2.591,1.488l3.5,6c.544.933.544,2.091,0,3.023Zm-10.613-1.012v-5.5c0-.553.448-1,1-1s1,.447,1,1v5.5c0,.553-.448,1-1,1s-1-.447-1-1Zm2.5,4c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Z"
        fill="#4E5660"
      />
    </svg>
  );
};

export default Report;
