import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./styles.scss";

const CustomMenu = ({ anchorEl, open, onClose, items }) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right", // Aligns the menu to the right of the anchor
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right", // Controls the transformation point of the menu
      }}
      PaperProps={{
        sx: {
          border: "1px solid #DEE3E7",
          boxShadow: "none",
          minWidth: "200px",
          marginTop: "8px",
          marginRight: "50px",
          borderRadius: "10px",
          padding: 0,
          "& .MuiList-root": {
            padding: "0px !important",
          },
        },
      }}
      // anchorPosition={"auto"}
    >
      {items?.map((item, index) => (
        <MenuItem
          key={index}
          onClick={item.onClick}
          sx={{
            "&:hover": {
              backgroundColor: "#F5F7F9", // Change to desired hover background color
            },
            color: "#1B242C",
            fontSize: "16px",
            fontWeight: "500",
            height: "44px",
          }}
        >
          <div className="d-flex align-items-center gap-2 custom-menu-list">
            {item?.icon && <i>{item?.icon}</i>}
            <span>{item.label}</span>
          </div>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default CustomMenu;
