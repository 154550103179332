import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../../assets/styles/toast";
import { postData } from "../../../../../../services";
import Modal from "../../../../../molecules/Modal/Normal";


const ReportFunctionality = ({
  id,
  type = "post",
  openReport,
  setOpenReport,
  onConfirm
}) => {
  const [modal, setModal] = useState(false);
const [cookies] = useCookies(['t','cuid'])
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState(""); // State to store the remark


  useEffect(() => {
    if (openReport) {
      setModal(true);
    }
  }, [openReport]);
  const handleRemarkChange = (e) => {
    setRemark(e.target.value); // Update remark state with textarea input
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const params =
      type === "post"
        ? { user_id: cookies.cuid, post_id: id, remark: remark }
        : type === "community"
        ? { user_id: cookies.cuid, community_id: id, remark: remark }
        : {};

    try {
      const res = await postData({
        endpoint: "ReportSpam/reportSpam",
        data: params,
        type: "community",
        token: cookies.t
      });

      if (res) {
        const message =
          type === "post"
            ? "Post Reported Successfully"
            : type === "community"
            ? "Community Reported Successfully"
            : "";
        toast.success(message, {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
      setModal(false); // Close the modal after submission
      setOpenReport(false);
      setRemark(""); // Clear the remark after submission if needed
      if(onConfirm){
        onConfirm()
      }
    }
  };

  const handleclose = () => {
    if (openReport) {
      setOpenReport(false);
      setModal(false);
    } else {
      setModal(false);
    }
  };

  return (
    <>
      <div className="center-div gap-2" onClick={() => setModal(true)}>
        {/* <Report classes={"me-2"} /> */}
        {type === "community" ? "Report" : "Report Spam"}
      </div>

      {modal && (
        <Modal
          isActive={modal}
          title={type === "community" ? "Report community" : "Report Spam"}
          onClose={handleclose}
        >
          <div className="form-group">
            <label htmlFor="remark">Remark</label>
            <textarea
              id="remark"
              value={remark}
              onChange={handleRemarkChange} // Capture the change in the textarea
            />
          </div>
          <div className="d-flex justify-content-end mt-3 mb-2">
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ReportFunctionality;
