import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getData } from "../../../../../services";
import FormHeading from "../../../../atoms/FormHeading";
import Footer from "../../../../molecules/Footer";
import Navbar from "../../../../molecules/Navbar";
import Product from "../index";
import "./styles.scss";

const SharedProduct = () => {
  const getProductDetail = () => {};

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const product_id = params.get("id");
  const id = params.get("link_id");
  const [isExpired, setIsExpired] = useState(false);

  const checkExpiry = async () => {
    const res = await getData({
      endpoint: "ContentSharing/checkLinkExpiry",
      params: {
        link_id: id,
      },
    });

    if (res.response === 200) {
      //   console.log("called");
      setIsExpired(false);
      getProductDetail();
    } else {
      setIsExpired(true);
    }
  };

  useEffect(() => {
    checkExpiry();
  }, []);

  return (
    <>
      <Navbar />
      <div className="shared_product_containter">
        {isExpired ? (
          <div className="shared-expired">
            <FormHeading
              title="Link Expired"
              description="This link has already been expired or Revoked"
              type="center"
            />
          </div>
        ) : (
          <Product type="shared" product_id={product_id} />
        )}
      </div>
      <div className="footer-container" style={{marginTop: 'auto'}}>
        <Footer />
      </div>
    </>
  );
};

export default SharedProduct;
