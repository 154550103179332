// Nothing to remain locked as of Jan 2025, as limited access is going to be their for free.

import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { USERTYPE } from "../../../helpers/constantData";
import useScreenWidth from "../../../hooks/useScreenwidth";
import { useSubscription } from "../../../SubscriptionContext";
import Community from "../../Icons/Community";
import CompanyIcon from "../../Icons/CompanyIcon";
import FaqIcon from "../../Icons/FaqIcon";
import HelpCenter from "../../Icons/Help-center";
import HomeIcon from "../../Icons/HomeIcon";
import Investors from "../../Icons/Investors";
import Library from "../../Icons/Library";
import Lock from "../../Icons/Lock";
import Product from "../../Icons/Product";
import Requests from "../../Icons/Requests";
import Resources from "../../Icons/Resources";
import SavedIcon from "../../Icons/SavedIcon";
import Share from "../../Icons/Share";
import Thought from "../../Icons/Thought";
import "./sidebar.scss";

// Custom NavLink wrapper to handle isActive logic
const CustomNavLink = ({
  to,
  label,
  icon,
  isLocked,
  alternatePath,
  index,
  handleSideBar,
  submenu,
}: {
  to: string;
  label: string;
  icon: any;
  isLocked: boolean | undefined;
  alternatePath: string;
  index: any;
  handleSideBar: any;
  submenu?: Array<{ to: string; label: string }>;
}) => {
  const location = useLocation();
  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);

  // Determine if the main link or any submenu link is active
  const isActive = () => {
    if (to === "/dashboard") {
      return location.pathname === "/dashboard";
    }
    return (
      location.pathname.startsWith(to) ||
      (submenu &&
        submenu.some((subLink) => location.pathname.startsWith(subLink.to)))
    );
  };

  // Check if the submenu item is active
  const isSubActive = (subLinkTo: string) => location.pathname === subLinkTo;

  const toggleSubmenu = (e: React.MouseEvent) => {
    // e.stopPropagation(); // Prevent click events from propagating to parent elements
    setIsSubmenuOpen((prev) => !prev);
  };

  return (
    <li
      key={index}
      className={`${isActive() ? "active-side" : ""}`}
      onClick={(e) => {
        if (!submenu) handleSideBar();
      }}
    >
      <NavLink
        to={isLocked ? alternatePath : to}
        className={`${isActive() ? "sidebar-active-link" : ""} sidebar-link`}
        id={`${label}-link`}
        onClick={(e) => {
          if (submenu) {
            toggleSubmenu(e); // Toggle submenu on clicking the main link
          } else {
            handleSideBar();
          }
        }}
      >
        <div className="d-flex align-items-center">
          <i>{icon}</i>
          {label}
        </div>

        {isLocked && <Lock />}
      </NavLink>

      {/* Render submenu if exists */}
      {submenu && (
        <ul className={`submenu ${isSubmenuOpen ? "submenu-open" : ""}`}>
          {submenu.map((subLink, subIndex) => (
            <li
              key={subIndex}
              className={`${isSubActive(subLink.to) ? "submenu-active" : ""}`}
            >
              <NavLink
                to={subLink.to}
                id={`${subLink.label}-link`}
                className={`submenu-link ${
                  isSubActive(subLink.to) ? "submenu-active-link" : ""
                }`}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent closing the submenu
                  handleSideBar(); // Optionally handle sidebar behavior
                }}
              >
                {subLink.label}
              </NavLink>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

const Sidebar = ({ isActive, setIsActive, sidebarRef, onClick }: any) => {
  const { isSubscriptionExpired } = useSubscription();

  const userInfo: any = useSelector(
    (state: any) => state.peopleData?.peopleData
  );
  const [cookies] = useCookies(["ut"]);
  const screenWidth = useScreenWidth();
  const decodedString = cookies?.ut ? atob(cookies?.ut) : null;

  let links = [
    {
      to: "/dashboard",
      label: "My Feed",
      icon: <HomeIcon />,
      alternatePath: "/dashboard",
    },
    {
      to: "/dashboard/my-company",
      label: "Company Profile",
      icon: <CompanyIcon />,
      alternatePath: "/dashboard/my-company",
    },
    {
      // to: "/dashboard/community",
      label: "Community",
      icon: <Community />,
      // alternatePath: "/dashboard/community",
      submenu: [
        {
          to: "/dashboard/community",
          label: "All Communities",
        },
        {
          to: "/dashboard/community/requests",
          label: "Requests",
        },
      ],
    },
    {
      to: "/dashboard/products",
      label: "Fund Directory",
      icon: <Product />,
      alternatePath: "/dashboard/products",
      isLocked: !(userInfo?.has_master_access || userInfo?.manager_database),
    },
    {
      to: "/dashboard/library",
      label: "Library",
      icon: <Library />,
      isLocked:
        !(
          userInfo?.has_master_access || userInfo?.investment_library_education
        ) ||
        (userInfo?.role === "Investor" && isSubscriptionExpired),
      alternatePath: userInfo?.product_learn_more_link,
    },
    {
      to: "/dashboard/investors",
      label: "Investors",
      icon: <Investors />,
      alternatePath: "/dashboard/investors",
      isLocked: !(userInfo?.has_master_access || userInfo?.investor_database),
    },
    {
      to: "/dashboard/resources",
      label: "Resources",
      icon: <Resources />,
      isLocked:
        isSubscriptionExpired ||
        !(userInfo?.has_master_access || userInfo?.resource),
      alternatePath: userInfo?.investor_learn_more_link,
    },
    {
      to: "/dashboard/saved-list",
      label: "Saved List",
      icon: <SavedIcon className="grey-fill-saved" />,
      alternatePath: "/dashboard/saved-list",
      isLocked: !userInfo?.investor_database || isSubscriptionExpired,
      // isLocked: userInfo?.role === "Manager/Sponsor" && isSubscriptionExpired,
    },
    {
      to: "/dashboard/shared-content",
      label: "Shared Content",
      icon: <Share />,
      alternatePath: "/dashboard/shared-content",
      isLocked: !userInfo?.content_sharing,
    },
    {
      to: "/dashboard/requests",
      label: "Requests",
      icon: <Requests />,
      alternatePath: "/dashboard/requests",
      // isLocked: userInfo?.role === "Manager/Sponsor" || isSubscriptionExpired,
    },
    {
      to: "/dashboard/help-center",
      label: "Help Center",
      icon: <HelpCenter />,
      alternatePath: "/help-center",
    },
    {
      to: "/dashboard/faq",
      label: "FAQ",
      icon: <FaqIcon />,
      alternatePath: "/faq",
    },
  ];

  if (userInfo?.has_master_access || userInfo?.is_admin) {
    // Insert "Thought Leadership" link at the 6th position
    links.splice(5, 0, {
      to: "/dashboard/my-blogs",
      label: "My Blogs",
      icon: <Thought />,
      alternatePath: "/dashboard/my-blogs",
    });
  }

  const directUserLinks = links.filter(
    (link) => link.label !== "Company Profile" && link.label !== "Requests"
  );

  const memoizedSetIsActive = useCallback((newIsActive: boolean) => {
    setIsActive(newIsActive);
  }, []);

  useEffect(() => {
    memoizedSetIsActive(screenWidth >= 1233);
  }, [screenWidth, memoizedSetIsActive]);

  const handleSideBar = () => {
    if (screenWidth < 1233) setIsActive(false);
  };

  return (
    <>
      {isActive && (
        <div
          className="sidebar"
          style={{ width: "18rem" }}
          ref={sidebarRef}
          onClick={onClick}
        >
          {/* <div className="user_details_container">
          <div className="user_details mb-3">
            <PeopleProfileCard name={userInfo?.name} profile={userInfo?.profile_photo} borderRadius="50%" size="55px"/>
            <div>
              <p className="mb-0 p-dark">{userInfo?.name}</p>
              <p className="p-light-small mb-0">{userInfo?.role}</p>

            </div>
          </div>
          <hr className="my-1"/>
          </div> */}

          <div className="ul">
            {(decodedString === USERTYPE ? directUserLinks : links).map(
              (link: any, index: number) => (
                <CustomNavLink
                  index={index}
                  to={link.to}
                  label={link.label}
                  icon={link.icon}
                  isLocked={link?.isLocked}
                  alternatePath={link.alternatePath}
                  handleSideBar={handleSideBar}
                  submenu={link.submenu}
                />
              )
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Sidebar;
