import React, { useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { confimationStyles } from "../../../../../assets/styles/toast";
import { postData } from "../../../../../services";
import Modal from "../../../../molecules/Modal/Normal";

const ReportFunctionality = ({ id, modal, setModal }) => {
  // const [modal, setModal] = useState(false);
  const [cookies] = useCookies(["cuid"]);
  const [isLoading, setIsLoading] = useState(false);
  const [remark, setRemark] = useState(""); // State to store the remark

  const handleRemarkChange = (e) => {
    setRemark(e.target.value); // Update remark state with textarea input
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const res = await postData({
        endpoint: "ReportSpam/reportSpam",
        data: { user_id: cookies.cuid, community_id: id, remark: remark },
        type: "community",
        token: cookies.t
      });

      if (res) {
        toast.success("Community Reported Successfully", {
          duration: 1000,
          style: confimationStyles,
        });
      }
    } catch (err) {
      console.log("err", err);
    } finally {
      setIsLoading(false);
      setModal(false); // Close the modal after submission
      setRemark(""); // Clear the remark after submission if needed
    }
  };

  return (
    <>
      {/* <div
        className="center-div gap-2"
        onClick={() => setModal(true)}
        type="button"
      >
        <Report classes={""} styles={{ marginRight: "5px" }} />
        Report
      </div> */}

      {modal && (
        <Modal
          isActive={modal}
          title="Report Community"
          onClose={() => setModal(false)}
        >
          <div className="form-group">
            <label htmlFor="remark">Remark</label>
            <textarea
              value={remark}
              onChange={handleRemarkChange} // Capture the change in the textarea
            />
          </div>
          <div className="d-flex justify-content-end mt-3 mb-2">
            <button
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm ms-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default React.memo(ReportFunctionality);
