import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import FormHeading from '../../atoms/FormHeading';
import EmailForm from '../../organisms/forms/authForms/EmailForm';

const Login = () => {
  const navigate = useNavigate();
  // Back btn Handling ---------------------------------------------------------------------------------------------
  const onButtonEvent = (e) => {
    e.preventDefault();
    navigate(0);
  };

  useEffect(() => {
    window.history.pushState(null, '', window.location.pathname);
    window.addEventListener("popstate", onButtonEvent);
    return () => {
      window.removeEventListener("popstate", onButtonEvent);
    };
  }, []);
  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
      <FormHeading
        title="Login to PrimeAlpha"
        description="Enter your registered email to continue."
      />
      
      <EmailForm />
    </>
  );
};

export default Login;
