import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { convertToValueLabel } from "..";
import { useSubscription } from "../../../../../SubscriptionContext";
import { useCreditCount } from "../../../../../contexts/CreditCountContext";
import DeleteRed from "../../../../Icons/DeleteRed";
import PeopleProfileCard from "../../../../atoms/PeopleProfileCard";
import TypeDiv from "../../../../atoms/TypeDiv";
import NoContentCard from "../../../../molecules/NoContentCard";
import BlogFilters from "../../../../organisms/blogFilters/SaveListFilters";
import Shimmer from "../Shimmer/shimmer";
import "../saved_list.scss";

const SavedCompanies = ({
  removeIcon,
  data,
  handleDelete,
  isSearched,
  filter,
  onFilterChange,
  selectedFilter,
  loadShimmer,
}: any) => {
  const navigate = useNavigate();
  const { updateCreditInfo } = useCreditCount();
   const userInfo = useSelector((state : any) => state.peopleData?.peopleData);

  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  const handleView = (item : any) => {
    if (userInfo?.walkin_trial) {
      updateCreditInfo({ type: "company", id: item});
    } else {
      navigate(`/dashboard/company/${item}`);
    }
  }

  return (
    <div className="saved-data-container" key={data}>
      <>
        {loadShimmer ? (
          <Shimmer />
        ) : (
          <>
            <div className="mt-3">
              <BlogFilters
                data={convertToValueLabel(data?.distinct_groups)}
                onFilterSelect={(option: any) => onFilterChange(option)}
                showDeleteIcon
                type="Company"
                alreadySelected={selectedFilter}
              />
            </div>


            {data?.data?.length > 0 ? (
              <>
                {data?.data?.map((item: any) => (
                  <div className="saved-item-container" key={item.saved_list}>
                    <div
                      className="saved-item cursor-pointer"
                      onClick={(e) =>
                        isSubscriptionExpired
                          ? handleUserClick(e)
                          : handleView(item.company_id) 
                      }
                    >
                      <PeopleProfileCard
                        type="company"
                        profile={item?.profile}
                        size="90px"
                        borderRadius="10%"
                      />

                      <div>
                        <div className="d-flex align-items-center gap-3 mb-2">
                          <h4 className="h4-i semi-light mb-0">{item?.name}</h4>
                          {(!selectedFilter || selectedFilter === "All") && (
                            <TypeDiv data={item?.group_name} color="#FFD88D" />
                          )}
                        </div>
                        <p className="p mb-1">
                          {" "}
                          <>
                            {item?.address?.state_region}
                            {item?.address?.state_region && ", "}{" "}
                            {item?.address?.country}
                          </>
                        </p>
                        <p className="p mb-1">{item?.role}</p>
                      </div>
                    </div>

                    <button
                      className={
                        !removeIcon
                          ? "btn btn-outline-danger Saved-remove-btn-web"
                          : "manage-icon"
                      }
                      onClick={(e) =>
                        isSubscriptionExpired
                          ? handleUserClick(e)
                          : handleDelete(item.saved_list)
                      }
                    >
                      {!removeIcon ? (
                        "Remove"
                      ) : (
                        <i className="d-flex">
                          <DeleteRed className="Saved-delete-icon-container" />
                        </i>
                      )}
                    </button>
                    {/* <button
            className={
              !removeIcon
                ? "btn btn-outline-danger Saved-remove-btn-phone"
                : "manage-icon"
            }
            onClick={() => handleDelete(item.saved_list)}
          >
            {!removeIcon ? "Remove" : <i className="fi fi-br-cross"></i>}
          </button> */}
                  </div>
                ))}
              </>
            ) : (
              <div className="non-saved">
                {isSearched ? (
                  <NoContentCard title="Sorry! No Result Found" type="Search" />
                ) : (
                  <NoContentCard title="No Company Saved Yet !" type="" />
                )}
              </div>
            )}
          </>
        )}
      </>
    </div>
  );
};

export default SavedCompanies;
