import styles from "./index.module.scss";

const ResetBtn = ({ onReset, type, className }) => {
  return (
    <div
      className={`${
        type === "small" ? styles.reset_small_btn : styles.reset_btn
      } ${className}`}
      onClick={onReset}
      type='button'
    >
      <label>Reset Filters</label>
    </div>
  );
};

export default ResetBtn;
