const Calender = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      className={className}
    >
      <path
        d="M6.79347 11.5898L6.5173 13.2301C6.4988 13.34 6.51126 13.4528 6.55329 13.556C6.59532 13.6592 6.66526 13.7486 6.75526 13.8142C6.8454 13.8797 6.95203 13.9187 7.06315 13.9268C7.17426 13.935 7.28544 13.912 7.38418 13.8604L8.85892 13.0905L10.3337 13.8605C10.4194 13.9051 10.5145 13.9284 10.6111 13.9286C10.735 13.9286 10.8586 13.8898 10.9629 13.8144C11.053 13.7488 11.123 13.6594 11.165 13.5562C11.2071 13.453 11.2195 13.3402 11.201 13.2303L10.9251 11.59L12.1125 10.4252C12.192 10.3471 12.2482 10.2485 12.2748 10.1403C12.3014 10.0321 12.2974 9.91869 12.2631 9.81269C12.2286 9.70672 12.1653 9.61247 12.0801 9.54054C11.995 9.46862 11.8915 9.42188 11.7813 9.40557L10.1358 9.16156L9.39467 7.67197C9.34514 7.57219 9.26873 7.48824 9.17404 7.42955C9.07936 7.37087 8.97017 7.3398 8.85877 7.33984C8.74737 7.33996 8.63821 7.3711 8.54351 7.42976C8.44881 7.48842 8.37231 7.57229 8.32258 7.67197L7.58218 9.16156L5.9367 9.40543C5.82648 9.4217 5.72299 9.46844 5.63791 9.54037C5.55283 9.6123 5.48954 9.70657 5.45516 9.81255C5.42067 9.9185 5.41646 10.032 5.44301 10.1402C5.46956 10.2484 5.52582 10.3471 5.60543 10.425L6.79347 11.5898Z"
        fill="#6F767E"
      />
      <path
        d="M15.9308 1.91358H14.0466V0.86846C14.0466 0.7544 14.0242 0.641457 13.9805 0.536082C13.9369 0.430706 13.8729 0.334961 13.7922 0.254315C13.7116 0.173669 13.6158 0.109702 13.5104 0.0660662C13.405 0.0224305 13.2921 -1.89286e-05 13.178 1.19751e-08H13.0403C12.9263 1.04022e-08 12.8134 0.0224644 12.708 0.0661104C12.6026 0.109756 12.5069 0.173729 12.4263 0.254375C12.3456 0.335021 12.2817 0.43076 12.2381 0.536126C12.1944 0.641491 12.172 0.754419 12.172 0.86846V1.91358H5.52414V0.86846C5.52414 0.638155 5.43266 0.417281 5.26982 0.254417C5.10699 0.0915534 4.88613 3.82559e-05 4.65582 1.19751e-08H4.51796C4.40391 1.04022e-08 4.29099 0.0224644 4.18563 0.0661104C4.08027 0.109756 3.98454 0.173729 3.90391 0.254375C3.82328 0.335021 3.75932 0.43076 3.71569 0.536126C3.67207 0.641491 3.64962 0.754419 3.64964 0.86846V1.91358H1.7874C0.801977 1.91358 0 2.71527 0 3.70098V16.2126C0 17.1977 0.801977 18 1.7874 18H15.931C16.9162 18 17.7183 17.1979 17.7183 16.2126V3.70098C17.7182 2.71527 16.9161 1.91358 15.9308 1.91358ZM15.6976 15.9794H2.02059V6.23988H15.6976V15.9794Z"
        fill="#6F767E"
      />
    </svg>
  );
};

export default Calender;
