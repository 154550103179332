import { useEffect, useState } from "react";
import { formatToDateTime } from "../../../../../helpers/formatDateTime";
import CommunityImageViewer from "../../../../atoms/CommunityImageViewer";
import Ellipseicon from "../../../../Icons/Ellipseicon";
import NoContentCard from "../../../../molecules/NoContentCard";
import Pagination from "../../../../molecules/Pagination";
import CommunityFilters from "../../../../organisms/communityFilters";
import Shimmer from "../../Community/Shimmer";
import "./styles.scss";

const Renderdetails = ({
  activeTab,
  data,
  itemsPerPage,
  isLoading,
  sendInvitationResponse,
  getCommunityAndPostRequests,
  currentPage,onPageChange, onItemsPerPageChange
}) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeAction, setActiveAction] = useState(null);
  const [categories, setCategories] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [sentFilterData, setSentFilterData] = useState([
    // { label: `All (0)`, value: "All" },
  ]);

  const totalItems = data?.total_record; // Calculate the number of items in the object

  const action = {
    Post: "post",
    Community: "join Community",
  };

  const actionValue = {
    Article: "an Article",
    Location: "a Roadshow",
    Event: "an Event",
  };

  const filtereddata = [
    { label: `All (${0})`, value: "All" },
    {
      label: `Event (${0})`,
      value: "Event",
    },
    {
      label: `Article (${0})`,
      value: "Article",
    },
    { label: `Text (${0})`, value: "Text" },
    { label: `Image (${0})`, value: "Image" },
    {
      label: `RoadShow (${0})`,
      value: "RoadShow",
    },
  ];

  useEffect(() => {
    if (categories?.length > 0) {
      getCommunityAndPostRequests(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (data?.filter_counts) {
      const totalRecords = data?.total_record || 0;
      if (totalRecords > 0) {
        setSentFilterData(
          filtereddata
            .map((item) => {
              const count =
                item.value === "All"
                  ? data?.total_record || 0
                  : data?.filter_counts[item.value.toLowerCase()] || 0;

              return {
                ...item,
                label: `${item.value} (${count})`,
                count,
              };
            })
            .filter((item) => item.value === "All" || item.count > 0)
        );
      } else {
        setSentFilterData([]);
      }
    }
  }, [data]);

  const handleFilterSelect = (option) => {
    if (option === "All") {
      // Reset categories, show all data
      setCategories(null);
    } else {
      setCategories([option]);
    }
  };
  const handleButtonClick = (index, action, item) => {
    setActiveIndex(index);
    setActiveAction(action);
    // setLoading(true);
    const status = action === "Accept" ? true : false;
    // Trigger API call
    sendInvitationResponse(item?.type, item?.id, item?.user_id, status);
  };

  return (
    <>
      <div
        style={{
          marginBottom: "16px",
        }}
      >
        <CommunityFilters
          data={sentFilterData}
          onFilterSelect={handleFilterSelect}
        />
      </div>

      <div className="container-list">
        {isLoading ? (
          <>
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
          </>
        ) : totalItems > 0 && Array.isArray(data?.data) ? (
          data?.data.map((item, index) => (
            <div
              key={index}
              className={`singlecard-container ${
                activeTab === "Received" && index === 0 ? "filter-tab" : ""
              }`}
            >
              <div className="left-content">
                <CommunityImageViewer
                  type={item.post_type}
                  imageUrl={item.community_logo}
                  viewType="community"
                  borderType="curved"
                />
                {item?.post_type === 'Invite' ? <div className="member-details">
                  <div className="memeber-container">
                  <span className="member-name">You</span>
                    <span className="item-text">
                      have been invited to {action?.[item?.type]}{" "}
                      {actionValue?.[item?.post_type]}{" "}
                      <strong>{item?.community_name}</strong> by   <strong>{item?.invited_by}</strong> 
                    </span>
                  </div>
                  <div className="member-community-details">
                    <span className="community-detail">{item.post_type}</span>
                    {item.date && (
                      <>
                        <Ellipseicon />
                        <span className="community-detail">{item.date}</span>
                      </>
                    )}
                  </div>
                  <span className="post-date">
                    {formatToDateTime(item.created_date)}
                  </span>
                </div> : <div className="member-details">
                  <div className="memeber-container">
                    <span className="member-name">{item.user_name}</span>
                    <span className="item-text">
                      requested to {action?.[item?.type]}{" "}
                      {actionValue?.[item?.post_type]}{" "}
                      <strong>{item?.community_name}</strong>
                    </span>
                  </div>
                  <div className="member-community-details">
                    <span className="community-detail">{item.type}</span>
                    {item.date && (
                      <>
                        <Ellipseicon />
                        <span className="community-detail">{item.date}</span>
                      </>
                    )}
                  </div>
                  <span className="post-date">
                    {formatToDateTime(item.created_date)}
                  </span>
                </div>}
                
              </div>
              {item?.status === "Reject" ? (
                <button
                  className={`action-btn-disabled me-3  ${
                    activeIndex === index && activeAction === "Rejected"
                      ? "active"
                      : ""
                  }`}
                  disabled={true}
                >
                  Rejected
                </button>
              ) : (
                <div className="action-btn-container me-3">
                  <button
                    className={`action-btn-primary ${
                      activeIndex === index && activeAction === "Accept"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleButtonClick(index, "Accept", item)}
                    // disabled={loading}
                  >
                    Accept
                    {/* {loading && activeIndex === index ? "Accepting..." : "Accept"} */}
                  </button>
                  <button
                    className={`action-btn-secondary ${
                      activeIndex === index && activeAction === "Decline"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleButtonClick(index, "Decline", item)}
                    // disabled={loading}
                  >
                    Decline
                    {/* {loading && activeIndex === index ? "Declining..." : "Decline"} */}
                  </button>
                </div>
              )}
            </div>
          ))
        ) : (
          <NoContentCard title="No Data Found" />
        )}

        {totalItems > itemsPerPage && (
          <div className="mt-3">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={currentPage}
              onPageChange={onPageChange}
              onItemsPerPageChange={onItemsPerPageChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Renderdetails;
