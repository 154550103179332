import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { formatToDateTime } from "../../../../../helpers/formatDateTime";
import CommunityImageViewer from "../../../../atoms/CommunityImageViewer";
import NoContentCard from "../../../../molecules/NoContentCard";
import Pagination from "../../../../molecules/Pagination";
import CommunityFilters from "../../../../organisms/communityFilters";
import Shimmer from "../../Community/Shimmer";
import "./styles.scss";

const Sentdetails = ({
  activeTab,
  data,
  isLoading,
  itemsPerPage,
  withDrawInvitation,
  getAllPostSentReports,
  currentPage,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const [cookies] = useCookies(["t", "cuid"]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeAction, setActiveAction] = useState(null);
  const [categories, setCategories] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [sentFilterData, setSentFilterData] = useState([
    // { label: `All (0)`, value: "All" },
  ]);
  const totalItems = data?.total_record; // Calculate the number of items in the object

  const filtereddata = [
    { label: `All (${0})`, value: "All" },
    {
      label: `Event (${0})`,
      value: "Event",
    },
    {
      label: `Article (${0})`,
      value: "Article",
    },
    { label: `Text (${0})`, value: "Text" },
    { label: `Image (${0})`, value: "Image" },
    {
      label: `RoadShow (${0})`,
      value: "RoadShow",
    },
  ];

  useEffect(() => {
    if (categories?.length > 0) {
      getAllPostSentReports(categories);
    }
  }, [categories]);

  useEffect(() => {
    if (data?.filter_counts) {
      const totalRecords = data?.total_record || 0;
      if (totalRecords > 0) {
        setSentFilterData(
          filtereddata
            .map((item) => {
              const count =
                item.value === "All"
                  ? data?.total_record || 0
                  : data?.filter_counts[item.value.toLowerCase()] || 0;

              return {
                ...item,
                label: `${item.value} (${count})`,
                count,
              };
            })
            .filter((item) => item.value === "All" || item.count > 0)
        );
      } else {
        setSentFilterData([]);
      }
    }
  }, [data]);

  const handleFilterSelect = (option) => {
    if (option === "All") {
      // Reset categories, show all data
      setCategories(null);
    } else {
      setCategories([option]);
    }
  };

  const handleButtonClick = (key, action, item) => {
    setActiveIndex(key);
    setActiveAction(action);
    // setLoading(true);
    withDrawInvitation(item.id, item.type, item?.user_id);
  };

  return (
    <>
      <div style={{ marginBottom: "16px" }}>
        <CommunityFilters
          data={sentFilterData}
          onFilterSelect={handleFilterSelect}
        />
      </div>
      <div className="container-list">
        {isLoading ? (
          <>
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
          </>
        ) : totalItems > 0 ? (
          data?.data?.map((item, index) => {
            return (
              <div
                key={index}
                className={`singlecard-container ${
                  activeTab === "Sent" && index === 0 ? "filter-tab" : ""
                }`}
              >
                <div className="left-content">
                  <CommunityImageViewer
                    type={item.post_type}
                    imageUrl={item.community_logo}
                    viewType="community"
                    borderType="curved"
                  />

                  {item?.is_invited ? (
                    <div className="member-details">
                      <div className="memeber-container">
                        <span className="item-text">
                          You've invited{" "}
                        </span>
                          <span className="member-name">{item.user_name}</span>{" "}
                          <span className="item-text">
                          to join your community{" "}
                        </span>
                          <span className="member-name">
                            {item.community_name} .
                          </span>
                 
                        {/* <span className="item-text">{item.content}</span> */}
                      </div>

                      <span className="post-date mt-0">
                        {" "}
                        {formatToDateTime(item.created_date)}
                      </span>
                    </div>
                  ) : (
                    <div className="member-details">
                      <div className="memeber-container">
                        <span className="item-text">
                          Requested to join community
                        </span>
                        <span className="member-name">
                          {item.community_name}
                        </span>
                        {/* <span className="item-text">{item.content}</span> */}
                      </div>

                      <span className="post-date mt-0">
                        {" "}
                        {formatToDateTime(item.created_date)}
                      </span>
                    </div>
                  )}
                </div>

                <div className="action-btn-container">
                  <button
                    className={`action-btn-secondary me-3 ${
                      activeIndex === index && activeAction === "Withdraw"
                        ? "active"
                        : ""
                    }`}
                    onClick={() => handleButtonClick(index, "Withdraw", item)}
                    // disabled={loading}
                  >
                    Withdraw
                    {/* {loading && activeIndex === index
                      ? "Processing..."
                      : "Withdraw"} */}
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <NoContentCard title="No Data Found" />
        )}

        {totalItems > itemsPerPage && (
          <div className="mt-3">
            <Pagination
              itemsPerPage={itemsPerPage}
              totalItems={totalItems}
              currentPage={currentPage}
              onPageChange={onPageChange}
              onItemsPerPageChange={onItemsPerPageChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Sentdetails;
