const CommunityNotification = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="22"
      viewBox="0 0 17 22"
      fill="none"
    >
      <path
        d="M12.2498 6.62531H4.74999C4.58423 6.62531 4.42526 6.55946 4.30805 6.44225C4.19085 6.32505 4.125 6.16608 4.125 6.00032V4.75035C4.12555 4.25325 4.32326 3.77666 4.67477 3.42515C5.02627 3.07365 5.50286 2.87593 5.99996 2.87539H6.6262C6.63614 2.40949 6.81519 1.96311 7.12996 1.61948C7.44473 1.27585 7.87373 1.05842 8.33697 1.00774C8.61096 0.982575 8.88712 1.01895 9.14525 1.1142C9.40338 1.20945 9.63697 1.36118 9.82894 1.55829C10.0036 1.73003 10.1419 1.93513 10.2357 2.16139C10.3295 2.38766 10.3768 2.63047 10.3749 2.87539H10.9999C11.497 2.87593 11.9735 3.07365 12.325 3.42515C12.6766 3.77666 12.8743 4.25325 12.8748 4.75035V6.00032C12.8748 6.16608 12.809 6.32505 12.6918 6.44225C12.5746 6.55946 12.4156 6.62531 12.2498 6.62531ZM5.37497 5.37534H11.6248V4.75035C11.6247 4.58464 11.5588 4.42576 11.4416 4.30858C11.3244 4.19141 11.1656 4.12551 10.9999 4.12536H9.74988C9.58412 4.12536 9.42515 4.05952 9.30795 3.94231C9.19074 3.8251 9.12489 3.66613 9.12489 3.50038V2.87539C9.12572 2.79476 9.11022 2.71481 9.07932 2.64033C9.04843 2.56585 9.00279 2.4984 8.94515 2.44202C8.88041 2.3755 8.80182 2.32406 8.71495 2.29136C8.62807 2.25866 8.53506 2.24551 8.44253 2.25284C8.2798 2.27941 8.13236 2.36444 8.02786 2.49198C7.92335 2.61951 7.86898 2.7808 7.87492 2.94557V3.50038C7.87492 3.66613 7.80907 3.8251 7.69186 3.94231C7.57466 4.05952 7.41569 4.12536 7.24993 4.12536H5.99996C5.83425 4.12551 5.67537 4.19141 5.55819 4.30858C5.44102 4.42576 5.37512 4.58464 5.37497 4.75035V5.37534Z"
        fill="#6F767E"
        stroke="#6F767E"
        stroke-width="0.4"
      />
      <path
        d="M14.1247 20.9996H2.87496C2.37786 20.9991 1.90127 20.8014 1.54977 20.4499C1.19826 20.0984 1.00055 19.6218 1 19.1247V5.99996C1.00055 5.50286 1.19826 5.02627 1.54977 4.67477C1.90127 4.32326 2.37786 4.12555 2.87496 4.125H4.74992C4.91568 4.125 5.07464 4.19085 5.19185 4.30805C5.30906 4.42526 5.37491 4.58423 5.37491 4.74999C5.37491 4.91574 5.30906 5.07471 5.19185 5.19192C5.07464 5.30913 4.91568 5.37497 4.74992 5.37497H2.87496C2.70925 5.37512 2.55037 5.44102 2.43319 5.55819C2.31602 5.67537 2.25012 5.83425 2.24997 5.99996V19.1247C2.25012 19.2904 2.31602 19.4493 2.43319 19.5664C2.55037 19.6836 2.70925 19.7495 2.87496 19.7497H14.1247C14.2904 19.7495 14.4493 19.6836 14.5665 19.5664C14.6837 19.4493 14.7496 19.2904 14.7497 19.1247V5.99996C14.7496 5.83425 14.6837 5.67537 14.5665 5.55819C14.4493 5.44102 14.2904 5.37512 14.1247 5.37497H12.2498C12.084 5.37497 11.925 5.30913 11.8078 5.19192C11.6906 5.07471 11.6248 4.91574 11.6248 4.74999C11.6248 4.58423 11.6906 4.42526 11.8078 4.30805C11.925 4.19085 12.084 4.125 12.2498 4.125H14.1247C14.6218 4.12555 15.0984 4.32326 15.4499 4.67477C15.8014 5.02627 15.9991 5.50286 15.9997 5.99996V19.1247C15.9991 19.6218 15.8014 20.0984 15.4499 20.4499C15.0984 20.8014 14.6218 20.9991 14.1247 20.9996Z"
        fill="#6F767E"
        stroke="#6F767E"
        stroke-width="0.4"
      />
      <path
        d="M11.8746 9.74997H4.87474C4.70899 9.74997 4.55002 9.68413 4.43281 9.56692C4.3156 9.44971 4.24976 9.29074 4.24976 9.12499C4.24976 8.95923 4.3156 8.80026 4.43281 8.68305C4.55002 8.56585 4.70899 8.5 4.87474 8.5H11.8746C12.0404 8.5 12.1994 8.56585 12.3166 8.68305C12.4338 8.80026 12.4996 8.95923 12.4996 9.12499C12.4996 9.29074 12.4338 9.44971 12.3166 9.56692C12.1994 9.68413 12.0404 9.74997 11.8746 9.74997Z"
        fill="#6F767E"
        stroke="#6F767E"
        stroke-width="0.4"
      />
      <path
        d="M11.8746 13.5H4.87474C4.70899 13.5 4.55002 13.4341 4.43281 13.3169C4.3156 13.1997 4.24976 13.0407 4.24976 12.875C4.24976 12.7092 4.3156 12.5503 4.43281 12.4331C4.55002 12.3158 4.70899 12.25 4.87474 12.25H11.8746C12.0404 12.25 12.1994 12.3158 12.3166 12.4331C12.4338 12.5503 12.4996 12.7092 12.4996 12.875C12.4996 13.0407 12.4338 13.1997 12.3166 13.3169C12.1994 13.4341 12.0404 13.5 11.8746 13.5Z"
        fill="#6F767E"
        stroke="#6F767E"
        stroke-width="0.4"
      />
      <path
        d="M11.8746 17.25H4.87474C4.70899 17.25 4.55002 17.1841 4.43281 17.0669C4.3156 16.9497 4.24976 16.7907 4.24976 16.625C4.24976 16.4592 4.3156 16.3003 4.43281 16.1831C4.55002 16.0658 4.70899 16 4.87474 16H11.8746C12.0404 16 12.1994 16.0658 12.3166 16.1831C12.4338 16.3003 12.4996 16.4592 12.4996 16.625C12.4996 16.7907 12.4338 16.9497 12.3166 17.0669C12.1994 17.1841 12.0404 17.25 11.8746 17.25Z"
        fill="#6F767E"
        stroke="#6F767E"
        stroke-width="0.4"
      />
    </svg>
  );
};

export default CommunityNotification;
