import React from "react";
import "./tabStrip.scss";

interface TabItem {
  id: number;
  label: string;
}

interface TabStripProps {
  activeTab: string;
  onTabChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    value: string
  ) => void;
  data: TabItem[];
  allowedTabId?: number; // Corrected: Make allowedTabId optional
  areTabsDisabled?: boolean; // Corrected: Make areTabsDisabled optional
  type?: string;
  styleType?: string;
  children?: any;
}

const TabStrip: React.FC<TabStripProps> = ({
  activeTab,
  onTabChange,
  data,
  allowedTabId,
  areTabsDisabled,
  type,
  styleType = "normal",
  children,
}) => {
  return (
    <div
      className={styleType === "normal" ? "" : "tabstrip-children"}
      id="tabstrip"
    >
      <div
        style={{
          borderBottom: "1px solid #CED5DE",

          overflowX: "auto",
          whiteSpace: "nowrap",
          width: styleType === "normal" ? "100%" : "max-content",
        }}
        id="tabstrip-first"
      >
        {data?.map((item) => {
          const isTabDisabled =
            areTabsDisabled &&
            allowedTabId !== undefined &&
            item.id !== allowedTabId;

          return (
            <>
              {type === "small" ? (
                <button
                  key={item.id}
                  onClick={(e) => onTabChange(e, item.label)}
                  className={activeTab === item.label ? "selected" : ""}
                  style={{
                    fontWeight: activeTab === item.label ? "500" : "400",
                    color: activeTab === item.label ? "#000" : "black",
                    textTransform: "capitalize",
                    paddingTop: "30px",
                    border: "none",
                    borderBottom:
                      activeTab === item.label
                        ? "2px solid #3D9BE9"
                        : "2px solid transparent",
                    paddingLeft: "10px",
                    background: "transparent",
                    padding: "10px 20px",
                    display: "inline-block",
                    fontSize: "14px",
                  }}
                  disabled={isTabDisabled}
                >
                  {item.label}
                </button>
              ) : (
                <button
                  key={item.id}
                  onClick={(e) => onTabChange(e, item.label)}
                  className={activeTab === item.label ? "selected" : ""}
                  style={{
                    fontWeight: activeTab === item.label ? "500" : "400",
                    color: activeTab === item.label ? "#000" : "black",
                    textTransform: "capitalize",
                    paddingTop: "30px",
                    border: "none",
                    borderBottom:
                      activeTab === item.label
                        ? "2px solid #3D9BE9"
                        : "2px solid transparent",
                    paddingLeft: "10px",
                    background: "transparent",
                    padding: "10px 20px",
                    display: "inline-block",
                  }}
                  disabled={isTabDisabled}
                >
                  {item.label}
                </button>
              )}
            </>
          );
        })}
      </div>

      <div>{children}</div>
    </div>
  );
};

export default TabStrip;
