import React, { useRef, useState } from "react";
import useScreenWidth from "../../../hooks/useScreenwidth";
import SearchIcon from "../../Icons/Search";
import "./search.scss";

interface SearchProps {
  onSearchEmit: (value: string) => void;
  placeholder?: string;
  className?: string;
  variant?: "search" | "search_lg" | "search_md" | "search_sm";
  style?: React.CSSProperties;
  phonestyle?: React.CSSProperties;
  bgStyle?: string;
  description?: string;
}

const Search: React.FC<SearchProps> = ({
  onSearchEmit,
  placeholder = "Search",
  className = "",
  variant = "search",
  style,
  phonestyle,
  description,
  bgStyle = "",
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const screenWidth = useScreenWidth();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    // Clear any previously set timeout
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    // Set a new timeout to emit the search value
    typingTimeoutRef.current = setTimeout(() => {
      if (newValue.length >= 3 || newValue === "") {
        onSearchEmit(newValue);
      }
    }, 300);
  };

  const combinedClassName = `${variant} ${bgStyle} ${className}`.trim();

  return (
    <div className={combinedClassName} style={screenWidth < 500 ? phonestyle : style}>
      <div className="input-group" style={{ width: "100%" }} title={description || placeholder}>
        <div className="input-group-prepend">
          <span
            className={`input-group-text ${isFocused ? "search-prepend-focused" : ""}`}
            id="basic-addon1"
          >
            <i className="d-flex ms-2 mt-1 mb-1">
              <SearchIcon />
            </i>
          </span>
        </div>
        <input
          type="text"
          className={`form-control ${isFocused ? "search-focused" : ""}`}
          placeholder={placeholder}
          aria-label="Search"
          aria-describedby="basic-addon1"
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default Search;
