import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCommunityNotificationContext } from "../../../../contexts/CommunityNotificationContext"; // Import the context
import { timeAgo } from "../../../../helpers/timeAgo";
import PeopleProfileCard from "../../../atoms/PeopleProfileCard";
import CommunityNotificationIcon from "../../../Icons/CommunityNotification";
import style from "../Notification/styles.module.scss";

const CommunityNotification = () => {
  const navigate = useNavigate();
  const {
    notificationData,
    notificationCount,
    showRed,
    loading,
    setShowRed,
    setNotificationData,
    markAsRead,
  } = useCommunityNotificationContext(); // Access notification context

  const [showDropdown, setShowDropdown] = useState(false);

  const handleIconClick = () => {
    setShowDropdown((prev) => !prev);
    if (!showDropdown) {
      setShowRed(false); // Mark as viewed
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const notificationIcon = document.getElementById(
        "communityNotificationIcon"
      );
      const dropdown = document.getElementById("communityNotificationDropdown");

      if (
        !notificationIcon.contains(event.target) &&
        dropdown &&
        !dropdown.contains(event.target)
      ) {
        setShowDropdown(false);
        markAsRead(); // Mark notifications as read when dropdown closes
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown, notificationData]); // Add notificationData to dependency array to trigger update when needed

  const handleDropdownItemClick = (notification) => {
    console.log('notification',notification)
    // if (notification?.type === "Blog") {
    //   navigate(`/dashboard/my-blogs`);
    // } else if (notification?.type) {
    //   navigate(`/dashboard/requests/${notification?.type}`);
    // } else {
    //   navigate(`/dashboard/requests`);


    // }

    const types = {
      'Post' : 'received',
      'Sent' : 'sent',
      "Report" : 'reported'
    }
    navigate(`/dashboard/community/requests/${types[notification.type]}`);
    setShowDropdown(false);
    setShowRed(false);
  };

  return (
    <div className={style.notification}>
      <i
        className="cursor-pointer grey-i"
        id="communityNotificationIcon"
        onClick={handleIconClick}
      >
        <CommunityNotificationIcon />
      </i>

      {showRed && <div className={style.communityNotificationCount}></div>}

      {showDropdown && (
        <div className={style.dropdown} id="communityNotificationDropdown">
          {loading ? (
            <div className={style.shimmerContainer}>
              <div className={style.shimmer}></div>
              <div className={style.shimmer}></div>
              <div className={style.shimmer}></div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between p-3 align-items-center py-1">
                <div className="h3-i semi-light">
                  {notificationCount > 0 && (
                    <>
                      {notificationCount}{" "}
                      {notificationCount > 1
                        ? "New Notifications"
                        : "New Notification"}
                    </>
                  )}
                </div>

                {notificationData.length > 0 && (
                  <div
                    className="view-more cursor-pointer"
                    onClick={() => {
                      navigate("/dashboard/community/requests");
                      setShowDropdown(false);
                    }}
                  >
                    View All
                  </div>
                )}
              </div>

              {notificationData.length > 0 ? (
                notificationData.map((notification, index) => (
                  <div
                    key={index}
                    className={`${style.dropdownItem} ${
                      notification?.notification_flag
                        ? style.unreadNotification
                        : ""
                    }`}
                    onClick={() => handleDropdownItemClick(notification)}
                  >
                    <div className="d-flex gap-3">
                      <PeopleProfileCard
                        profile={notification?.profile_photo}
                        name={notification?.user_name}
                        size="48px"
                        borderRadius="50%"
                        fontSize="16px"
                        color="#ffffff"
                      />
                      <div className="d-flex flex-column">
                        {notification?.type === "Community" ? (
                          <>
                            <p className={style.notification_p}>
                              <span className="semi-light">
                                {notification?.user_name}
                              </span>{" "}
                              requested to join community{" "}
                              <span className="semi-light">
                                {notification?.name}
                              </span>
                            </p>
                          </>
                        ) : notification?.type === "Post" ? (
                          <>
                            <p className={style.notification_p}>
                              <span className="semi-light">
                                {notification.user_name}
                              </span>{" "}
                              requested to Post .
                              {/* <span className="semi-light">
                                {notification?.post_type === 'Location' :  }
                              </span> */}
                            </p>
                          </>
                        ) : (
                          <>
                            <p className={style.notification_p}>
                              <span className="semi-light">
                                {notification?.user_name}
                              </span>{" "}
                              reported{" "}
                              {notification?.post_type === "Post" ? (
                                "a post"
                              ) : (
                                <>
                                  community{" "}
                                  <span className="semi-light">
                                    {notification?.name}
                                  </span>
                                </>
                              )}
                            </p>
                          </>
                        )}

                        <p className="p mb-xs">{timeAgo(notification?.date)}</p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="h3-i semi-light mb-1">
                    No New Notifications
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CommunityNotification;
