import axios from "../axios"; // Adjust the path accordingly
import communityAxios from "../communityAxios";

interface PostDataParams {
  endpoint: string;
  data: any; // Adjust the type according to your needs
  params?: any; // Adjust the type according to your needs
  token?: string;
  type?: string;
}

export const postData = async ({
  endpoint,
  data,
  params,
  token,
  type = "normal",
}: PostDataParams): Promise<{ data: any; isLoading: boolean }> => {
  try {
    // Set isLoading to true before the API call starts
    const isLoading = true;

    let response;

    if (type === "normal") {
      response = await axios.post(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } else {
      response = await communityAxios.post(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    }

    // Set isLoading to false when the API call is complete
    const result = { data: response.data, isLoading: false };
    return result;
  } catch (error) {
    console.error("Error posting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};

export const postFormData = async ({
  endpoint,
  data,
  params,
  token,
  type = "normal",
}: PostDataParams): Promise<{ data: any; isLoading: boolean }> => {
  try {
    // Set isLoading to true before the API call starts
    const isLoading = true;
    let response;

    if (type === "normal") {
      response = await axios.post(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      response = await communityAxios.post(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    }

    // Set isLoading to false when the API call is complete
    const result = { data: response.data, isLoading: false };
    return result;
  } catch (error) {
    console.error("Error posting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};

interface DeleteDataParams {
  endpoint: string;
  params?: any; // Adjust the type according to your needs
  token?: string;
  type?: "normal";
}

export const deleteData = async ({
  endpoint,
  params,
  token,
  type = "normal",
}: DeleteDataParams): Promise<{ data: any; isLoading: boolean }> => {
  try {
    // Set isLoading to true before the API call starts
    const isLoading = true;
    let response;

    if (type === "normal") {
      response = await axios.delete(endpoint, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // Set isLoading to false when the API call is complete
      const result = { data: response.data, isLoading: false };
      return result;
    } else {
      response = await communityAxios.delete(endpoint, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      // Set isLoading to false when the API call is complete
      const result = { data: response.data, isLoading: false };
      return result;
    }
  } catch (error) {
    console.error("Error deleting data:", error);
    // Set isLoading to false in case of an error
    const errorResult = { error, isLoading: false };
    throw errorResult;
  }
};

// -------------------------------------------------------------------------------------

interface PatchDataParams extends PostDataParams {}

export const patchData = async ({
  endpoint,
  data,
  params,
  token,
  type = "normal",
}: PatchDataParams): Promise<any> => {
  try {
    let response;

    if (type === "normal") {
      response = await axios.patch(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } else {
      response = await communityAxios.patch(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    }

    return response.data;
  } catch (error) {
    console.error("Error patching data:", error);
    throw error;
  }
};

export const patchFormData = async ({
  endpoint,
  data,
  params,
  token,
  type = "normal",
}: PatchDataParams): Promise<any> => {
  try {
    let response;

    if (type === "normal") {
      response = await axios.patch(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    } else {
      response = await communityAxios.patch(endpoint, data, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      });
    }

    return response.data;
  } catch (error) {
    console.error("Error patching data:", error);
    throw error;
  }
};

interface GetDataParams {
  endpoint: string;
  params?: any; // Adjust the type according to your needs
  token?: string;
  type?: string;
}

export const getData = async ({
  endpoint,
  params,
  token,
  type = "normal",
}: GetDataParams): Promise<any> => {
  try {
    let response;
    if (type === "normal") {
      response = await axios.get(endpoint, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    } else {
      response = await communityAxios.get(endpoint, {
        params,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
    }

    return response.data;
  } catch (error) {
    console.error("Error getting data:", error);
    throw error;
  }
};
