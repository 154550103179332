import { useEffect, useRef, useState } from "react";
import useScreenWidth from "../../../../../../../hooks/useScreenwidth";
import FilterIcon from "../../../../../../Icons/FilterIcon";
import CustomButton from "../../../../../../atoms/CustomeButton";
import CloseBtnIcom from "../../../../../../molecules/Modal/CloseBtnIcon";
import { groupByLabel } from "../../../../Products/NewProductDatabase/NewFilter/Funds";
import FundsFunction from "./FundsFunction";
import "./style.scss";

const Preferances = ({
  type,
  reset,
  id,
  className,
  headingMobile,
  dateRangeNames = ["Date range"],
  onFilterChange,
  filterData: propsFilterData,
  isDateRange = false,
  headingtext,
  handleTrackRecord,
  handleTicketSize,
  tabresponsive,
  handleAum,
  selectedFilter,
  selectedTrackRecord = {},
  selectedTicketSize = {},
}) => {
  const { productType, geography, sector } = FundsFunction({
    type: "product",
  });

  useEffect(() => {
    if (selectedFilter) {
      setSelectedOptionsMap(selectedFilter);
    }
    if (selectedTicketSize) {
      setTicketSize(selectedTicketSize);
    }
    if (selectedTrackRecord) {
      setTrackRecord(selectedTrackRecord);
    }
  }, [selectedFilter, selectedTrackRecord, selectedTicketSize]);

  const containerRef = useRef(null);
  const dropdownRef = useRef(null);
  const [updatedFilterData, setUpdatedFilterData] = useState({});
  const [trackRecord, setTrackRecord] = useState({ min: null, max: null });
  const [ticketSize, setTicketSize] = useState({ min: null, max: null });
  const [errors, setErrors] = useState({ min: null, max: null });
  const screenWidth = useScreenWidth();

  const updated = dateRangeNames?.reduce((acc, name, index) => {
    acc[name] = [`startDate${index}`, `endDate${index}`];
    return acc;
  }, {});

  useEffect(() => {
    // if (reset) {
    handleReset();
    // }
  }, [reset]);

  useEffect(() => {
    const groupedProductType = groupByLabel(productType);

    const filterDataFunds = {
      "Product Type": groupedProductType || [],
      Geography: geography || [],
      Sector: sector || [],
      "Pre-Launch": [
        { value: "true", label: "Yes" },
        { value: "false", label: "No" },
      ],
    };

    const filterDataField = isDateRange
      ? { ...filterDataFunds, ...updated }
      : filterDataFunds;

    setUpdatedFilterData(filterDataField);
  }, [productType, geography, sector]);

  const [isOpen, setIsOpen] = useState(false);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [selectedKey, setSelectedKey] = useState();
  const [selectedOptionsMap, setSelectedOptionsMap] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const keys = Object.keys(updatedFilterData);
    setSelectedKey(keys[0]);
  }, []);

  const toggleFilterBox = () => {
    const keys = Object.keys(updatedFilterData);
    setIsOpen(!isOpen);
    setSelectedKey(keys[0]);
    setSearchQuery("");
  };

  const handleKeyClick = (key) => {
    setSelectedKey(key);
    setSearchQuery("");
  };

  // useEffect(() => {
  //   if (
  //     selectedKey === "Product Type" &&
  //     selectedOptionsMap?.["Product Type"]?.length > 0
  //   ) {
  //     const typeValues =
  //       selectedOptionsMap?.["Product Type"]?.map((type) => type.value) || [];
  //     fetchStrategyByMultipleTypeId(typeValues);
  //   }
  // }, [selectedOptionsMap?.["Product Type"]]);

  // Function to recursively delete dependent keys
  const deleteDependentKeys = (updatedOptionsMap, deletedKey) => {
    for (const key in disableConditions) {
      const condition = disableConditions[key];
      if (typeof condition === "function" && condition(updatedOptionsMap)) {
        if (updatedOptionsMap[key]) {
          delete updatedOptionsMap[key];
          deleteDependentKeys(updatedOptionsMap, key); // Recursively delete dependent keys
        }
      }
    }
  };

  const handleOptionChange = (selectedValue) => {
    setSelectedOptionsMap((prevOptionsMap) => {
      const updatedOptionsMap = { ...prevOptionsMap };

      // Ensure the key exists in the map
      if (!updatedOptionsMap[selectedKey]) {
        updatedOptionsMap[selectedKey] = [];
      }

      // Function to handle toggling of a single selected value
      const toggleOption = (value) => {
        const optionExists = updatedOptionsMap[selectedKey].some(
          (option) => option.value === value
        );

        if (optionExists) {
          updatedOptionsMap[selectedKey] = updatedOptionsMap[
            selectedKey
          ].filter((option) => option.value !== value);
        } else {
          // Find the selected option in updatedFilterData
          let selectedOption = updatedFilterData[selectedKey]?.find(
            (option) => option.value === value || option.value.includes(value)
          );

          if (selectedOption) {
            const dataToAdd = selectedOption.data || selectedOption;

            if (Array.isArray(dataToAdd)) {
              updatedOptionsMap[selectedKey] = [
                ...updatedOptionsMap[selectedKey],
                ...dataToAdd.filter((item) => item.value === value),
              ];
            } else {
              updatedOptionsMap[selectedKey] = [
                ...updatedOptionsMap[selectedKey],
                dataToAdd,
              ];
            }
          } else {
            console.warn(`No option found for value: ${value}`);
          }
        }
      };

      // If selectedValue is an array (e.g., "Select All" clicked)
      if (Array.isArray(selectedValue)) {
        const allSelected = selectedValue.every((value) =>
          updatedOptionsMap[selectedKey].some(
            (option) => option.value === value
          )
        );

        if (allSelected) {
          // Deselect all if all items are already selected
          updatedOptionsMap[selectedKey] = updatedOptionsMap[
            selectedKey
          ].filter((option) => !selectedValue.includes(option.value));
        } else {
          // Otherwise, add all items
          selectedValue.forEach((value) => {
            const selectedOption = updatedFilterData[selectedKey]?.find(
              (option) => option.value === value || option.value.includes(value)
            );

            if (selectedOption) {
              const dataToAdd = selectedOption.data || selectedOption;
              if (Array.isArray(dataToAdd)) {
                updatedOptionsMap[selectedKey] = [
                  ...updatedOptionsMap[selectedKey],
                  ...dataToAdd.filter((item) => item.value === value),
                ];
              } else {
                updatedOptionsMap[selectedKey] = [
                  ...updatedOptionsMap[selectedKey],
                  dataToAdd,
                ];
              }
            }
          });
        }
      } else {
        // Proceed with single value toggle logic
        toggleOption(selectedValue);
      }

      // Remove the key if its array is empty
      if (updatedOptionsMap[selectedKey].length === 0) {
        delete updatedOptionsMap[selectedKey];
        deleteDependentKeys(updatedOptionsMap, selectedKey);
      }

      return updatedOptionsMap;
    });
  };

  const handleApply = () => {
    const filterValues = {
      ...selectedOptionsMap,
      ...dateRange,
    };

    onFilterChange(filterValues);
    setIsOpen(false);
  };

  const handleReset = () => {
    setSelectedOptionsMap({});
    setDateRange({ startDate: null, endDate: null });
    setIsOpen(false);
    onFilterChange({});
    handleAum({ min: null, max: null }, "Track Record");
    handleAum({ min: null, max: null }, "Average Ticket Size");
  };

  const disableConditions = {
    "Product Type": [], // Define disabling conditions for Product Type
    Geography: [], // Define disabling conditions for Geography
    Sector: [], // Define disabling conditions for Sector
    "Pre-Launch": [],
    "Product Strategy": (selectedOptionsMap) =>
      !selectedOptionsMap["Product Type"],
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const excludedClassesRegex = /^Mui.*/; // Regular expression to match classes starting with "Mui"

      const isExcluded = Array.from(event.target.classList).some((className) =>
        excludedClassesRegex.test(className)
      );

      if (
        containerRef.current &&
        !containerRef.current.contains(event.target) &&
        !isExcluded &&
        isOpen
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, isOpen]);

  const handleChecked = (value) => {
    const selectedOptions = selectedOptionsMap[selectedKey] || [];

    if (Array.isArray(value)) {
      // If value is an array, check if all items in the array are found in the selected options
      const allItemsFound = value.every((val) =>
        selectedOptions.some((selectedOption) => selectedOption.value === val)
      );
      return allItemsFound;
    } else {
      // If value is not an array, just check if it exists in the selected options
      const isFound = selectedOptions.some(
        (selectedOption) => selectedOption.value === value
      );
      return isFound;
    }
  };

  useEffect(() => {
    if (
      containerRef &&
      containerRef?.current &&
      dropdownRef &&
      dropdownRef.current &&
      screenWidth < 900 &&
      tabresponsive
    ) {
      dropdownRef.current.style.visibility = "hidden";
      // console.log(dropdownRef?.current?.style.visibility="hidden");
      const positions = containerRef?.current?.getBoundingClientRect();
      const dropPosition = dropdownRef?.current?.getBoundingClientRect();
      const totalWidth = (dropPosition.width * 100) / 80;
      const width_20_percent = (totalWidth * 20) / 100;
      dropdownRef.current.style.left = `-${
        positions.x + (screenWidth < 500 && width_20_percent)
      }px`;
      dropdownRef.current.style.right = `auto`;
      dropdownRef.current.style.visibility = "visible";
    }
  }, [isOpen]);

  const handleBlur = (e, key) => {
    const { name, value } = e.target;

    if (key === "Track Record")
      setTrackRecord((prevAum) => ({ ...prevAum, [name]: value }));
    else setTicketSize((prev) => ({ ...prev, [name]: value }));
    // validate(name, value);
  };

  const handleConfirm = (type) => {
    if (trackRecord) {
      handleAum({ ...trackRecord }, "Track Record");
    }

    if (ticketSize) {
      handleAum({ ...ticketSize }, "Ticket Size");
    }
  };

  const hasSelectedFilters =
    Object.keys(selectedOptionsMap)?.length > 0 ||
    (isDateRange &&
      (trackRecord.min || trackRecord.max || ticketSize.min || ticketSize.max));

  const isActive =
    (Array.isArray(selectedOptionsMap) && selectedOptionsMap.length > 0) ||
    (selectedOptionsMap && Object.keys(selectedOptionsMap)?.length > 0) ||
    (isDateRange &&
      (trackRecord.min ||
        trackRecord.max ||
        ticketSize.min ||
        ticketSize.max)) ||
    isOpen;

  {
    console.log("Object.values(dateRange)", Object.values(dateRange));
  }

  const buttonClasses = `${
    headingMobile
      ? hasSelectedFilters
        ? "multi-dot-indicator filter-mobile-container-btn"
        : "filter-mobile-container-btn"
      : hasSelectedFilters
      ? "multi-dot-indicator filterbtn"
      : "filterbtn"
  } ${isActive ? "active-filter" : "test"}`;

  return (
    <>
      <div
        className={`${className} ${
          headingMobile
            ? "filter-container filter-mobile-container"
            : "filter-container"
        }`}
        ref={containerRef}
        id={id}
      >
        <CustomButton
          handleClick={toggleFilterBox}
          icon={<FilterIcon />}
          text={headingMobile ? "" : headingtext}
          classes={buttonClasses}
          style={{ height: "40px" }}
        />
  
        {isOpen && (
          <div className={`filter-box`} ref={dropdownRef}>
            <div className="filter-header">
              <p className="header">Filters</p>

              <span
                className="header"
                style={{ cursor: "pointer" }}
                onClick={toggleFilterBox}
              >
                <i className="d-flex">
                  <CloseBtnIcom />
                </i>
              </span>
            </div>
            <div className="filter-data-box">
              <div className="filter-key-box">
                {Object.keys(updatedFilterData).map((key) => (
                  <div
                    key={key}
                    onClick={() => {
                      // Check if the key should be disabled based on the disableConditions
                      if (
                        disableConditions[key] &&
                        typeof disableConditions[key] === "function"
                      ) {
                        if (disableConditions[key](selectedOptionsMap)) {
                          // If the condition is met, return early without changing the selected key
                          return;
                        }
                      }
                      // If the key is not disabled, handle the click event as usual
                      handleKeyClick(key);
                    }}
                    className={`filter-key selected ${
                      selectedKey === key ? "active-key" : ""
                    } ${
                      // Apply disabled class if the key is disabled
                      disableConditions[key] &&
                      typeof disableConditions[key] === "function" &&
                      disableConditions[key](selectedOptionsMap) &&
                      "disabled-key"
                    }`}
                  >
                    {key}

                    {selectedOptionsMap[key] ||
                    (isDateRange &&
                      (dateRange[`startDate${dateRangeNames.indexOf(key)}`] ||
                        dateRange[`endDate${dateRangeNames.indexOf(key)}`] ||
                        (key === "Track Record" &&
                          (trackRecord.min || trackRecord.max)) ||
                        (key === "Average Ticket Size ($M)" &&
                          (ticketSize.min || ticketSize.max)))) ? (
                      <div className="dot-blue"></div>
                    ) : (
                      ""
                    )}
                  </div>
                ))}
              </div>

              <div className="filters-column">
                {dateRangeNames?.includes(selectedKey) ? (
                  <div>
                    <form className="formConatiner">
                      <div className="formInput">
                        <label htmlFor="min">Min {selectedKey}</label>
                        <input
                          type="number"
                          placeholder="Enter Min"
                          name="min"
                          onBlur={(e) => handleBlur(e, selectedKey)}
                          onChange={(e) => {
                            if (selectedKey === "Track Record") {
                              setTrackRecord((prev) => ({
                                ...prev,
                                min: e.target.value,
                              }));
                            } else {
                              setTicketSize((prev) => ({
                                ...prev,
                                min: e.target.value,
                              }));
                            }
                          }}
                          value={
                            selectedKey === "Track Record"
                              ? trackRecord.min
                              : ticketSize.min
                          }
                        />
                      </div>

                      <div className="formInput mt-2">
                        <label htmlFor="max">Max {selectedKey}</label>
                        <input
                          type="number"
                          name="max"
                          placeholder="Enter Max"
                          onBlur={(e) => handleBlur(e, selectedKey)}
                          onChange={(e) => {
                            if (selectedKey === "Track Record") {
                              setTrackRecord((prev) => ({
                                ...prev,
                                max: e.target.value,
                              }));
                            } else {
                              setTicketSize((prev) => ({
                                ...prev,
                                max: e.target.value,
                              }));
                            }
                          }}
                          value={
                            selectedKey === "Track Record"
                              ? trackRecord.max
                              : ticketSize.max
                          }
                        />
                      </div>
                    </form>
                  </div>
                ) : (
                  <>
                    <input
                      key={selectedKey}
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      style={{
                        height: "35px",
                        marginBottom: "10px",
                        width: "100%",
                      }}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />

                    <div className="filters-options">
                      {(() => {
                        if (selectedKey === "Product Type") {
                          // Group options by category_name
                          const groupedOptions = updatedFilterData[
                            selectedKey
                          ].reduce((acc, option) => {
                            const categoryName =
                              option.category_name || "Uncategorized";
                            if (!acc[categoryName]) {
                              acc[categoryName] = [];
                            }
                            acc[categoryName].push(option);
                            return acc;
                          }, {});

                          // Filter and organize options within each category
                          const filteredGroupedOptions = Object.entries(
                            groupedOptions
                          ).reduce((acc, [categoryName, options]) => {
                            const selectedOptions = options.filter(
                              (option) =>
                                selectedOptionsMap[selectedKey]?.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                ) &&
                                option.label
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                            );

                            const unselectedOptions = options.filter(
                              (option) =>
                                !selectedOptionsMap[selectedKey]?.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                ) &&
                                option.label
                                  .toLowerCase()
                                  .includes(searchQuery.toLowerCase())
                            );

                            const filteredOptions = [
                              ...selectedOptions,
                              ...unselectedOptions,
                            ];

                            if (filteredOptions.length > 0) {
                              acc[categoryName] = filteredOptions;
                            }

                            return acc;
                          }, {});

                          // Check if no options are available
                          if (
                            Object.keys(filteredGroupedOptions).length === 0
                          ) {
                            return (
                              <div className="no-data-found">
                                {searchQuery
                                  ? ""
                                  : selectedKey === "Product Strategy"
                                  ? "No Data Found for the Selected Product Type"
                                  : ""}
                              </div>
                            );
                          }

                          // Render grouped options
                          return Object.entries(filteredGroupedOptions).map(
                            ([categoryName, options]) => (
                              <div
                                key={categoryName}
                                className="category-group"
                              >
                                <h6 className="filter-group-header">
                                  {categoryName}
                                </h6>
                                {options.map((option) => (
                                  <div key={option.value} className="mb-1">
                                    <label
                                      className="check-container"
                                      htmlFor={option.value}
                                    >
                                      {option.label}
                                      <input
                                        type="checkbox"
                                        id={option.value}
                                        checked={handleChecked(option.value)}
                                        onChange={() =>
                                          handleOptionChange(option.value)
                                        }
                                      />
                                      <span className="checkmark"></span>
                                    </label>
                                  </div>
                                ))}
                              </div>
                            )
                          );
                        } else {
                          // Original UI for other selected keys
                          const selectedOptions = updatedFilterData[selectedKey]
                            .filter((option) =>
                              selectedOptionsMap[selectedKey]?.some(
                                (selectedOption) =>
                                  selectedOption.value === option.value
                              )
                            )
                            .filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            );

                          const unselectedOptions = updatedFilterData[
                            selectedKey
                          ]
                            .filter(
                              (option) =>
                                !selectedOptionsMap[selectedKey]?.some(
                                  (selectedOption) =>
                                    selectedOption.value === option.value
                                )
                            )
                            .filter((option) =>
                              option.label
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase())
                            );

                          const filteredOptions = [
                            ...selectedOptions,
                            ...unselectedOptions,
                          ];

                          if (filteredOptions.length === 0) {
                            return (
                              <div className="no-data-found">
                                {searchQuery ? "" : "No Data Found"}
                              </div>
                            );
                          }

                          return filteredOptions.map((option) => (
                            <div key={option.value} className="mb-1">
                              <label
                                className="check-container"
                                htmlFor={option.value}
                              >
                                {option.label}
                                <input
                                  type="checkbox"
                                  id={option.value}
                                  checked={handleChecked(option.value)}
                                  onChange={() =>
                                    handleOptionChange(option.value)
                                  }
                                />
                                <span className="checkmark"></span>
                              </label>
                            </div>
                          ));
                        }
                      })()}
                    </div>
                  </>
                )}
              </div>
            </div>
            <hr className="my-0" />
            <div className="d-flex justify-content-end gap-3 p-3">
              <button
                className="btn btn-outline-dark mt-0"
                onClick={() => {
                  handleReset();
                }}
              >
                Reset
              </button>
              <button
                className="btn btn-primary mt-0"
                disabled={!errors}
                onClick={() => {
                  handleConfirm();
                  handleApply();
                }}
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default Preferances;
