import { useCallback, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { USERTYPE } from "../../../../helpers/constantData";
import { getData } from "../../../../services";
import CommunityFilters from "../../../atoms/Community/communityFilters";
import NormalButton from "../../../atoms/Community/communityFilters/NormalButton";
import CustomMenu from "../../../atoms/CustomMenu";
import NotFound from "../../../Icons/NotFound";
import SortIcon from "../../../Icons/Sort";
import CommunityPosts from "./CommunityPosts";
import Shimmer from "./PostView/Shimmer";
import SimilarCommunities from "./SimilarCommunities";
import SimilarPeopleProfiles from "./SimilarPeopleProfiles";
import "./styles.scss";

const newFilterData = [
  { label: `Posts`, value: "Post" },
  { label: `Articles`, value: "Article" },
  { label: `Events`, value: "Event" },
  { label: `Roadshows`, value: "Location" },
];

const CommunityTimeline = () => {
  const [cookies] = useCookies(["t", "cuid"]);
  const communityRef = useRef();
  const [page, setPage] = useState(1); // Track current page
  const [selectedPostsType, setSelectedPostsType] = useState(null); // for posts type filter
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const [posts, setPosts] = useState();
  const [selectedSortType, setSelectedSortType] = useState(null);
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [similarData, setSimilarData] = useState([]);
  const [communities, setCommunities] = useState([]);
  const decodedString = cookies?.ut ? atob(cookies?.ut) : null;

  const [isLoading, setIsLoading] = useState(false);

  const sortMenu = [
    {
      id: "latest_added",
      label: "Latest First",
      onClick: () => {
        setSortAnchorEl(false);
        setSelectedSortType("null");
      },
    },
    {
      id: "oldest_first",
      label: "Oldest First",
      onClick: () => {
        setSortAnchorEl(false);
        setSelectedSortType("sort_by_oldest");
      },
    },
    {
      id: "most_likes",
      label: "Most Liked",
      onClick: () => {
        setSortAnchorEl(false);
        setSelectedSortType("sort_by_likes");
      },
    },
  ];

  const fetchUpdatedPosts = useCallback(async () => {
    setIsLoading(true);
    const postData = await getData({
      endpoint: "Post/getAllTimelinePost",
      params: {
        user_id: cookies.cuid,
        page: 1,
        page_limit: 5,
        sort: selectedSortType,
        types: selectedPostsType
          ? selectedPostsType === "All"
            ? null
            : JSON.stringify([selectedPostsType])
          : null,
      },
      token: cookies.t,
      type: "community",
    });

    setPosts(postData);
    setIsLoading(false);
  }, [selectedPostsType, selectedSortType]);

  const fetchNextPage = async () => {
    try {
      const nextPage = page + 1; // Increment the page number
      const postData = await getData({
        endpoint: "Post/getAllTimelinePost",
        params: {
          user_id: cookies.cuid,
          page: nextPage,
          page_limit: 5,
          sort: selectedSortType,
          types: selectedPostsType
            ? selectedPostsType === "All"
              ? null
              : JSON.stringify([selectedPostsType])
            : null,
        },
        token: cookies.t,
        type: "community",
      });

      if (postData?.data?.length > 0) {
        setPosts((prevPosts) => ({
          ...prevPosts,
          data: [...prevPosts.data, ...postData.data], // Append new posts
        }));
        setPage(nextPage); // Update the current page
      }
    } catch {
    } finally {
      return false;
    }
  };

  const fetchSimilarProfiles = async () => {
    try {
      const res = await getData({
        endpoint: "People/community/getPeopleSimilarProfile",
        params: {
          people_id: cookies.cuid,
        },
        token: cookies.t,
      });

      if (res) {
        setSimilarData(res?.data);
      }
    } catch {}
  };

  const fetchCommunities = async () => {
    try {
      const res = await getData({
        endpoint: "Community/getSimilarCommunities",
        params: {
          user_id: cookies.cuid,
          page : 1,
          page_limit : 6
        },
        token: cookies.t,
        type: "community",
      });

      console.log('res',res)

      if (res) {
        setCommunities(res?.data);
      }
    } catch {}
  };

  useEffect(() => {
    if (decodedString === USERTYPE) {
      fetchCommunities();
    } else {
      fetchSimilarProfiles();
    }
  }, []);

  useEffect(() => {
    // if (selectedPostsType || selectedSortType) {
    fetchUpdatedPosts();
    // }
  }, [selectedPostsType, fetchUpdatedPosts, selectedSortType]);

  return (
    <div id="Community-Page" ref={communityRef}>
      <div className="community-main-content">
        <div>

          <div className="d-flex justify-content-between mt-2">
            <CommunityFilters
              data={newFilterData}
              onFilterSelect={(option) => setSelectedPostsType(option)}
              alreadySelected={selectedPostsType}
            />

            <NormalButton
              label={"Sort"}
              icon={<SortIcon />}
              onClick={(e) => setSortAnchorEl(e.currentTarget)}
            />

            <CustomMenu
              items={sortMenu}
              anchorEl={sortAnchorEl}
              open={Boolean(sortAnchorEl)}
              onClose={() => setSortAnchorEl(null)}
            />
          </div>
        </div>

        {selectedPostsType && !isLoading && posts?.data?.length === 0 && (
          <div className="community-card d-flex align-items-center justify-content-center flex-column p-5">
            <div className="not-found-icon mt-1 mb-3">
              <NotFound />
            </div>

            <div className="h3-j">No results found</div>

            <p className="p-dark mt-2">Try applying another filter search</p>

            <button
              className="btn btn-outline-dark btn-sm"
              onClick={() => setSelectedPostsType("All")}
            >
              Clear Filter
            </button>
          </div>
        )}

        {isLoading ? (
          <Shimmer />
        ) : (
          <CommunityPosts
            // communityData={communityData}
            posts={posts}
            // fetchUpdatedPosts={fetchUpdatedPosts}
            // peopleDetails={peopleDetails}
            fetchNextPage={fetchNextPage}
          />
        )}
      </div>

      <div className="community-right">
        <div className="community-right">
          <div className="community-card p-3">
            <div className="box">
              <p className="h4-j fw-semi mb-0">
                {decodedString === USERTYPE
                  ? "   Communities you might be interested in "
                  : "   Profiles you might be interested in"}
              </p>
            </div>

            <div className="mt-3">
              {decodedString === USERTYPE ? <SimilarCommunities similarData={communities}/> :   <SimilarPeopleProfiles similarData={similarData} />}
            
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunityTimeline;
