import React from "react";
import { useCookies } from "react-cookie";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPeopleData } from "../../../../store/slices/peopleSlice";
import { useSubscription } from "../../../../SubscriptionContext";
import CloseBtnIcom from "../CloseBtnIcon";
import "./logout-modal.scss";

interface CloseButtonProps {
  onClose: () => void;
}

const CloseButton: React.FC<CloseButtonProps> = ({ onClose }) => {
  return (
    <div onClick={onClose}>
      <i className="d-flex" style={{ fontSize: "18px", cursor: "pointer" }}>
        <CloseBtnIcom />
      </i>
    </div>
  );
};

interface ModalProps {
  width?: string;
  onClose: () => void;
  isActive: boolean;
}

const Modal: React.FC<ModalProps> = ({
  width = "400px",
  onClose,
  isActive,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setTop } = useSubscription();
  const [, , removeCookies] = useCookies([
    "t",
    "un",
    "email",
    "cuid",
    "username",
    "token",
    "ut",
    "cid",
    "role",
  ]);
  const cookieNames = [
    "t",
    "un",
    "email",
    "cuid",
    "cid",
    "role",
    "username",
    "token",
    "ut",
  ];

  const cookiesNameDomain = ["token", "username" , "ut" , "un" , "cuid"];

  const handleLogout = () => {
    cookieNames.forEach((cookieName: any) => {
      removeCookies(cookieName, {
        path: "/",
      });
    });
    cookiesNameDomain.forEach((cookieName: any) => {
      removeCookies(cookieName, {
        path: "/",
        domain: process.env.REACT_APP_DOMAIN,
      });
    });

    localStorage.removeItem("emailrequest");
    localStorage.removeItem("trial_info");

    setTop(0);

    dispatch(setPeopleData({}));

    navigate("/", { replace: true });
  };
  const styles: React.CSSProperties = {
    width: width,
  };

  return (
    <>
      <div className={`modal ${isActive ? "active" : ""}`}>
        <div className="modal-content" style={styles}>
          <div className="modal-body py-4">
            <div className="d-flex align-items-center justify-content-between">
              <p className="modal-heading mb-0 mt-2">Logout</p>

              <CloseButton onClose={onClose} />
            </div>

            <p className="modal-description mt-2">
              Are you sure do you want to Logout.
            </p>

            <div className="d-flex w-100 mt-3">
              <button
                className="btn btn-outline-dark"
                onClick={onClose}
                style={{ flex: "1 0 45%" }}
              >
                No
              </button>
              <button
                className="btn btn-primary mt-0"
                onClick={handleLogout}
                style={{ flex: "1 0 45%", marginLeft: "10px" }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
