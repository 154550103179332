const Community = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="20"
      height="20"
    >
      <path d="m5.5,7c1.93,0,3.5-1.57,3.5-3.5S7.43,0,5.5,0,2,1.57,2,3.5s1.57,3.5,3.5,3.5Zm0-5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm-.5,8c-1.654,0-3,1.346-3,3,0,.553-.448,1-1,1S0,13.553,0,13C0,10.243,2.243,8,5,8c.552,0,1,.447,1,1s-.448,1-1,1Zm7,7c0,.553-.448,1-1,1h-1c-1.654,0-3,1.346-3,3,0,.553-.448,1-1,1s-1-.447-1-1c0-2.757,2.243-5,5-5h1c.552,0,1,.447,1,1Zm3.5-10c1.93,0,3.5-1.57,3.5-3.5s-1.57-3.5-3.5-3.5-3.5,1.57-3.5,3.5,1.57,3.5,3.5,3.5Zm0-5c.827,0,1.5.673,1.5,1.5s-.673,1.5-1.5,1.5-1.5-.673-1.5-1.5.673-1.5,1.5-1.5Zm.5,6c1.779,0,3.439.958,4.331,2.499.276.479.113,1.09-.365,1.366-.157.092-.33.135-.5.135-.345,0-.681-.179-.867-.499-.535-.926-1.531-1.501-2.599-1.501-.552,0-1-.447-1-1s.448-1,1-1Zm8,11c0,.553-.448,1-1,1h-3v3c0,.553-.448,1-1,1s-1-.447-1-1v-3h-3c-.552,0-1-.447-1-1s.448-1,1-1h3v-3c0-.553.448-1,1-1s1,.447,1,1v3h3c.552,0,1,.447,1,1Zm-13.5-11c-1.93,0-3.5,1.57-3.5,3.5s1.57,3.5,3.5,3.5,3.5-1.57,3.5-3.5-1.57-3.5-3.5-3.5Zm0,5c-.827,0-1.5-.673-1.5-1.5s.673-1.5,1.5-1.5,1.5.673,1.5,1.5-.673,1.5-1.5,1.5Z" />
    </svg>
  );
};

export default Community;
