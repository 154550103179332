const Like = ({ id, className = "" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      id={id}
      className={className}
    >
      <path
        d="M13.0906 10.6905H12.2906V9.89047V6.50237C12.2906 6.02951 12.1311 5.64306 11.7884 5.30035C11.5719 5.08384 11.3379 4.94045 11.0758 4.8642L7.99829 11.755V19.6128C7.99829 19.741 8.03371 19.8186 8.11756 19.8983C8.21193 19.9879 8.30834 20.0281 8.45042 20.0281H16.4419C16.9081 20.0281 17.2992 19.8936 17.6459 19.625C17.9906 19.3578 18.21 19.019 18.3155 18.5791L18.3178 18.5699L18.3202 18.5607L19.7556 13.1503C19.7557 13.1498 19.7559 13.1493 19.756 13.1489C19.8609 12.7456 19.839 12.3652 19.6914 11.9833C19.5386 11.5877 19.2985 11.2792 18.9615 11.0398L19.3201 10.5351L18.9615 11.0398C18.6347 10.8075 18.2698 10.6905 17.8413 10.6905H13.0906ZM4.02602 19.8021C4.18956 19.9656 4.35079 20.0281 4.54674 20.0281H4.85155V12.3845H4.54674C4.34028 12.3845 4.17548 12.4489 4.01437 12.6035C3.8612 12.7506 3.8 12.8996 3.8 13.0944V19.2814C3.8 19.4773 3.86249 19.6386 4.02602 19.8021Z"
        stroke="#5E5E5E"
        stroke-width="1.6"
      />
    </svg>
  );
};

export default Like;
