import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";
import InputLayout from "../../../../atoms/InputLayout";
import PageCard from "../../../../atoms/PageCard";
import TypeDiv from "../../../../atoms/TypeDiv";
import RequestAcceessForm from "../../../../organisms/forms/RequestAcceessForm";

import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { useSubscription } from "../../../../../SubscriptionContext";
import { formatNames } from "../../../../../helpers/formatNames";
import useScreenWidth from "../../../../../hooks/useScreenwidth";
import EditBlue from "../../../../Icons/EditBlue";
import Lock from "../../../../Icons/Lock";
import View from "../../../../Icons/View";
import CustomButton from "../../../../atoms/CustomeButton";
import IconCircle from "../../../../atoms/IconCircle";
import "./component.scss";

const CompanyProduct = ({ data }: any) => {
  const { id } = useParams();
  const [cookies] = useCookies(["cuid", "cid"]);
  const navigate = useNavigate();
  const userInfo = useSelector((state: any) => state.peopleData?.peopleData);
  const screenWidth = useScreenWidth();
  const { isSubscriptionExpired, handleUserClick } = useSubscription();

  return (
    <>
      <h3 className="h3-j mb-3">
        {/* {data?.product?.length}{" "} */}
        {data?.product?.length <= 1 ? "Product" : "Products"}
      </h3>
      {data?.product?.length > 0 &&
        data?.product?.map((item: any) => (
          <PageCard
            customStyles={{ backgroundColor: "#F9F9F9" }}
            classes="p-4 mb-4"
          >
            <div>
              <div className="d-flex align-items-start justify-content-between">
                <div>
                  <TypeDiv
                    data={formatNames(item?.category)}
                    classes="mb-2"
                    color="#B1E5FC"
                  />
                  <h3 className="h2-j mb-3 cursor-pointer">{item?.name}</h3>
                </div>

                <div className="d-flex gap-3">
                  {!userInfo?.has_master_access && id && !item.allow_access ? (
                    <></>
                  ) : (
                    <div
                      onClick={() =>
                        navigate(`/dashboard/product/${item.product_id}`)
                      }
                      className="cursor-pointer"
                    >
                      {/* <IconCircle
                        icon={
                          <i className="d-flex" style={{ width: "20px" }}>
                            <View
                              width="16"
                              height="15"
                              className="black-fill"
                            />
                          </i>
                        }
                        color="transparent"
                        iconColor="#ffffff"
                        border="1px solid #1A1D1F"
                        iconSize="16px"
                        size={32}
                      /> */}
                      <CustomButton
                        icon={
                          <View
                            className="Lock-white-container"
                            width="17"
                            height="17"
                          />
                        }
                        classes="btn-xs"
                        type="btn-primary"
                        text={screenWidth > 480 ? "View" : ""}
                        forMobile={screenWidth < 480 ? true : false}
                      />
                    </div>
                  )}

                  {cookies.cid !== item.company_id ? (
                    <></>
                  ) : (
                    <>
                      {userInfo.is_admin ? (
                        <div
                          onClick={(e) => {
                            // isSubscriptionExpired
                            //   ? handleUserClick(e)
                            //   :
                            navigate(
                              `/dashboard/product/edit/${item.product_id}`
                            );
                          }}
                          className="cursor-pointer"
                        >
                          <CustomButton
                            icon={
                              <EditBlue
                                className="black-fill"
                                width="16"
                                height="15"
                              />
                            }
                            classes="btn-xs"
                            text={screenWidth > 480 ? "Edit" : ""}
                            forMobile={screenWidth < 480 ? true : false}
                          />
                        </div>
                      ) : null}
                    </>
                  )}
                </div>

                {!userInfo?.has_master_access &&
                  id &&
                  cookies.cid !== item.company_id &&
                  !item.allow_access && (
                    <IconCircle
                      color="#1A1D1F"
                      icon={
                        <i className="d-flex">
                          <Lock className="Lock-white-container" />
                        </i>
                      }
                      iconColor="#ffffff"
                      iconSize="16px"
                      size={32}
                    />
                  )}
              </div>

              <div
                onClick={() =>
                  navigate(`/dashboard/product/${item.product_id}`)
                }
                className={`${
                  !userInfo?.has_master_access && id && !item.allow_access
                    ? "no-pointer-events "
                    : "cursor-pointer"
                } company-product-detail-container`}
              >
                {item.structure?.length && (
                  <InputLayout>
                    <div className="p-dark-product-company">Structure</div>

                    <div className="p-light-small-product-company">
                      {formatNames(item.structure)}
                    </div>
                  </InputLayout>
                )}

                <InputLayout>
                  <div className="p-dark-product-company">Type</div>
                  <div className="p-light-small-product-company">
                    {formatNames(item.type)}
                  </div>
                </InputLayout>

                {item?.strategy?.length > 0 && (
                  <InputLayout>
                    <div className="p-dark-product-company">Strategy</div>
                    <div className="p-light-small-product-company">
                      {formatNames(item.strategy)}
                    </div>
                  </InputLayout>
                )}

                {item?.geography_name?.length > 0 && (
                  <InputLayout>
                    <div className="p-dark-product-company">Geography</div>

                    <p className="p-light-small-product-company">
                      {item?.geography_name
                        ? [item?.geography_name].join(", ")
                        : "--"}
                    </p>
                  </InputLayout>
                )}

                {item?.sector_name?.length > 0 && (
                  <InputLayout>
                    <div className="p-dark-product-company">Sector</div>
                    <p className="p-light-small-product-company">
                      {item?.sector_name ? [item?.sector_name].join(",") : "--"}
                    </p>
                  </InputLayout>
                )}

                {item?.inception && (
                  <InputLayout>
                    <div className="p-dark-product-company">Inception</div>
                    <div className="p-light-small-product-company">
                      {item?.inception
                        ? moment.utc(item?.inception).format("MMM YYYY")
                        : "--"}
                    </div>
                  </InputLayout>
                )}

                {item?.product_details?.target_close_date && (
                  <InputLayout>
                    <div className="p-dark-product-company">Target Closed</div>
                    <div className="p-light-small-product-company">
                      {item?.product_details?.target_close_date
                        ? moment
                            .utc(item?.product_details?.target_close_date)
                            .format("MMM YYYY")
                        : "--"}
                    </div>
                  </InputLayout>
                )}

                {item?.product_details?.funding_goal > 0 && (
                  <InputLayout>
                    <div className="p-dark-product-company">Funding Goal</div>

                    <div className="p-light-small-product-company">
                      {item?.product_details?.funding_goal
                        ? `$${item.product_details.funding_goal.toLocaleString(
                            "en-US"
                          )} `
                        : "--"}
                    </div>
                  </InputLayout>
                )}

                {/* <InputLayout>
                  <div className="p-dark-product-company">Funding Status</div>
                  <div
                    className={`p-light-small-product-company ${
                      item?.status === "Active"
                        ? "status-active"
                        : "status-inactive"
                    }`}
                  >
                    {" "}
                    {item?.status}
                  </div>
                </InputLayout> */}
                {!item?.not_applicable && item?.aum_amount && (
                  <InputLayout>
                    <div className="p-dark-product-company">
                      AUM (USD Millions)
                    </div>
                    <div className="p-light-small-product-company">
                      {item?.aum_amount && "$"}
                      {item?.aum_amount.toLocaleString("en-US")}
                      {item?.aum_month_year &&
                        `(as of ${
                          moment.utc(item.aum_month_year).isValid()
                            ? moment.utc(item.aum_month_year).format("MMM YYYY")
                            : "--"
                        })`}
                    </div>
                  </InputLayout>
                )}
              </div>
            </div>

            {item?.description && (
              <InputLayout>
                <div className="p-dark-product-company">Description</div>
                <div
                  className="p-light-small-product-company"
                  style={{ overflowWrap: "break-word" }}
                >
                  <div className="quill quill-change">
                    <div className="ql-container ql-snow ql-no-border">
                      <div
                        className="ql-editor blog-content extar-description-styles p-light-small-product-company"
                        style={{
                          paddingLeft: "0px",
                          display: "inline",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item?.description.substring(0, 250),
                        }}
                      ></div>
                      <div
                        style={{ display: "inline" }}
                        className="p-light-small-product-company"
                      >
                        {item?.description?.length > 251 ? "..." : " "}
                      </div>
                    </div>
                  </div>
                </div>
              </InputLayout>
            )}

            {!userInfo?.has_master_access &&
              id &&
              cookies.cid !== item.company_id &&
              !item.allow_access && (
                <div className="d-flex gap-3 mt-3">
                  {/* <RequestInfoForm
                  type="Request"
                  mapType="Product"
                  mapId={item?.product_id}
                /> */}

                  <RequestAcceessForm
                    product_id={item.product_id}
                    product_name={item?.name}
                    disabled={item?.product_requested}
                  />
                </div>
              )}
          </PageCard>
        ))}
    </>
  );
};

export default CompanyProduct;
