import React from 'react'
import './community_filter.scss';

const NormalButton = ({icon , onClick, label}) => {
  return (
    <div
    key={label}
    className={`filters-item d-flex justify-content-between gap-2 align-items-center`}
    onClick={onClick}
  >
    {icon}

    {label}
  </div>
  )
}

export default NormalButton