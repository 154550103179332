// Import necessary modules
import PropTypes from "prop-types";
import NoCompany from "../../../assets/images/NoCompany.png";
import Company from "../../../assets/images/NoContent.png";
import NotFoundImage from "../../../assets/images/NotFound.png";
import "./image_viewer.scss"; // Import the CSS file

const ImageViewer = ({ imageUrl, altText, type, viewType = "normal" }) => {
  const defaultImageUrl = type === "Company" ? NoCompany : NotFoundImage;

  const handleError = (e) => {
    e.target.src = type === "Company" ? NoCompany : defaultImageUrl;
  };

  return (
    <div
      className={
        viewType === "normal" ? "image-container" : "community-image-container"
      }
    >
      {imageUrl ? (
        <img
          src={imageUrl}
          alt={altText}
          className="image"
          onError={handleError}
        />
      ) : (
        <img
          src={type === "Company" ? NoCompany : NotFoundImage}
          alt={altText}
          className={`image no-image ${type !== Company ? "other-image" : ""}`}
          onError={handleError}
          style={{ objectFit: "cover" }}
        />
      )}
    </div>
  );
};

ImageViewer.propTypes = {
  imageUrl: PropTypes.string,
  altText: PropTypes.string,
  type: PropTypes.string,
};

export default ImageViewer;
