import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CreditNotificationCard from "../../../../atoms/CreditNotificationCard";
import Heading from "../../../../atoms/Heading";
import TabStrip from "../../../../molecules/TabStrip";
import Company from "./Tabs/Company";
import People from "./Tabs/People";
import RoadShow from "./Tabs/Roadshow";
import style from "./index.module.scss";

const NewProductDatabase = () => {
  const [activeTab, setActiveTab] = useState("Company");
  const location = useLocation()
  const navigate = useNavigate();
  const { query } = useParams();
  const userInfo = useSelector((state) => state.peopleData?.peopleData);

  useEffect(() => {
    if (query) {
      const updatedQuery = query.charAt(0).toUpperCase() + query.slice(1);
      setActiveTab(updatedQuery);
    }
  }, [query]);

  const dataArray = [
    {
      id: 0,
      label: "Company",
      view: <Company />,
    },
    {
      id: 1,
      label: "People",
      view: <People />,
    },
    {
      id: 2,
      label: "Roadshow",
      view: <RoadShow />,
    },
  ];

  const _getComponent = (tabs) => {
    return dataArray
      .filter((item) => item.label === tabs)
      .map((item) => item.view)[0];
  };

  const onTabChange = (event, value) => {
    setActiveTab(value);
    navigate(`/dashboard/products/productdatabase/${value}`);
  };

  return (
    <div className={style.Main_Investor_Info_Container} id="Fund-Directory">
      <div className={style.Product_DataBase_Container}>
        <Heading
          type="main"
          title="Fund Directory"
          classes={style.products_database_heading_container}
        >
          {userInfo?.walkin_trial && <CreditNotificationCard />}
        </Heading>
      </div>

      <div className="requests-tab-div">
        <TabStrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={dataArray}
        />
        <Box sx={{ width: "100%" }}>
          <Box>{_getComponent(activeTab)}</Box>
        </Box>
      </div>
    </div>
  );
};

export default NewProductDatabase;
