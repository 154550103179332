import NoContentImage from "../../../../assets/images/NoCompany.png";
import CustomButton from "../../../atoms/CustomeButton";
import "./styles.scss";

const SimilarCommunities = ({ similarData }) => {
  const handleView = (slug) => {
    window.open(`${process.env.REACT_APP_COMMUNITY_URL}community/${slug}`, "_blank");
  };
  return (
    <>
      {similarData?.map((item) => {
        return (
          <div className="card" style={{ backgroundColor: "transparent" }}>
            <div className="box">
              <div className="me-2">
     
                  <img
                    src={item?.logo ? item?.logo : NoContentImage}
                    alt="community-logo"
                    className="community-logo"
                  />
           
              </div>
              <div className="d-flex flex-column">
                <div className="team-people"></div>
                <div class="h6-j mb-0" style={{ overflowWrap: "anywhere" }}>
                  {item?.name}
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    <div className="p">{item?.job_title}</div>
                    {/* <div className="dot"></div> */}
                    {/* <div class="p">{item.company}</div> */}
                  </div>
                </div>
              </div>
            </div>

            <CustomButton
              // icon={<Direction />}
              handleClick={() => {
                handleView(item?.slug);
              }}
              classes="btn-outline-dark btn-sm"
              text="View "
            />
          </div>
        );
      })}
    </>
  );
};

export default SimilarCommunities;
