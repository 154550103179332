import { Formik, Form as FormikForm, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import useRecaptcha from "../../../../hooks/useRecaptcha";
import { getData, postData } from "../../../../services";
import { setLoading } from "../../../../store/slices/loaderSlice";
import Error from "../../../atoms/Error";
import InputField from "../../../atoms/InputField";
import styles from "../forms.module.scss";
import { commonLogicForLoginAndVerification } from "./commonLogic";

const VerificationForm = () => {
  const location = useLocation();
  const state = location.state || {};

  const [cookie, setCookie] = useCookies([
    "cuid",
    "role",
    "cid",
    "un",
    "t",
    "investor_access",
    "is_admin",
    "library_access",
    "resources_access",
    "product_access",
    "username",
    "token",
  ]);
  const dispatch = useDispatch();

  const [resendLoading, setResendLoading] = useState(false);

  const [refreshToken, setRefreshToken] = useState(false);
  const recaptchaToken = useRecaptcha("VERIFY", refreshToken);

  const storedCountdownStartTime = localStorage.getItem("countdownStartTime");
  const initialResendTimer = 30;

  const [resendTimer, setResendTimer] = useState<number>(initialResendTimer);
  const [showResend, setShowResend] = useState<boolean>(false);

  const navigate = useNavigate();

  const startResendTimer = (): void => {
    setResendTimer(30);
    setShowResend(false);
  };

  const handleResendCompleted = (): void => {
    startResendTimer();
  };

  const initialValues: FormikValues = {
    otp: "",
  };

  const validationSchema = yup.object().shape({
    otp: yup
      .string()
      .length(6, "OTP is 6 digits long")
      .required("OTP is required"),
  });

  const handleResendClick = async (): Promise<void> => {
    try {
      // Set resendLoading to true when starting the resend process
      setResendLoading(true);
      dispatch(setLoading(true));
      const { data: resultData, isLoading: resendLoading } = await postData({
        endpoint: "/CustomerAuth/resendOtp",
        data: {},
        params: { user_id: cookie.cuid },
      });

      if (resultData.status) {
        dispatch(setLoading(false));
        toast.success(resultData.message, {
          duration: 1000,
          style: confimationStyles,
        });
        handleResendCompleted();
      }
    } catch (error) {
      console.error(error);
    } finally {
      // Set resendLoading to false when the resend process is completed (success or failure)
      setResendLoading(false);
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer((prevResendTimer) => prevResendTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [resendTimer]);

  useEffect(() => {
    localStorage.setItem("countdownStartTime", resendTimer.toString());
  }, [resendTimer]);

  const formatTimer = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleSubmit = async (values: FormikValues): Promise<void> => {
    dispatch(setLoading(true));

    const recaptchaResponse = await getData({
      endpoint: "Wrapper/verifyRecaptcha",
      params: { token: recaptchaToken, expected_action: "VERIFY" },
    });

    if (recaptchaResponse?.riskScore > 0.5) {
      try {
        // Assuming postData returns a promise
        const dataToSend = {
          ...values,
          user_id: cookie.cuid,
        };
        const { data: resultData, isLoading } = await postData({
          endpoint: "/CustomerAuth/verifyOtp",
          data: {},
          params: dataToSend,
        });

        if (resultData.status) {
          dispatch(setLoading(false));
          toast.success(resultData.message, {
            duration: 1000,
            style: confimationStyles,
          });

          await commonLogicForLoginAndVerification(
            resultData?.data,
            cookie,
            setCookie,
            state.skipHubspotValidation
          );

          if (localStorage.getItem("emailrequest") === "email") {
            navigate("/dashboard/requests");
          } else if (localStorage.getItem("emailrequest") === "emailsent") {
            navigate("/dashboard/requests/Sent");
          }  else if (localStorage.getItem("emailrequest") === "emailCommunityRequest"){
            navigate("/dashboard/community/requests");
          }
          else if (localStorage.getItem("redirectUrl")) {
            const redirectUrl = localStorage.getItem("redirectUrl");
          
            if (redirectUrl) {
              localStorage.removeItem("redirectUrl"); // Clear stored redirect URL after use
              window.location.href = redirectUrl; // Redirect to external URL
            }
          }          
          else {
            navigate("/dashboard");
          }

          handleResendCompleted();
        } else {
          setRefreshToken((prevState) => !prevState);
          toast.error(resultData.message, {
            duration: 1000,
            style: errorStyles,
          });
        }
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    } else {
      toast.error("Recaptcha Not Verified", {
        duration: 1000,
        style: errorStyles,
      });

      dispatch(setLoading(true));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        values,
        setFieldValue,
        handleBlur,
      }) => (
        <FormikForm onSubmit={handleSubmit}>
          <InputField
            label="Verification Code"
            name="otp"
            id="otp"
            type="text"
            inputmode="numeric"
            pattern="\d*"
            onChange={(e) => {
              e.preventDefault();
              const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              const trimmedValue = inputValue.slice(0, 6); // Take only the first 6 digits

              // Check if the trimmed value is different to avoid triggering unnecessary re-renders
              if (trimmedValue !== values.otp) {
                setFieldValue("otp", trimmedValue);
              }
            }}
            onInput={(e) => {
              e.preventDefault();
              const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
              const trimmedValue = inputValue.slice(0, 6); // Take only the first 6 digits
              e.target.value = trimmedValue;
            }}
            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                e.preventDefault();
              }
            }}
            placeholder="Enter Verification Code"
          />

          {(touched.otp || isSubmitting) && errors.otp && (
            <Error error={errors.otp} />
          )}

          <button
            className="btn btn-primary w-100 mt-4"
            disabled={isSubmitting}
            type="submit"
          >
            Verify{" "}
            {isSubmitting && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <div className={`${styles.form_footer} mt-4`}>
            <button
              className="btn btn-outline-dark"
              onClick={() => navigate(-1)}
              type="button"
            >
              Back
            </button>
            {resendTimer === 0 || showResend ? (
              <button
                className="btn btn-outline-dark"
                onClick={handleResendClick}
                type="button"
                disabled={resendLoading} // Adjust the condition based on your logic
              >
                Resend
                {resendLoading && (
                  <span
                    className="spinner-border spinner-border-sm ms-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
              </button>
            ) : (
              <button
                className="btn btn-outline-dark"
                onClick={handleResendClick}
                type="button"
                disabled // Adjust the condition based on your logic
              >
                {formatTimer(resendTimer)}
              </button>
            )}
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default VerificationForm;
