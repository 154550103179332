import React, { ReactNode } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Footer from "../../molecules/Footer";
import Navbar from "../../molecules/Navbar";
import styles from "./auth_layout.module.scss";

interface AuthLayoutProps {
  children?: ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const navigate = useNavigate();

  const redirectToRequestAccessPage = () => {
    window.location.href = "https://insight.primealpha.com/request-access";
  };

  const redirectToRequestDemo = () => {
    window.location.href = "https://insight.primealpha.com/demo";
  };
  return (
    <>
      <Navbar dataShow={false} />
      <div className={styles.auth_screen}>
        <div className={styles.auth_cards}>
          <div className={styles.auth_card}>
            <p className="h24">
              Not registered yet? Sign up for a free account.
            </p>
            <p className="p16">
              Gain complimentary access to a variety of valuable tools.
            </p>
            <div className={styles.btn_div}>
              <button
                className="btn btn-primary"
                onClick={redirectToRequestAccessPage}
              >
                Request Access
              </button>
            </div>
          </div>
          <div className={styles.auth_card}>
            <p className="h24">Request a PrimeAlpha demo.</p>
            <p className="p16">
              Would you like to learn more about our ecosystem of investors,
              managers, and companies?
            </p>
            <div className={styles.btn_div}>
              <button
                className="btn btn-primary"
                onClick={redirectToRequestDemo}
              >
                Request a Demo
              </button>
            </div>
          </div>
        </div>

        <div className={styles.partition}></div>

        {/* <div className={styles.auth_content}>
          <div className={styles.auth_child}> */}
        <div className={styles.auth_content}>
          <div className={styles.auth_child}>
            <Outlet />
          </div>
        </div>
        {/* </div>
        </div> */}
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </>
  );
};

export default AuthLayout;
