import { Form, Formik } from "formik";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";

import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import { expitationTime } from "../../../../helpers/expirationTime";
import useRecaptcha from "../../../../hooks/useRecaptcha";
import { getData, postData } from "../../../../services";
import Error from "../../../atoms/Error";
import InputField from "../../../atoms/InputField";
import ReCaptchaTerms from "../../../atoms/RecaptchaTerms";

const WalkinForm = () => {
  const [pageError, setPageError] = useState(null);
  const [cookie, setCookie] = useCookies(["t"]);
  const navigate = useNavigate();
  const [loginMethod, setLoginMethod] = useState("password"); // Default login method

  //recaptcha handling
  const [refreshRecaptcha, setRefreshRecaptcha] = useState(false);
  const recaptchaToken = useRecaptcha("EMAIL", refreshRecaptcha);

  const initialValues = {
    email: "",
    password : null,
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    const recaptchaResponse = await getData({
      endpoint: "Wrapper/verifyRecaptcha",
      params: { token: recaptchaToken, expected_action: "EMAIL" },
    });

    if (recaptchaResponse?.riskScore > 0.5) {
      try {
        const lowercaseEmail = values.email.toLowerCase();
        const updatedValues = { ...values, email: lowercaseEmail };

        localStorage.setItem("em", values.email);
        
        if (loginMethod === "otp") {
          let endpoint = "/CustomerAuth/customerLogin"; // Default endpoint for password login
          let params = updatedValues;
          let requestData = {};

          const { data: resultData } = await postData({
            endpoint,
            data: requestData,
            params,
          });



          if (resultData.response === 200) {
            toast.success(resultData.message, {
              duration: 1000,
              style: confimationStyles,
            });
            setCookie("cuid", resultData.data.user_id, {
              path: "/",
              expires: expitationTime(),
              domain: process.env.REACT_APP_DOMAIN,
            });

              navigate("/auth/verify");
            
          } else {
            toast.error(resultData.message, {
              duration: 1000,
              style: errorStyles,
            });
          }
        }

        if (loginMethod === "password") {
          navigate("/auth/password-verify");
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      toast.error("Recaptcha Not Verified", {
        duration: 1000,
        style: errorStyles,
      });

      // If recaptcha is not verified, refresh the token
      setRefreshRecaptcha((prev) => !prev); // Toggle to regenerate token
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        isValid,
        dirty,
        handleSubmit,
        isSubmitting,
        touched,
        errors,
        setFieldValue,
        handleBlur,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          <div className="form-group">
            <InputField
              label="Email"
              type="text"
              id="email"
              name="email"
              value={values.email}
              onChange={(e) => setFieldValue("email", e.target.value)}
              onBlur={handleBlur}
              placeholder="Enter Email Address"
              autoComplete="off"
              className={`${
                ((touched.email || isSubmitting) && errors.email) || pageError
                  ? "error-input"
                  : ""
              }`}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\s/g, "");
              }}
            />
            {touched.email && errors.email && <Error error={errors.email} />}
            {pageError && values.email && !errors.email && (
              <Error error={pageError} />
            )}
          </div>

          {/* Login with Password */}
          <button
            className="btn btn-primary w-100 mt-4"
            disabled={isSubmitting}
            type="submit"
            onClick={() => setLoginMethod("password")}
          >
            Login with Password
            {isSubmitting && loginMethod === "password" && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          {/* Login with OTP */}
          <button
            className="btn btn-outline-dark w-100 mt-4"
            type="submit"
            onClick={() => setLoginMethod("otp")}
            disabled={isSubmitting}
          >
            Login with Email OTP
            {isSubmitting && loginMethod === "otp" && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <ReCaptchaTerms classes="mt-3 mb-3" />
        </Form>
      )}
    </Formik>
  );
};

export default WalkinForm;
