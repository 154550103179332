import { useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { useLocation } from "react-router-dom";
import { getData } from "../../../../../services";
import FormHeading from "../../../../atoms/FormHeading";
import TypeDiv from "../../../../atoms/TypeDiv";
import Footer from "../../../../molecules/Footer";
import Navbar from "../../../../molecules/Navbar";
import DataRoomImage from "../components/Dataroom/DataRoomImage";
import "./styles.scss";
import ViewDataShimmer from "./viewDataShimmer/viewDataShimmer";

const ViewDataRoom = () => {
  const location = useLocation();

  const params = new URLSearchParams(location.search);

  const id = params.get("id");
  const link_id = params.get("link_id");

  const [cookies] = useCookies(["t", "product_access", "library_access"]);
  const [videoRoomData, setVideoRoomData] = useState([]);
  const [view, setView] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [pageError, setPageError] = useState("");
  const [inactiveError, setInactiveError] = useState(null);
  // const { id } = useParams();

  const audioPlayerRef = useRef();
  const containerRef = useRef();

  const handleImageClick = () => {
    if (audioPlayerRef.current) {
      const audio = audioPlayerRef.current.audio.current;
      if (audio.paused) {
        audio.play();
        // setIsFocused(true);
      } else {
        audio.pause();
        // setIsFocused(false);
      }
      containerRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const [isExpired, setIsExpired] = useState(false);

  const checkExpiry = async () => {
    const res = await getData({
      endpoint: "ContentSharing/checkLinkExpiry",
      params: {
        link_id: link_id,
      },
    });

    if (res.response === 200) {
      //   console.log("called");
      setIsExpired(false);
      getVideoData();
    } else {
      setIsExpired(true);
    }
  };

  const getVideoData = async () => {
    setDataLoading(true);
    try {
      const res = await getData({
        endpoint: "Product/getDataRoomDataDetail",
        params: { data_id: id },
        token: cookies.t,
      });

      if (res) {
        setTimeout(() => {
          setDataLoading(false);
        }, 1000);
        setPageError("Data No Longer Exists.");
        if (
          res[0]?.product_status === "Inactive" ||
          !res[0]?.is_featured ||
          res[0]?.response === "404"
        ) {
          setInactiveError("Data No Longer Exists.");
        }
      }

      if (res?.[0]?.type === "Video") {
        setView("Video");
      } else if (res?.[0]?.type === "Podcast") {
        setView("Podcast");
      }

      setVideoRoomData(res);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    checkExpiry();
  }, [id]);

  const CreatedDate = videoRoomData[0]?.date
    ? (() => {
        const date = new Date(videoRoomData[0]?.date);
        const day = date.getDate();
        const month = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(date);
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "";
  const LastUpdatedDate = videoRoomData[0]?.modified_date
    ? (() => {
        const date = new Date(videoRoomData[0]?.modified_date);
        const day = date.getDate();
        const month = new Intl.DateTimeFormat("en-US", {
          month: "long",
        }).format(date);
        const year = date.getFullYear();
        return `${day} ${month} ${year}`;
      })()
    : "";

  const getEmbedUrl = (link) => {
    const youtubeRegex =
      /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/;
    const vimeoRegex = /vimeo\.com\/(?:video\/|manage\/videos\/)?(\d+)/;
    const buzzsproutRegex = /buzzsprout\.com\/(\d+)\/(\d+)/;

    const youtubeMatch = link?.match(youtubeRegex);
    const vimeoMatch = link?.match(vimeoRegex);
    const buzzsproutMatch = link?.match(buzzsproutRegex);

    if (youtubeMatch) {
      return {
        isBuzzsprout: false,
        isVimeo: false,
        embedUrl: `https://www.youtube.com/embed/${youtubeMatch[1]}`,
      };
    } else if (vimeoMatch) {
      return {
        isBuzzsprout: false,
        isVimeo: true,
        embedUrl: `https://player.vimeo.com/video/${vimeoMatch[1]}`,
      };
    } else if (buzzsproutMatch) {
      const embedUrl = `${link}-tet?client_source=small_player&iframe=true`;
      return {
        isBuzzsprout: true,
        isVimeo: false,
        embedUrl: embedUrl,
      };
    } else {
      return {
        isBuzzsprout: false,
        isVimeo: false,
        embedUrl: null,
      };
    }
  };
  const { isBuzzsprout, isVimeo, isYoutube, embedUrl } = getEmbedUrl(
    videoRoomData[0]?.link
  );

  const handleLoad = () => {
    setLoading(false);
  };

  return (
    <>
      <Navbar />
      <div className="video-dara-room-container">
        {isExpired ? (
          <>
            <div className="shared-expired">
              <FormHeading
                title="Link Expired"
                description="This link has already been expired or Revoked"
                type="center"
              />
            </div>
          </>
        ) : (
          <>
            <>
              {dataLoading ? (
                <ViewDataShimmer />
              ) : inactiveError ? (
                <>
                  <div className="shared-expired">
                    <FormHeading title={inactiveError} />
                  </div>
                </>
              ) : !dataLoading && videoRoomData?.[0]?.is_featured ? (
                <>
                  <div className="Data-room-item-container">
                    <div className="Data-pic-header-container">
                      <DataRoomImage
                        sub_type={
                          videoRoomData[0]?.sub_type
                            ? videoRoomData[0]?.sub_type
                            : videoRoomData[0]?.type
                        }
                        thumbnail={videoRoomData?.[0]?.thumbnail}
                      />
                      <div className="Date-header-container">
                        <div
                          style={{
                            display: "flex",
                            gap: "5px",
                            alignItems: "center",
                          }}
                          className="data-header-vide0-container"
                        >
                          <h3>{videoRoomData[0]?.name}</h3>
                          <TypeDiv
                            color="#B1E5FC"
                            data={
                              videoRoomData[0]?.sub_type
                                ? videoRoomData[0]?.sub_type
                                : videoRoomData[0]?.type
                            }
                          ></TypeDiv>
                        </div>

                        <span className="date-header video-display-none">
                          <div className="quill quill-change">
                            <div
                              className="ql-container ql-snow ql-no-border"
                              style={{ background: "white" }}
                            >
                              <div
                                className="ql-editor blog-content p-0"
                                style={{ padding: "0 !important" }}
                                dangerouslySetInnerHTML={{
                                  __html: videoRoomData[0]?.description,
                                }}
                              ></div>
                            </div>
                          </div>
                        </span>
                        <span className="p video-display-none">
                          Updated Date :{LastUpdatedDate}
                        </span>
                      </div>
                    </div>
                    <div>
                      <span
                        className="date-header video-display-none-web"
                        dangerouslySetInnerHTML={{
                          __html: videoRoomData[0]?.description,
                        }}
                      ></span>
                      <span className="p video-display-none-web">
                        Updated Date :{LastUpdatedDate}
                      </span>
                    </div>
                    <div
                      style={{ display: "flex", gap: "5px" }}
                      className="video-data-romm-btns-conatiner"
                    >
                      {!(
                        videoRoomData[0]?.sub_type === "Podcast" ||
                        videoRoomData[0]?.type === "Podcast" ||
                        videoRoomData[0]?.sub_type === "Video" ||
                        videoRoomData[0]?.type === "Video"
                      ) && (
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            window.open(videoRoomData[0]?.document_url)
                          }
                        >
                          Download
                        </button>
                      )}
                      {(videoRoomData[0]?.sub_type === "Podcast" ||
                        videoRoomData[0]?.sub_type === "Pdf" ||
                        videoRoomData[0]?.sub_type === "Video") && (
                        <button
                          className="btn btn-primary"
                          onClick={() =>
                            setView(
                              view
                                ? ""
                                : videoRoomData[0]?.sub_type
                                ? videoRoomData[0]?.sub_type
                                : videoRoomData[0]?.type
                            )
                          }
                        >
                          {view ? "Hide" : "View"}
                        </button>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                ((!dataLoading && !videoRoomData?.[0]?.is_featured) ||
                  videoRoomData?.[0]?.response === "404") && (
                  <>
                    <div className="shared-expired">
                      <FormHeading title={pageError} />
                    </div>
                  </>
                )
              )}

              {view === "Pdf" && (
                <iframe
                  src={videoRoomData[0]?.document_url}
                  className="pdf-iframe mt-3"
                />
              )}
              {view === "Podcast" && embedUrl && (
                <div
                  className="video-div"
                  style={{ height: isBuzzsprout ? "200px" : "" }}
                >
                  <iframe
                    src={embedUrl}
                    title="Embedded Video"
                    onLoad={handleLoad}
                    style={{
                      borderRadius: isVimeo && "10px",
                      backgroundColor: isVimeo && "black",
                    }}
                  ></iframe>
                </div>
              )}

              {videoRoomData[0]?.document &&
                videoRoomData[0]?.type === "Podcast" && (
                  <div className="mt-4" ref={containerRef}>
                    <AudioPlayer
                      ref={audioPlayerRef}
                      header={
                        <>
                          <div
                            className="shared-audio-image-div cursor-pointer"
                            onClick={handleImageClick}
                          >
                            <img
                              src={videoRoomData[0]?.podcast_thumbnail}
                              alt=""
                              width="100%"
                              height="100%"
                            />
                          </div>
                        </>
                      }
                      autoPlay={false}
                      src={videoRoomData[0]?.document}
                      onPlay={(e) => console.log("onPlay")}
                      showJumpControls={false}
                      autoPlayAfterSrcChange={false}
                      // other props here
                    />
                  </div>
                )}

              {view === "Video" && (
                <div
                  className="video-div"
                  style={{ height: isBuzzsprout ? "200px" : "" }}
                >
                  <iframe
                    src={embedUrl}
                    title="Video"
                    onLoad={handleLoad}
                    style={{
                      borderRadius: isVimeo && "10px",
                      backgroundColor: isVimeo && "black",
                    }}
                  ></iframe>
                </div>
              )}
            </>
          </>
        )}
      </div>
      <div className="footer-container">
      <Footer />
      </div>
    
    </>
  );
};
export default ViewDataRoom;
