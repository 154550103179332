import FormHeading from "../../atoms/FormHeading";
import LoginForm from '../../organisms/forms/authForms/LoginForm';

const Login = () => {

  // commented because of flow change
  
  // const navigate = useNavigate();
  // // Back btn Handling ---------------------------------------------------------------------------------------------
  // const onButtonEvent = (e : any) => {
  //   e.preventDefault();
  //   navigate(0);
  // };

  // useEffect(() => {
  //   window.history.pushState(null, '', window.location.pathname);
  //   window.addEventListener("popstate", onButtonEvent);
  //   return () => {
  //     window.removeEventListener("popstate", onButtonEvent);
  //   };
  // }, []);
  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
       <FormHeading
        title="Enter your password"
        description="Enter your password to log in to your account."
      />
      
      <LoginForm />
    </>
  );
};

export default Login;
