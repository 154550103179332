import Direction from "../../../../Icons/Direction";
import Location from "../../../../Icons/Location";
import CustomButton from "../../../../atoms/CustomeButton";
import IconCircle from "../../../../atoms/IconCircle";
import PageCard from "../../../../atoms/PageCard";
import "./component.scss";

const Address = ({ data }: any) => {
  const handleMapClick = (address: any) => {
    window.open(
      "https://maps.google.com?q=" +
        address?.latitude +
        "," +
        address?.longitude
    );
  };

  return (
    <div className="address-card-container-wrapper">
      <PageCard
        customStyles={{ backgroundColor: "#FFF", padding: "20px" , border : '1px solid #CED5DE'}}
        classes="address-card-container"
      >
        <div className="address-card">
          {data?.address?.map((address: any) => (
            <div
              key={address.id}
              className="d-flex gap-3 align-items-center address-single-card "
            >
              <IconCircle
                icon={<i className="d-flex">{<Location />}</i>}
                color="#efefef"
              />
              <div>
                <div className="p-dark mb-0 ">
                  {address.address}
                  {address.landmark && `, ${address.landmark}`}
                  {address.city && `, ${address.city}`}
                  {address.state_region && `, ${address.state_region}`}
                  {address.zip_code && `, ${address.zip_code}`}
                  {address.country && `, ${address.country}`}
                </div>
                <CustomButton
                  icon={<Direction />}
                  handleClick={() => {
                    handleMapClick(address);
                  }}
                  classes={"mapStyle"}
                  text="View in Map"
                />
              </div>
            </div>
          ))}
        </div>
      </PageCard>
    </div>
  );
};

export default Address;
