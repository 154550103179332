import "./styles.scss"; // Include shimmer styles here

const Shimmer = () => {
  return (
    <div className="shimmer-container">
      <div className="shimmer-row">
        <div className="shimmer-logo"></div>
        <div className="shimmer-content">
          <div className="shimmer-line shimmer-title"></div>
          <div className="shimmer-line shimmer-subtitle"></div>
          <div className="shimmer-line shimmer-text"></div>
        </div>
      </div>
    </div>
  );
};

export default Shimmer;
