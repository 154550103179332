import { useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { useDelete } from "../../../../hooks/useDelete";
import CustomMenu from "../../../atoms/CustomMenu";
import IconCircle from "../../../atoms/IconCircle";
import ImageViewer from "../../../atoms/ImageViewer";
import ShareComponent from "../../../atoms/postComponents/LikeComponent/ShareComponent";
import TypeDiv from "../../../atoms/TypeDiv";
import DeleteRed from "../../../Icons/DeleteRed";
import EditBlue from "../../../Icons/EditBlue";
import Leave from "../../../Icons/Leave";
import Report from "../../../Icons/Report";
import VerticalMenu from "../../../Icons/VerticalMenu";
import View from "../../../Icons/View";
import Modal from "../../../molecules/Modal/DeleteModal";
import NoContentCard from "../../../molecules/NoContentCard";
import Pagination from "../../../molecules/Pagination";
import ReportFunctionality from "./ReportFunctionality";
import Shimmer from "./Shimmer";

const CommunityTab = ({
  isLoading,
  data,
  handleEditModal,
  handleJoinCommunity,
  itemsPerPage,
  currentPage,
  onPageChange,
  onItemsPerPageChange,
  refetch,
  refetchOther
}) => {
  const [cookies] = useCookies(["cuid", "t"]);
  const [selectedRow, setSelectedRow] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLeaveModalOpen, setIsLeaveModalOpen] = useState(false);

  const [modal, setModal] = useState(false);

  const colors = {
    Owner: "#B5E4CA",
    Moderator: "#FFBC99",
    Member: "#CABDFF",
  };

  const handleStatusMenu = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCommunityView = (item) => {
    window.open(`${process.env.REACT_APP_COMMUNITY_URL}community/${item.slug}`);
  };

  const menu = [
    {
      id: "view",
      label: "View",
      icon: <View />,
      onClick: () => {
        handleCommunityView(selectedRow);
        handleClose();
      },
    },

    // {
    //   id: "edit",
    //   label: "Report",
    //   onClick: () => handleEditModal(selectedRow?.id),
    // },
  ];

  if (selectedRow?.role !== "Member") {
    menu.push({
      id: "edit",
      label: "Edit",
      icon: <EditBlue />,
      onClick: () => {
        handleEditModal(selectedRow?.id);
        handleClose();
      },
    });
  }

  if (selectedRow?.role === "Owner") {
    menu.push({
      id: "edit",
      label: "Delete",
      icon: <DeleteRed fill="#4e5660" />,
      onClick: () => {
        setIsDeleteModalOpen(true);
        handleClose();
      },
    });
  }

  if (
    !selectedRow?.is_private ||
    (selectedRow?.is_private && selectedRow?.role !== "Member")
  ) {
    menu.push({
      id: "edit",
      label: <ShareComponent post_id={selectedRow?.slug} type="community"/>,
      //   icon: <Share />,
      // onClick: () => handleEditModal(selectedRow?.id),
    });
  }

  menu.push({
    id: "report",
    icon: <Report />,
    label: "Report",
    onClick: () => {
      setModal(true);
      handleClose();
    },
  });

  if (selectedRow?.role !== "Owner") {
    menu.push({
      id: "leave",
      label: "Leave Community",
      icon: <Leave fill="#4e5660" />,
      onClick: () => {
        setIsLeaveModalOpen(true);
        handleClose();
      },
    });
  }

  // Memoize total items for better performance
  const totalItems = useMemo(() => data?.total_record ?? 0, [data]);

  console.log("data", data);

  const deletecommunity = useDelete();

  const handleDeletion = async () => {
    try {
      await deletecommunity(
        "Community/deleteCommunity",
        "Community",
        {
          id: selectedRow?.id,
        },
        null,
        "community"
      );
      // fetchInsightsData();
      setIsDeleteModalOpen(false);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  const handleLeaveCommunity = async () => {
    try {
      await deletecommunity(
        "Community/removeUser",
        "Community",
        {
          community_id: selectedRow?.id,
          user_id: cookies.cuid,
        },
        null,
        "community",
        `You are no longer a member of the ${selectedRow?.name}.`
      );
      // fetchInsightsData();
      setIsLeaveModalOpen(false);
      refetch();
      refetchOther()
    } catch (error) {
      console.log(error);
    }
  };

  console.log("com-data", data?.data?.length);

  return (
    <>
      <div className="data-container">
        {isLoading ? (
          <>
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
            <Shimmer />
          </>
        ) : (
          <>
            {data?.data?.length > 0 ? (
              data?.data.map((item) => (
                <div className="data-row" key={item?.id}>
                  <ImageViewer
                    type="Company"
                    imageUrl={item?.logo}
                    viewType="community"
                  />
                  <div className="row-content-container">
                    <div className="row-content">
                      <div className="d-flex align-items-center gap-3">
                        <a href={`${process.env.REACT_APP_COMMUNITY_URL}community/${item.slug}`} style={{textDecoration : 'none'}} target="_blank">
                        <p className="p16 p-dark semi-light mb-0">
                          {item?.name}
                        </p>
                        </a>
                
                        {item?.access_to_community ? (
                          <TypeDiv
                            data={item?.role}
                            color={colors?.[item?.role]}
                          />
                        ) : (
                          <TypeDiv
                            data={item?.is_private ? "Private" : "Public"}
                            color={item?.is_private ? "#B5E4CA" : "#FFBC99"}
                          />
                        )}
                      </div>

                      <div className="d-flex gap-1 align-items-center">
                        <div className="p-grey" style={{ color: "#6F767E" }}>
                          {item?.members}{" "}
                          {item?.members > 1 ? "members" : "member"}
                        </div>
                        <div className="dot"></div>
                        <div className="p-grey" style={{ color: "#6F767E" }}>
                          {item?.country}, {item?.state}, {item?.city}
                        </div>
                      </div>
                      <p className="p-grey light" style={{ color: "#6F767E" }}>
                        {item?.description?.length > 250
                          ? `${item.description.substring(0, 250)}...`
                          : item?.description}
                      </p>
                    </div>
                    {item?.access_to_community ? (
                      <div className="d-flex gap-3">
                        <IconCircle
                          icon={<VerticalMenu />}
                          isClickable
                          handleClick={(event) => handleStatusMenu(event, item)}
                        />

                        <CustomMenu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl) && selectedRow === item}
                          onClose={handleClose}
                          items={menu}
                        />
                      </div>
                    ) : (
                      <>
                        {item?.requested ? (
                          <button
                            disabled
                            className="btn btn-outline-dark btn-sm"
                          >
                            Requested
                          </button>
                        ) : item?.invited ? (
                          <button
                          disabled
                          className="btn btn-outline-dark btn-sm w-10"
                          style={{ flexShrink: 0 }}
                        >
                          Invited
                        </button>
                        ) :(
                          <button
                            className="btn btn-outline-primary w-10 btn-sm"
                            onClick={() =>
                              handleJoinCommunity({ community_id: item?.id , is_private : item?.is_private})
                            }
                   
                          >
                            Join
                          </button>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <NoContentCard title="No Data Found" />
            )}
          </>
        )}
      </div>

      {/* Pagination */}
      {totalItems > 10 && !isLoading && (
        <div className="mt-3">
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={totalItems}
            currentPage={currentPage}
            onPageChange={onPageChange}
            onItemsPerPageChange={onItemsPerPageChange}
          />
        </div>
      )}

      {isDeleteModalOpen && (
        <Modal
          isActive={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          title="Community"
          type="Delete"
          onClick={handleDeletion}
          description="Are you sure you want to delete this community"
        />
      )}

      {isLeaveModalOpen && (
        <Modal
          isActive={isLeaveModalOpen}
          onClose={() => setIsLeaveModalOpen(false)}
          title="Community"
          type="Leave"
          onClick={handleLeaveCommunity}
          description={`Are you sure you want to leave "${selectedRow.name}".`}
        />
      )}

      {modal && (
        <ReportFunctionality
          modal={modal}
          setModal={setModal}
          id={selectedRow?.id}
        />
      )}
    </>
  );
};

export default CommunityTab;
