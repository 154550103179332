import CircleAvatar from "../../../atoms/CircleAvatar";
import CustomButton from "../../../atoms/CustomeButton";
import "./styles.scss";

const SimilarPeopleProfiles = ({ similarData }) => {
  const handleView = (id) => {
    window.open(`/dashboard/people/${id}`, "_blank");
  };
  return (
    <>
      {similarData?.map((item) => {
        return (
          <div className="card" style={{ backgroundColor: "transparent" }}>
            <div className="box">
              <div className="me-2">
                {item?.profile_photoS ? (
                  <img
                    src={item?.profile_photo}
                    alt="Profile"
                    className="team-image"
                  />
                ) : (
                  <>
                    <CircleAvatar
                      borderRadius="50%"
                      name={item?.name}
                      size={47}
                      fontSize="17px"
                      color="#fff"
                      fontColor="#3D9BE9"
                      border="1px solid #efefef"
                      iconColor="#3D9BE9"
                    />
                  </>
                )}
              </div>
              <div className="d-flex flex-column">
                <div className="team-people"></div>
                <div class="h6-j mb-0" style={{ overflowWrap: "anywhere" }}>
                  {item?.name}
                </div>
                <div>
                  <div className="d-flex align-items-center">
                    <div className="p line-truncate">{item?.job_title}</div>
                    {/* <div className="dot"></div> */}
                    {/* <div class="p">{item.company}</div> */}
                  </div>
                </div>
              </div>
            </div>

            <CustomButton
              // icon={<Direction />}
              handleClick={() => {
                handleView(item?.id);
              }}
              classes="btn-outline-dark btn-sm"
              text="View "
            />
          </div>
        );
      })}
    </>
  );
};

export default SimilarPeopleProfiles;
