"use client";
import { useState } from "react";
import { useCookies } from "react-cookie";
import { deleteData, postData } from "../../../../services";
import Like from "../../../Icons/Like";
import "./styles.scss";

const LikeComponent = ({ post_id, is_liked, incrementLike, decrementLike }) => {
  // Initialize isClicked based on the is_liked prop
  const [isClicked, setIsClicked] = useState(is_liked);
  const [cookies] = useCookies(["t", "cuid"]);

  // Function to handle liking a post
  const handleLikePost = async () => {
    const res = await postData({
      endpoint: "Post/addLike",
      type: "community",
      params: {
        post_id: post_id,
        user_id: cookies.cuid,
      },
    });
  };

  // Function to handle removing a like from a post
  const handleRemovePost = async () => {
    const res = await deleteData({
      endpoint: "Post/removeLike", // Assuming this is the correct endpoint
      type: "community",
      params: {
        post_id: post_id,
        user_id: cookies.cuid,
      },
    });
  };

  // Toggle between like and unlike
  const handleClick = () => {
    if (isClicked) {
      handleRemovePost(); // If it is already liked, remove like
      decrementLike();
    } else {
      handleLikePost(); // If it is not liked, add like
      incrementLike();
    }
    setIsClicked(!isClicked); // Toggle the isClicked state
  };

  return (
    <div onClick={handleClick} className="center-div gap-2" id="post_like">
      <Like className={isClicked ? "active-like" : ""} />
      {isClicked ? "Liked" : "Like"}
    </div>
  );
};

export default LikeComponent;
