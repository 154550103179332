"use client";
import { useEffect, useState } from "react";
import Copy from "../../../../Icons/Copy";
import Facebook from "../../../../Icons/Facebook";
import LinkedIn from "../../../../Icons/LinkedIn";
import Share from "../../../../Icons/Share";
import X from "../../../../Icons/X";
import Modal from "../../../../molecules/Modal/Normal";
import "./styles.scss";

const ShareComponent = ({
  post_id,
  type = "post",
  openShare,
  setOpenShare,
}) => {
  const [shareModal, setShareModal] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (openShare) {
      setShareModal(true);
    }
  }, [openShare]);
  // Constructing the post link
  // const link = `${process.env.NEXT_PUBLIC_SITE_URL}community/posts/${post_id}`;
  const link =
    type === "post"
      ? `${process.env.REACT_APP_COMMUNITY_URL}community/posts/${post_id}`
      : type === "community"
      ? `${process.env.REACT_APP_COMMUNITY_URL}community/${post_id}`
      : {};
  // const link = 'https://discover.primealpha.co/community/An%20Investor%20Community-8';

  // Social media share URLs
  const twitterShare = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    link
  )}&text=Check%20out%20this%20post!`;
  const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    link
  )}`;
  const linkedInShare = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    link
  )}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(link).then(
      () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
      },
      (err) => {
        console.error("Failed to copy the link: ", err);
      }
    );
  };

  const handleclose = () => {
    if (openShare) {
      setOpenShare(false);
      setShareModal(false);
    } else {
      setShareModal(false);
    }
  };
  return (
    <>
      <div className="center-div gap-2" onClick={() => setShareModal(true)}>
        {/* {type === "post" && <Share />} */}
        <Share />
        Share
      </div>

      {shareModal && (
        <Modal
          isActive={shareModal}
          title="Share Post"
          onClose={handleclose}
          width="650px"
        >
          <div className="form-group">
            <label htmlFor="">Copy Link</label>
          </div>
          <div className="share-div">
            <div className="share-link-container">
              <span className="ms-2">{link}</span>
            </div>
            {copied ? (
              <span style={{ color: "#3d9be9" }}>Copied!</span>
            ) : (
              <div className="copy-icon-container" onClick={copyToClipboard}>
                <Copy />
              </div>
            )}
          </div>

          <div className="share-options pt-3 my-3">
            <a href={linkedInShare} target="_blank" rel="noopener noreferrer">
              <LinkedIn /> Share on LinkedIn
            </a>
            <div className="dot"></div>
            <a href={twitterShare} target="_blank" rel="noopener noreferrer">
              <X /> Share on X
            </a>
            <div className="dot"></div>
            <a href={facebookShare} target="_blank" rel="noopener noreferrer">
              <Facebook /> Share on Facebook
            </a>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ShareComponent;
