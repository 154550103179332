// import PropTypes from "prop-types";
// import type { FC, ReactNode } from "react";
// import { useCookies } from "react-cookie";
// import { Navigate, useLocation } from "react-router-dom";
// import { USERTYPE } from "../helpers/constantData";
// import { useAuthenticated } from "../hooks/useAuthenticated";

// interface GuestGuardProps {
//   children: ReactNode;
// }

// const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
//   const auth = useAuthenticated();
//   const [cookies] = useCookies(["ut"]);
//   const decodedString = cookies?.ut ? atob(cookies?.ut) : null;
//   const location = useLocation();

//   console.log('loc',location)
//   debugger;
//   if (auth) {
//     if (decodedString === USERTYPE) {
//       return <Navigate to="/dashboard/community" />;
//     } else return <Navigate to="/dashboard" />;
//   }

//   return <>{children}</>;
// };

// GuestGuard.propTypes = {
//   children: PropTypes.node,
// };

// export default GuestGuard;

import PropTypes from "prop-types";
import type { FC, ReactNode } from "react";
import { useCookies } from "react-cookie";
import { Navigate, useLocation } from "react-router-dom";
import { USERTYPE } from "../helpers/constantData";
import { useAuthenticated } from "../hooks/useAuthenticated";

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const auth = useAuthenticated();
  const [cookies] = useCookies(["ut"]);
  const location = useLocation(); // Get current location
  const decodedString = cookies?.ut ? atob(cookies?.ut) : null;

  // Extract redirectUrl from query params (when user is unauthenticated)
  const searchParams = new URLSearchParams(location.search);
  const redirectUrl = searchParams.get("redirectUrl");

 if(redirectUrl){
  localStorage.setItem('redirectUrl',redirectUrl)
 }

  if (auth) {
    // If authenticated, redirect to appropriate dashboard or the redirectUrl
    const defaultRedirectPath =
      decodedString === USERTYPE ? "/dashboard/community" : "/dashboard";

    return <Navigate to={redirectUrl || defaultRedirectPath} replace />;
  }

  // If not authenticated, ensure redirectUrl is preserved in the child components
  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;

