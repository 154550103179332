import { Box } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { confimationStyles } from "../../../../assets/styles/toast";
import communityAxios from "../../../../communityAxios";
import { getData } from "../../../../services";
import ContentContainer from "../../../atoms/ContentContainer";
import Heading from "../../../atoms/Heading";
import TabStrip from "../../../molecules/TabStrip";
import Renderdetails from "./components/renderdetails";
import Reporteddetails from "./components/reporteddetails";
import Sentdetails from "./components/sentdetails";
import "./styles.scss";

const CommunityRequest = () => {
  const [cookies] = useCookies(["t", "cuid"]);
  const [activeTab, setActiveTab] = useState("Received");
  const [sentRequest, setSendRequest] = useState(null);
  const [receivedData, setReceivedData] = useState(null);
  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const onPageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const onItemsPerPageChange = useCallback((perPage) => {
    setItemsPerPage(perPage);
    setCurrentPage(1);
  }, []);

  // Fetch Received Requests
  const getCommunityAndPostRequests = useCallback(
    async (categories) => {
      setIsLoading(true);
      try {
        const res = await getData({
          endpoint: "Community/getCommunityAndPostRequests",
          type: "Community",
          params: {
            user_id: cookies.cuid,
            page: currentPage,
            page_limit: itemsPerPage,
            types: categories?.length > 0 ? JSON.stringify(categories) : null,
          },
          token : cookies.t
        });
        if (res) {
          setReceivedData(res);
        }
      } catch (error) {
        console.error("Failed to fetch Community Request data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [cookies.cuid, itemsPerPage, currentPage]
  );

  // Fetch Sent Requests
  const getAllPostSentReports = useCallback(
    async (categories) => {
      setIsLoading(true);
      try {
        const res = await getData({
          endpoint: "Community/getCommunityAndPostSentRequests",
          type: "Community",
          params: {
            user_id: cookies.cuid,
            page: currentPage,
            page_limit: itemsPerPage,
            types: categories?.length > 0 ? JSON.stringify(categories) : null,
          },
          token : cookies.t
        });
        if (res) {
          setSendRequest(res);
        }
      } catch (error) {
        console.error("Failed to fetch Sent Request data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [cookies.cuid, itemsPerPage, currentPage]
  );

  // Fetch Reported Requests
  const getAllPostReports = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await getData({
        endpoint: "ReportSpam/getAllPostReports",
        type: "Community",
        params: {
          user_id: cookies.cuid,
          page: currentPage,
          page_limit: itemsPerPage,
        },
        token : cookies.t
      });
      if (res) {
        setReportData(res);
      }
    } catch (error) {
      console.error("Failed to fetch Reported Request data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [cookies.cuid, itemsPerPage, currentPage]);

  // Trigger fetching data on mount
  useEffect(() => {
    if (activeTab === "Received") {
      getCommunityAndPostRequests();
    } else if (activeTab === "Sent") {
      getAllPostSentReports();
    } else if (activeTab === "Reported") {
      getAllPostReports();
    }
  }, [activeTab, currentPage, itemsPerPage]);

  // Function to call the Invitation Accept/Reject API
  const sendInvitationResponse = async (
    type,
    community_id,
    user_id,
    status
  ) => {
    try {
      const response = await communityAxios.patch(
        "/Community/invitationAcceptReject",
        null,
        {
          params: {
            type: type,
            id: community_id,
            user_id: user_id,
            status: status ? "Active" : "Reject", // API expects a string status
          },
        }
      );
      if (response?.data?.status) {
        toast.success(response?.data?.message, {
          duration: 1000,
          style: confimationStyles,
        });
        getCommunityAndPostRequests();
      }
    } catch (error) {
      console.error("Error in API call:", error);
    }
    // finally {
    //   setLoading(false);
    // }
  };

  // Funtion to WithDraw Invitation
  const withDrawInvitation = async (community_id, type, user_id) => {
    try {
      const response = await communityAxios.delete(
        "/Community/withdrawCommunityInvite",
        {
          params: {
            id: community_id,
            type: type,
            user_id: user_id,
          },
          headers: {
            Authorization: `Bearer ${cookies?.t}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.data?.status) {
        toast.success(response?.data?.message, {
          duration: 1000,
          style: confimationStyles,
        });
        getAllPostSentReports();
      }
      console.log("API response:", response.data);
    } catch (error) {
      console.error("Error in API call:", error);
    } finally {
      // setLoading(false);
    }
  };

  // Handle Tab change
  const onTabChange = (event, value) => {
    setActiveTab(value);
    setCurrentPage(1);
    setItemsPerPage(10)
  };

  // Get the component for the selected tab
  const _getComponent = (tabLabel) => {
    const tabData = dataArray.find((item) => item.label === tabLabel);
    return tabData ? tabData.view : null;
  };

  // Define the tab structure
  const dataArray = [
    {
      id: 0,
      label: "Received",
      view: (
        <Renderdetails
          activeTab={activeTab}
          data={receivedData}
          isLoading={isLoading}
          itemsPerPage={itemsPerPage}
          sendInvitationResponse={sendInvitationResponse}
          getCommunityAndPostRequests={getCommunityAndPostRequests}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onItemsPerPageChange={onItemsPerPageChange}
        />
      ),
    },
    {
      id: 1,
      label: "Sent",
      view: (
        <Sentdetails
          activeTab={activeTab}
          isLoading={isLoading}
          data={sentRequest}
          itemsPerPage={itemsPerPage}
          withDrawInvitation={withDrawInvitation}
          getAllPostSentReports={getAllPostSentReports}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onItemsPerPageChange={onItemsPerPageChange}
        />
      ),
    },
    {
      id: 2,
      label: "Reported",
      view: (
        <Reporteddetails
          activeTab={activeTab}
          isLoading={isLoading}
          data={reportData}
          itemsPerPage={itemsPerPage}
          getAllPostReports={getAllPostReports}
          currentPage={currentPage}
          onPageChange={onPageChange}
          onItemsPerPageChange={onItemsPerPageChange}
  
        />
      ),
    },
  ];

  return (
    <ContentContainer id="Community-Requests-Page">
      <div className="d-flex flex-column w-100">

        <Heading title="Manage Requests" type="main"/>
        <div className="tab-strip-container">
          <TabStrip
            activeTab={activeTab}
            onTabChange={onTabChange}
            data={dataArray}
            type="small"
          />
        </div>

        <Box sx={{ width: "100%" }}>
          <Box sx={{ pt: 2 }}>{_getComponent(activeTab)}</Box>
        </Box>
      </div>
    </ContentContainer>
  );
};

export default CommunityRequest;
