import * as signalR from "@microsoft/signalr";
import { createContext, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getData, postData } from "../../services"; // Assuming postData is available to send data to API

const CommunityNotificationContext = createContext();

export const CommmunityNotificationProvider = ({ children }) => {
  const [cookies] = useCookies(["t", "cuid"]);
  const [notificationData, setNotificationData] = useState([]);
  const [notificationCount, setNotificationCount] = useState(0);
  const [showRed, setShowRed] = useState(false);
  const [loading, setLoading] = useState(true);
  const [unread, setUnread] = useState([]);
  const [unreadTypes, setUnreadTypes] = useState({});

  const baseURL = process.env.REACT_APP_COMMUNITY_NOTIFICATION_HUB_URL;

  // SignalR connection setup
  const setupSignalRConnection = () => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(baseURL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    connection.start().catch((error) => {
      console.error("SignalR Connection Error: ", error);
    });

    connection.on("ReceiveNotification", (notification) => {
      if (notification?.receiver_ids.includes(cookies.cuid)) {
        setShowRed(true);
        setNotificationData((prev) => [notification, ...prev]);
      }
    });
  };

  // Fetch notifications from API
  const getNotificationData = async () => {
    const res = await getData({
      endpoint: "Community/getNotifications",
      params: {
        user_id: cookies.cuid,
      },
      token: cookies.t,
      type: "community",
    });

    if (res) {
      setNotificationData(res);
      setLoading(false);
    }
  };

  // Mark a specific notification as read
  const markAsRead = async () => {
    if (unread.length > 0) {
      try {
        // debugger;
        const res = await postData({
          endpoint: "Community/updateNotificationFlag",
          params: {
            community_ids:
              unreadTypes?.community_ids?.length > 0
                ? JSON.stringify(unreadTypes?.community_ids)
                : null,
            post_ids:
              unreadTypes?.post_ids?.length > 0
                ? JSON.stringify(unreadTypes?.post_ids)
                : null,
            report_id:
              unreadTypes?.report_id?.length > 0
                ? JSON.stringify(unreadTypes?.report_id)
                : null,
          },
          token: cookies.t,
          type: "community",
        });

        if (res?.data?.status) {
          // Update the local notification state
          setNotificationData((prevData) =>
            prevData.map((notification) =>
              unread.includes(notification.id)
                ? { ...notification, notification_flag: false }
                : notification
            )
          );
          // Update unread count
          setNotificationCount((prevCount) => prevCount - 1);
          // Hide red dot if all notifications are read
          if (notificationCount === 1) {
            setShowRed(false);
          }
        }
      } catch (error) {
        console.error("Error marking notification as read:", error);
      }
    }
  };

  // Count unread notifications
  useEffect(() => {
    const unreadNotifications = notificationData.filter(
      (notification) => notification.notification_flag === true
    );

    const unreadNotId = unreadNotifications?.map(
      (notification) => notification.id
    );

    const separatedIds = unreadNotifications.reduce(
      (acc, notification) => {
        if (notification.type === "Community") {
          acc.community_ids.push(notification.id);
        } else if (notification.type === "Report") {
          acc.report_id.push(notification.id);
        } else {
          acc.post_ids.push(notification.id);
        }
        return acc;
      },
      { community_ids: [], report_id: [], post_ids: [] }
    );

    setUnreadTypes(separatedIds);

    // const uniqueUnread = [...new Set(unreadNotId)];
    setUnread(unreadNotId);

    const count = unreadNotId.length;
    if (count > 0) {
      setShowRed(true);
    }
    setNotificationCount(count);
  }, [notificationData]);

  useEffect(() => {
    setupSignalRConnection();
    getNotificationData(); // Only called once on initial render
  }, []);

  return (
    <CommunityNotificationContext.Provider
      value={{
        notificationData,
        notificationCount,
        showRed,
        loading,
        setShowRed,
        setNotificationData,
        markAsRead, // Expose the markAsRead function
      }}
    >
      {children}
    </CommunityNotificationContext.Provider>
  );
};

// Custom hook to use the notification context
export const useCommunityNotificationContext = () =>
  useContext(CommunityNotificationContext);
