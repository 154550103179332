import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";

const mapContainerStyle = {
  width: "100%",
  height: "200px",
  backgroundColor: "#f4f4f4",
};

const center = {
  lat: 40.7128, // Default latitude
  lng: -74.006, // Default longitude
};

const MapWithPin = ({ location , type = 'post'}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_KEY, // Replace with your API key
  });

  const finalMapStyles = {...mapContainerStyle, borderRadius : type === 'view' ? '10px 10px 0 0' : '10px', height :  type === 'view' ? '240px' : '200px'}

  const [markerPosition, setMarkerPosition] = useState(center);

  useEffect(() => {
    if (location) {
      setMarkerPosition(location);
    }
  }, [location]);

  return (
    <>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={finalMapStyles}
          zoom={14}
          center={markerPosition}
        >
          <MarkerF position={markerPosition} />
        </GoogleMap>
      )}
    </>
  );
};

export default MapWithPin;
