import { useEffect, useRef, useState } from "react";
import PostView from "./PostView";

const CommunityPosts = ({
  communityData,
  posts,
  peopleDetails,
  fetchUpdatedPosts,
  fetchNextPage,
}) => {
  const loaderRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [visiblePosts, setVisiblePosts] = useState(posts?.data || []);

  const handleNewPageFetch = async () => {
    if (visiblePosts?.length < posts?.total_record) {
      setIsLoading(true);

      const loaded = await fetchNextPage();
      setIsLoading(loaded);
    }
  };

  // Infinite scroll handling using IntersectionObserver
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          // fetchNextPage();

          handleNewPageFetch();
        }
      },
      { threshold: 1.0 }
    );
    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }
    return () => {
      if (loaderRef.current) observer.unobserve(loaderRef.current);
    };
  }, [fetchNextPage]);



  useEffect(() => {
    setVisiblePosts(posts?.data || []);
  }, [posts]);

  const handleReported = (id) => {
    setVisiblePosts((prevPosts) => prevPosts.filter((post) => post.id !== id));
  };


  return (
    <div className="posts-container">
      {visiblePosts?.map((post) => (
        <PostView
          key={post.id}
          name={post?.creator?.name}
          data={post}
          profile={post?.creator?.profile_photo}
          peopleDetails={peopleDetails}
          fetchUpdated={fetchUpdatedPosts}
          role={communityData?.role}
          is_share_allowed={
            !communityData?.is_private ||
            (communityData?.is_private && communityData?.role !== "Member")
          }
          onConfirm={() => handleReported(post.id)}
        />
      ))}
      {/* {isLoading && ( */}
      <div ref={loaderRef} className=" w-100">
        {isLoading && (
          <div className="d-flex align-items-center justify-content-center w-100">
            <div class="spinner-border text-secondary" role="status">
              <span class="sr-only"></span>
            </div>
          </div>
        )}
      </div>
      {/* )} */}
    </div>
  );
};

export default CommunityPosts;
