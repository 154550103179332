// components/LinkPreview.jsx
import { useEffect, useState } from "react";
import "./styles.scss";

const LinkPreview = ({ url }) => {
  const [metaTags, setMetaTags] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMetadata = async () => {
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_COMMUNITY_URL
          }api/fetchMetadata?url=${encodeURIComponent(url)}`
        );

        const data = await response.json();

        // console.log('meta - data',data)
        setMetaTags(data);
      } catch (error) {
        console.error("Error fetching metadata:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMetadata();
  }, [url]);

  if (loading) {
    return (
      <div id="LinkPreview">
        <div className="preview-img">
          <div className="shimmer-img"></div>
        </div>

        <div className="content shimmer-content">
          <div className="shimmer-title"></div>
          <div className="shimmer-text"></div>
        </div>

        {/* {metaTags?.author && <p>Author: {metaTags?.author}</p>}
    {metaTags?.platform && <p>Platform: {metaTags?.platform}</p>} */}
      </div>
    );
  }

  return (
    <a href={url || "/"} target="_blank" style={{ textDecoration: "none" }}>
      <div id="LinkPreview">
        {metaTags?.image && (
          <img src={metaTags?.image} alt="Preview" className="preview-img" />
        )}

        {(metaTags?.title || metaTags?.description) && (
          <div className="content">
            <h1 className="h5-i">{metaTags?.title}</h1>
            <p className="p mb-0">{metaTags?.description}</p>
          </div>
        )}

        {/* {metaTags?.author && <p>Author: {metaTags?.author}</p>}
      {metaTags?.platform && <p>Platform: {metaTags?.platform}</p>} */}
      </div>
    </a>
  );
};

export default LinkPreview;
