import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useSelector } from "react-redux";
import { timeAgo } from "../../../../../helpers/timeAgo";
import {
  deleteData,
  getData,
  patchData,
  postData,
} from "../../../../../services";
import CustomMenu from "../../../../atoms/CustomMenu";
import PeopleProfileCard from "../../../../atoms/PeopleProfileCard";
import DeleteRed from "../../../../Icons/DeleteRed";
import EditBlue from "../../../../Icons/EditBlue";
import Menu from "../../../../Icons/Menu";

const CommentsSection = ({
  post_id,
  comments,
  profile,
  name,
  isOpen,
  peopleDetails,
  incrementComment,
  decrementComment,
}) => {
  const [allData, setAllData] = useState();
  const [allComments, setAllComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [replyCommentId, setReplyCommentId] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [replyText, setReplyText] = useState("");
  const [cookies] = useCookies(["cuid"]);
  const userInfo = useSelector((state) => state.peopleData?.peopleData);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);

  const [editingCommentId, setEditingCommentId] = useState(null); // Track which comment is being edited
  const [editedText, setEditedText] = useState(""); // Track the new text for the comment being edited

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1); // Initialize to 1

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleStatusMenu = (event, item) => {
    setAnchorEl(event.currentTarget);
    // setSelectedRow(row);
    setSelectedComment(item);
  };

  const menu = [
    {
      id: "edit",
      label: "Edit",
      icon: <EditBlue fill="black" width="15" height="15" />,
      onClick: () => {
        // handleCommunityView(selectedRow);
        setEditingCommentId(selectedComment.id);
        setEditedText(selectedComment.comment);
        handleClose();
      },
    },

    {
      id: "delete",
      label: "Delete",
      icon: <DeleteRed fill={"black"} />,
      onClick: () => {
        setAnchorEl(null);
        handleDeleteComment({ id: selectedComment?.id });
      },
    },
  ];

  const fetchComments = async (page = 1) => {
    try {
      if (page === 1) {
        setIsLoading(true);
      } else {
        setIsLoadingMore(true);
      }

      const res = await getData({
        endpoint: "Post/getAllComments",
        type: "community",
        params: { post_id, user_id : cookies.cuid, page },
      });

      if (res) {
        setAllData(res);
        setAllComments((prevComments) =>
          page === 1 ? res.comments : [...prevComments, ...res.comments]
        );
        const total_pages = Math.ceil(res?.total_comments / res?.page_limit);
        setTotalPages(total_pages || 1); // Assuming the API returns total_pages
        setCurrentPage(page);
      }
    } catch (error) {
      console.error("Error fetching comments:", error);
      // Optionally, show an error message
    } finally {
      if (page === 1) {
        setIsLoading(false);
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      setCurrentPage(1)
      fetchComments(1);
    }
  }, [isOpen]);

  // useEffect(() => {
  //   const commentsWithLikeAndReplyStatus = comments.map((comment) => ({
  //     ...comment,
  //     liked: false,
  //     replies: comment.replies || [],
  //   }));
  //   setAllComments(commentsWithLikeAndReplyStatus);
  // }, [comments]);

  const handleAddComment = async () => {
    if (newComment.trim()) {
      const newCommentData = {
        id: allComments.length + 1,
        user: {
          name: userInfo?.name,
          profile_photo: userInfo?.profile_photo,
          id: cookies.cuid,
        },
        comment: newComment,
        time: new Date(),
        liked: false,
        replies: [],
      };
      setAllComments([newCommentData, ...allComments]);
      setNewComment("");
    }

    incrementComment();

    try {
      const res = await postData({
        type: "community",
        endpoint: "Post/addComment",
        data: {
          post_id: post_id,
          user_id: cookies.cuid,
          comment: newComment,
        },
      });

      if (res) {
        const newId = res?.data?.data?.comment_id;

        setAllComments((prevComments) => {
          const updatedComments = [...prevComments];
          updatedComments[0].id = newId; // Update the ID of the first comment
          return updatedComments;
        });
      }
    } catch {}
  };

  const handleDeleteComment = async ({ id }) => {
    decrementComment();

    setAllComments((prevComments) =>
      prevComments.filter((comment) => comment.id !== id)
    );

    try {
      const res = deleteData({
        type: "community",
        endpoint: "Post/deleteComment",
        params: {
          id: id,
        },
      });
    } catch {}
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (newComment) {
        handleAddComment();
      }
    }
  };

  const handleLike = async (id, isReply = false) => {
    let isLiked = false; // To store whether the comment/reply was liked

    // Update the like status locally
    await setAllComments((prevComments) =>
      prevComments.map((comment) => {
        if (comment.id === id) {
          isLiked = comment.liked; // Store the current like state
          return { ...comment, liked: !comment.liked };
        }
        if (comment.replies) {
          return {
            ...comment,
            replies: comment.replies.map((reply) => {
              if (reply.id === id) {
                isLiked = reply.liked; // Store the current like state for reply
                return { ...reply, liked: !reply.liked };
              }
              return reply;
            }),
          };
        }
        return comment;
      })
    );

    if (isLiked) {
      const res = await deleteData({
        endpoint: "Post/removeLike", // Assuming this is the correct endpoint
        type: "community",
        params: {
          comment_id: id,
          user_id: cookies.cuid,
        },
      });
    } else {
      const res = await postData({
        endpoint: "Post/addLike",
        type: "community",
        params: {
          comment_id: id,
          user_id: cookies.cuid,
        },
      });
    }
  };

  const handleReply = (id, authorName, isReply = false) => {
    setReplyCommentId(id);
    setReplyText(
      `<a href="#${authorName}" class="author-link">${authorName}</a> `
    ); // HTML for highlighted author
  };

  const handleAddReply = (id) => {
    if (replyText.trim()) {
      const replyData = {
        id: allComments.length + 1,
        user: {
          name: name || userInfo.name,
        },
        comment: replyText.replace(/<\/?[^>]+(>|$)/g, ""), // Strip HTML for text-only storage
        time: new Date(),
        liked: false,
        replies: [],
      };

      setAllComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === id
            ? { ...comment, replies: [replyData, ...comment.replies] }
            : {
                ...comment,
                replies: comment.replies?.map((reply) =>
                  reply.id === id
                    ? { ...reply, replies: [replyData, ...reply.replies] }
                    : reply
                ),
              }
        )
      );

      setReplyCommentId(null);
      setReplyText("");
    }
  };

  const handleSaveEdit = async () => {
    try {
      // Update the comment locally
      setAllComments((prevComments) =>
        prevComments.map((comment) =>
          comment.id === editingCommentId
            ? { ...comment, comment: editedText }
            : comment
        )
      );
      setEditingCommentId(null);
      setEditedText("");

      await patchData({
        endpoint: "Post/editComment",
        type: "community",
        params: {
          id: editingCommentId,
        },
        data: {
          comment_id: editingCommentId,
          user_id: cookies.cuid,
          comment: editedText,
          post_id: post_id,
        },
      });
    } catch (error) {
      console.error("Error saving comment:", error);
    }
  };

  const handleCancelEdit = () => {
    setEditingCommentId(null);
    setEditedText("");
  };

  const loadMoreComments = () => {
    if (currentPage < totalPages && !isLoadingMore) {
      fetchComments(currentPage + 1);
    }
  };

  return (
    <div className="comment-section">
      {!isLoading ? (
        <div className="existing-comments" key={allComments}>
          {allComments?.length > 0 ? (
            <>
              {allComments.map((comment) => (
                <div key={comment.id} className="comment mb-2">
                  <PeopleProfileCard
                    profile={comment?.user?.profile_photo}
                    name={comment?.user?.name}
                    size={44}
                    borderRadius="50%"
                    fontSize="14px"
                  />
                  <div className="w-100">
                    <div
                      className={`comment-detail ${
                        editingCommentId === comment.id ? "w-100" : ""
                      }`}
                    >
                      <div className="comment-author">
                        {comment?.user?.name}

                        {comment?.user?.id === cookies.cuid &&
                          editingCommentId !== comment.id && (
                            <div
                              onClick={(event) =>
                                handleStatusMenu(event, comment)
                              }
                              className="cursor-pointer"
                            >
                              <Menu />
                            </div>
                          )}

                        <CustomMenu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          items={menu}
                        />
                      </div>
                      {editingCommentId === comment.id ? (
                        <div className="w-100">
                          <input
                            type="text"
                            value={editedText}
                            onChange={(e) => setEditedText(e.target.value)}
                            className="edit-comment-input w-100"
                            autoFocus
                          />

                          <div className="d-flex gap-2">
                            <button
                              onClick={handleSaveEdit}
                              className="btn-save btn btn-post btn-primary btn-xs"
                            >
                              Save
                            </button>
                            <button
                              onClick={handleCancelEdit}
                              className="btn-cancel btn btn-post btn-outline-dark btn-xs"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div>{comment.comment}</div>
                      )}
                    </div>
                    <div className="d-flex gap-3 mt-1 mb-3 align-items-center">
                      <span
                        className="p-dark cursor-pointer"
                        onClick={() => handleLike(comment.id)}
                      >
                        {comment.liked ? "Liked" : "Like"}
                      </span>
                      {/* <span className="p mb-0">|</span> */}
                      {/* <span
                        className="p-dark cursor-pointer"
                        onClick={() => handleReply(comment.id, comment.author)}
                      >
                        Reply
                      </span> */}

                      {/* <div className="dot"></div> */}
                      <span className="p mb-0">
                        {timeAgo(comment.created_date)}
                      </span>
                    </div>

                    {/* {comment.replies?.length > 0 &&
                      comment.replies.map((reply) => (
                        <div key={reply.id} className="comment mb-2 ml-5">
                          <PeopleProfileCard
                            profile={reply?.profile}
                            name={reply?.author}
                            size={40}
                            borderRadius="50%"
                            fontSize="12px"
                          />
                          <div>
                            <div className="comment-detail">
                              <div className="comment-author">
                                {reply.author}
                              </div>
                              <div>{reply.text}</div>
                            </div>
                            <div className="d-flex gap-3 mt-1 mb-3">
                              <span
                                className="p-dark cursor-pointer"
                                onClick={() => handleLike(reply.id, true)}
                              >
                                {reply.liked ? "Liked" : "Like"}
                              </span>
                              <span
                                className="p-dark cursor-pointer"
                                onClick={() =>
                                  handleReply(comment.id, reply.author, true)
                                }
                              >
                                Reply
                              </span>
                              <span className="p mb-0">{reply.time}</span>
                            </div>

                            {reply.replies?.length > 0 &&
                              reply.replies.map((subReply) => (
                                <div
                                  key={subReply.id}
                                  className="comment mb-2 ml-5"
                                >
                                  <PeopleProfileCard
                                    profile={subReply?.profile}
                                    name={subReply?.author}
                                    size={36}
                                    borderRadius="50%"
                                    fontSize="10px"
                                  />
                                  <div>
                                    <div className="comment-detail">
                                      <div className="comment-author">
                                        {subReply.author}
                                      </div>
                                      <div>{subReply.text}</div>
                                    </div>
                                    <div className="p mb-0">
                                      {subReply.time}
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      ))} */}

                    {/* {replyCommentId === comment.id && (
                      <div className="add-comment w-100 mb-2">
                        <PeopleProfileCard
                          profile={peopleDetails?.profile_photo}
                          name={peopleDetails?.name}
                          size={44}
                          borderRadius="50%"
                          fontSize="14px"
                        />
                        <div className="comment-input-container">
                          <input
                            type="text"
                            placeholder="Write a reply..."
                            value={replyText.replace(/<\/?[^>]+(>|$)/g, "")} // Remove HTML for safe input
                            onChange={(e) => setReplyText(e.target.value)}
                            className="comment-input"
                            onKeyDown={(e) => {
                              if (e.key === "Enter" && !e.shiftKey) {
                                e.preventDefault();
                                handleAddReply(comment.id);
                              }
                            }}
                          />
                          <button
                            className="btn btn-primary post-button"
                            onClick={() => handleAddReply(comment.id)}
                            disabled={!replyText}
                          >
                            Reply
                          </button>
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              ))}
              {currentPage < totalPages && (
                <div className="load-more-container mb-2">
                  <button
                    className="comments cursor-pointer btn-link text-primary py-0"
                    onClick={loadMoreComments}
                    disabled={isLoadingMore}
                    style={{ fontSize: "12px" }}
                  >
                    {isLoadingMore ? "Loading..." : "Load More Comments"}
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="no-comments">No comments yet.</div>
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center py-3">
          <div class="spinner-border text-secondary" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      )}

      <div className="add-comment mt-2">
        <PeopleProfileCard
          profile={userInfo?.profile_photo}
          name={userInfo?.name}
          size={44}
          borderRadius="50%"
          fontSize="14px"
        />
        <div className="comment-input-container">
          <input
            type="text"
            placeholder="Write a comment..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className="comment-input"
            onKeyDown={handleKeyDown}
          />
          <button
            className="btn btn-primary post-button"
            onClick={handleAddComment}
            disabled={!newComment}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );
};

export default CommentsSection;
